import React, { useState, useEffect } from 'react'
import styles from './CompanyHome.module.css'

import { useParams } from 'react-router-dom'

import { Line } from '../../../../components'
import { Profile }  from './profile'
import { Wallet }  from './wallet'
import { Calendar }  from './calendar'
import { Order }  from './order'
import { Staff }  from './staff'

export const CompanyHome: React.FC = (props: any) => {
  return (
    <div className={ `${ styles['company-home'] }` }>
      <div className={　styles['company-home-tit'] }>我的主页</div>
      <div className={ styles['company-cont'] }>
        <div className={ `${ styles['company-cont-l'] }` }>
          <Profile />
          <Order />
        </div>
        <div className={ `${ styles['company-cont-r'] }` }>
          {/* <Calendar /> */}
          <Staff />
          <Wallet />
        </div>
      </div>
    </div>
  )
}