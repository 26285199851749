import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Input, Button } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store'

import util from '../../../../libs/util'

import { Ad, defaultAd } from '../../index'
import { ScreenEffectModal } from '../screen-effect-modal'
import styles from './OrderBottom.module.css'

interface Props {
  ad: Ad,
  isEditMode?: boolean,
  isConfirmPage?: boolean,
  reloadFn?: Function,
  onSaveFn?: Function,
}

export const OrderBottom: React.FC<Props> = ({ ad, isEditMode, isConfirmPage = false, reloadFn, onSaveFn }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  useEffect(() => {
    if (!ad.mallOrderPlanId) {
      return
    }
    getContentUploadEndTime(ad)
  }, [ad.planAim])

  // 取消广告计划
  const toggleCancelOrderTips = () => {
    Modal.confirm({
      title: '是否确认取消广告计划？',
      content: '',
      okText: '确认',
      cancelText: '暂不',
      onOk: onCancelOrder,
    })
  }
  const onCancelOrder = async() => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const { code, data } = await util.request({
      method: 'POST',
      url: `/mall/order/cancelOrderPlan/${ ad.mallOrderPlanId }`,
    })

    setIsSubmiting(false)
    if (code === 1) {
      message.success('取消成功')
      reloadFn && reloadFn()
    } else {
      message.error('操作失败')
    }
  }

  const [isSubmiting, setIsSubmiting] = useState(false)

  // 素材截止上传时间
  const [contentUploadEndTime, setContentUploadEndTime] = useState()
  const getContentUploadEndTime = async(ad) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/tool/getContentUploadEndTime`,
      params: {
        deliveryStart: ad.deliveryStart,
        operatorType: ad.operatorType,
      },
    })

    if (code === 1) {
      const time = data.contentUploadEndTime
      // 距离素材提交截止时间≤3天
      if (+new Date(time.replace(/-/g, '/')) - +new Date() <= 3 * 24 * 60 * 60 * 1000) {
        setContentUploadEndTime(time)
      }
    }
  }
  
  // 去收银台
  const goToCashierPage = () => {
    history.push(`/ad/cashier/${ ad.mallOrderPlanId }/${ isConfirmPage }`)
  }

  const onPay = () => {
    if (!contentUploadEndTime) {
      if (!checkPayAuth()) {
        return
      }
      goToPay()
      return
    }
    Modal.confirm({
      title: '提示',
      content: `广告素材须在${contentUploadEndTime }前提交，请确保您有充足的素材准备时间`,
      cancelText: '素材准备时间不足，返回调整投放周期',
      okText: '已了解，立即支付',
      onOk() {
        goToPay()
      },
      onCancel() {
      },
    })
  }

  const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
  const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'AD_MANAGER') : false
  })
  const checkPayAuth = () => {
    // 个人用户中，只有管理员能支付
    const hasAuth = userType !== 'COMPANY' && isManager || userType === 'COMPANY'
    if (!hasAuth) {
      Modal.warning({
        title: '暂无支付权限',
        content: `请联系企业或管理员进行操作`,
        okText: '我知道了',
        closable: false,
        onOk() {
          if (isConfirmPage) {
            history.goBack()
          }
        },
      })
    }
    return hasAuth
  }

  // 去支付
  const goToPay = async() => {
    if (isSubmiting) {
      return
    }

    if (ad.showPlanStatus === 'ORDER_WAIT') {
      if (!checkPayAuth()) {
        return
      }
      goToCashierPage()
      return
    }

    setIsSubmiting(true)

    // 更新订单(保存订单名)
    const updateResult = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName: ad.deliveryName,
      },
    })

    if (updateResult.code !== 1) {
      setIsSubmiting(false)
      message.error(updateResult.message || '操作失败')
      return
    } 

    // 锁定订单状态
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/deliveryOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
      },
      loadingText: '提交中...',
    })

    setIsSubmiting(false)

    if (code === 1) {
      if (!checkPayAuth()) {
        return
      }
      goToCashierPage()
    } else {
      message.error(message || '操作失败')
    }
  }

  // 去结算
  const onConfirm = async() => {
    // 是待付款状态
    if (ad.showPlanStatus === 'ORDER_WAIT') {
      onPay()
      return
    }
    
    // 区间不满
    const deliveryStart = ad?.deliveryStart || ''
    const deliveryEnd = ad?.deliveryEnd || ''

    const orderTotal = ad.orderTotal
    const realDeliveryStart = orderTotal.realDeliveryStart
    const realDeliveryEnd = orderTotal.realDeliveryEnd

    const day = 24 * 60 * 60 * 1000
    const startDef = Math.floor((new Date(deliveryStart).getTime() - new Date(realDeliveryStart).getTime()) / day)
    const endDef = Math.floor((new Date(deliveryEnd).getTime() - new Date(realDeliveryEnd).getTime()) / day)

    // 部分日期无任何点位投放
    if (startDef < 0 || endDef > 0) {
      Modal.confirm({
        title: '提示',
        content: `投放周期部分日期无任何点位投放，若继续结算，投放周期将自动调整为${ util.tools.formatDateStr(orderTotal.realDeliveryStart) }-${ util.tools.formatDateStr(orderTotal.realDeliveryEnd) }`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan()
        },
        onCancel() {
        },
      })

      return
    }

    // 存在投放日期不满投的点位(innerTotal  < 点位数量 * 天数)
    if (orderTotal.innerTotal < orderTotal.pointUsableCount * ad.deliveryDays) {
      Modal.confirm({
        title: '存在投放日期不满投的点位',
        content: `是否确定选购该影院的点位？`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan()
        },
        onCancel() {
        },
      })

      return
    }

    // 清空失效点位
    if (orderTotal.disablePointCount > 0) {
      await clearDisabledPoint()
    }

    goToConfirmPage()
  }

  // 更新订单
  const updateOrderPlan = async() => {
    const orderTotal = ad.orderTotal

    // 更新订单(更新投放周期)
    const { code } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryContentDuration: ad.deliveryContentDuration,
        deliveryStart: orderTotal.realDeliveryStart,
        deliveryEnd: orderTotal.realDeliveryEnd,
      },
    })

    if (code !== 1) {
      return
    }

    goToConfirmPage()
  }

  // 清除失效点位
  const clearDisabledPoint = async() => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const { code } = await util.request({
      method: 'DELETE',
      url: `/mall/order/clearDisablePoint`,
      data: ad.mallOrderPlanId,
    })

    setIsSubmiting(false)

    if (code === 1) {
      message.success('失效点位清理成功')
      reloadFn && reloadFn()
    }
  }

  // 确认订单页
  const goToConfirmPage = ()=> {
    history.push(`/ad/confirm/${ ad.mallOrderPlanId }`)
  }

  // 广告素材要求
  const showMaterialModal = () => {
    onPreviewEffect()
  }
  const screenEffectModalRef: any = useRef()
  const onPreviewEffect = () => {
    screenEffectModalRef.current.onToggle()
  }

  // 保存
  const onSave = () => {
    onSaveFn && onSaveFn()
  }

  return (
    <>
    {
      (ad.showPlanStatus && ad.showPlanStatus !== 'DRAFT' && ad.showPlanStatus !== 'SAVE' || isConfirmPage) && (
        <div className={ `${ styles['ad-page-cont'] } ${ styles['ad-page-bottom'] }  ${ styles['no-flex'] }`}>
          <div className={ `${ styles['ad-page-form-item'] }` }>
            <div className={ `${ styles['ad-page-form-label'] }` }>总投放费用</div>
            <div className={ `${ styles['ad-page-form-value'] }` }>
              <div className={ `${ styles['text-price'] }` }>
                <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.originalBuyPrice).int }</span>
                <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.originalBuyPrice).dec }</span>
              </div>
            </div>
          </div>
          {/* 会员权益折扣 */}
          {
            (ad.orderTotal?.platformDiscountRatio != 1) && (
              <div className={ `${ styles['ad-page-form-item'] } ${ styles['special'] }` }>
                <div className={ `${ styles['ad-page-form-label'] }` }>R{ ad?.orderTotal?.adOrgGrade }{ ad?.orderTotal?.adOrgGrade < 2 ? '高级' : '铂金' }会员权益折扣{ util.tools.splitNum2String(ad?.orderTotal?.platformDiscountRatio * 100).int }{ util.tools.splitNum2String(ad?.orderTotal?.platformDiscountRatio * 100).dec }%</div>
                <div className={ `${ styles['ad-page-form-value'] }` }>
                  <div className={ `${ styles['text-price'] }` }>-
                    <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                    <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.platformDiscountPrice).int }</span>
                    <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.platformDiscountPrice).dec }</span>
                  </div>
                </div>
              </div>
            )
          }
          {/* 折扣减免 */}
          {
            (ad?.adAgentSettleOriginalPrice - ad?.adAgentSettlePrice > 0) && (
              <div className={ `${ styles['ad-page-form-item'] } ${ styles['special'] }` }>
                <div className={ `${ styles['ad-page-form-label'] }` }>折扣减免</div>
                <div className={ `${ styles['ad-page-form-value'] }` }>
                  <div className={ `${ styles['text-price'] }` }>-
                    <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                    <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(util.tools.reduce(ad?.adAgentSettleOriginalPrice, ad?.adAgentSettlePrice)).int }</span>
                    <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(util.tools.reduce(ad?.adAgentSettleOriginalPrice, ad?.adAgentSettlePrice)).dec }</span>
                  </div>
                </div>
              </div>
            )
          }
          {/* 媒体商家谢绝投放退款 */}
          {
            (ad?.orderTotal?.refundAmount > 0) && (
              <div className={ `${ styles['ad-page-form-item'] } ${ styles['special'] }` }>
                <div className={ `${ styles['ad-page-form-label'] }` }>媒体商家谢绝投放退款</div>
                <div className={ `${ styles['ad-page-form-value'] }` }>
                  <div className={ `${ styles['text-price'] }` }>-
                    <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                    <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.refundAmount).int }</span>
                    <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.refundAmount).dec }</span>
                  </div>
                </div>
              </div>
            )
          }
          {
            (ad?.orderTotal?.platformDiscountRatio != 1 || ad?.adAgentSettleOriginalPrice - ad?.adAgentSettlePrice > 0) && (
              <div className={ `${ styles['ad-page-form-item'] } ${ styles['important'] }` }>
                <div className={ `${ styles['ad-page-form-label'] }` }>{ isConfirmPage ? '合计' : '实付款' }</div>
                <div className={ `${ styles['ad-page-form-value'] }` }>
                  <div className={ `${ styles['text-price'] }` }>
                    <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                    <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE' ? (ad?.planAmount || ad?.orderTotal?.planAmount) : ad?.orderTotal?.realPayAmount).int }</span>
                    <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE' ? (ad?.planAmount || ad?.orderTotal?.planAmount) : ad?.orderTotal?.realPayAmount).dec }</span>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      )
    }

    {
      (ad.showPlanStatus && (ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE' || ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT')) && (
        <div className={ `${ styles['ad-page-cont'] } ${ styles['ad-page-bottom'] } ${ ((ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT' || ad?.showPlanStatus === 'DRAFT') && !isConfirmPage) ? styles['bottom'] : '' } ${ isConfirmPage ? styles['confirm'] : '' }${ ad.showPlanStatus !== 'DRAFT' && ad.showPlanStatus !== 'SAVE' && ad.showPlanStatus !== 'ORDER_WAIT' && ad.orderTotal?.platformDiscountRatio != 1 ? styles['no-flex'] : '' }` }>
        {
          ((isEditMode || ad?.showPlanStatus === 'DRAFT' || ad?.showPlanStatus === 'SAVE') && !isConfirmPage) && (
            <div className={ `${ styles['ad-tit-tips'] }` }>已选点位 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.pointCount }</span> 个，覆盖场所 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.entityCount }</span> 个，预估触达 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.reachTime }</span>人</div>
          )
        }

        {
          isConfirmPage && (
            <div className={ `${ styles['ad-material-tips'] }` }>请阅读<span className={ `${ styles['ad-material-link'] }` } onClick={ showMaterialModal }>《广告素材制作要求》</span></div>
          )
        }

        {
          (ad.showPlanStatus && (ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE' || ad?.showPlanStatus === 'ORDER_WAIT')) && (
            <div className={ `${ styles['ad-page-bottom-l'] }` }>
              {
                (ad?.showPlanStatus === 'ORDER_WAIT' && (ad?.orderTotal?.platformDiscountRatio != 1 || ad?.adAgentSettleOriginalPrice - ad?.adAgentSettlePrice > 0)) && (
                  <div className={ `${ styles['text-price-wrap'] }` }>
                    合计
                    <div className={ `${ styles['text-price'] }` }>
                      <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                      <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).int }</span>
                      <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).dec }</span>
                    </div>
                    <div className={ `${ styles['text-price-reduce'] }` }>
                      共减
                      <div className={ `${ styles['text-price'] }` }>
                        <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                        <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(util.tools.reduce(ad?.orderTotal?.originalBuyPrice, ad?.orderTotal?.planAmount)).int }</span>
                        <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(util.tools.reduce(ad?.orderTotal?.originalBuyPrice, ad?.orderTotal?.planAmount)).dec }</span>
                      </div>
                      元
                    </div>
                  </div>
                )
              }
              {
                (ad.showPlanStatus && (ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE')) && (
                  <div className={ `${ styles['text-price-wrap'] }` }>
                    合计
                    <div className={ `${ styles['text-price'] }` }>
                      <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                      <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(isConfirmPage ? ad?.orderTotal?.planAmount : ad?.orderTotal?.originalBuyPrice).int }</span>
                      <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(isConfirmPage ? ad?.orderTotal?.planAmount : ad?.orderTotal?.originalBuyPrice).dec }</span>
                    </div>
                  </div>
                )
              }
              
              {/* 未保存时 */}
              {
                (ad.showPlanStatus === 'DRAFT' && !isConfirmPage) && (<span className={ `${ styles['btn-cancel'] }` } onClick={ onSave }>保存</span>)
              }
              {
                ((!ad?.showPlanStatus || ad?.showPlanStatus === 'DRAFT' || ad?.showPlanStatus === 'SAVE') && !isConfirmPage) && (
                  <button className={ `${ styles['btn-comfirm'] }` } disabled={ isSubmiting || ad?.orderTotal?.pointUsableCount <= 0 } onClick={ onConfirm }>去结算</button>
                )
              }
              {
                (isConfirmPage) && (
                  <button className={ `${ styles['btn-comfirm'] }` } disabled={ isSubmiting || ad?.orderTotal?.pointUsableCount <= 0 } onClick={ goToPay }>去付款</button>
                )
              }
            </div>
          )
        }
        
        
        {/* 右边按钮 */}
        {/*待付款时*/}
        {
          ((ad?.showPlanStatus === 'ORDER_WAIT' || ad?.showPlanStatus === 'PAY_WAIT') && !isConfirmPage) && (
            <div className={ `${ styles['ad-page-bottom-r'] }` }>
              <span className={ `${ styles['btn-cancel'] }` } onClick={ toggleCancelOrderTips }>取消广告计划</span>
              {
                (ad?.showPlanStatus === 'ORDER_WAIT' && ad?.adAgentUcOrgId && ad?.agentPayStatus === 'YES') && (<button className={ `${ styles['btn-comfirm'] }` } disabled>等待代理商付款</button>)
              }
              
              {
                (ad?.showPlanStatus === 'ORDER_WAIT' && (!ad?.adAgentUcOrgId || ad?.adAgentUcOrgId && ad?.agentPayStatus !== 'YES')) && (<button className={ `${ styles['btn-comfirm'] }` } disabled={ isSubmiting || ad.orderTotal?.pointUsableCount <= 0 } onClick={ goToPay }>去付款</button>)
              }
            </div>
          )
        }
        
        {/* 广告素材制作要求 */}
        <ScreenEffectModal childRef={ screenEffectModalRef }  />
      </div>
      )
    }
    </>
  )
}