import React, { useState, useEffect } from 'react'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import styles from './Wallet.module.css'

import util from '../../../../../libs/util'
interface Props {
  isPersonal?: boolean,
}

export const Wallet: React.FC<Props> = (props) => {
  const [availableAmount, setAvailableAmount] = useState(0)
  const [balance, setBalance] = useState(0)
  const [creditAmount, setCreditAmount] = useState(0)
  const [isShow, setIsShow] = useState(true)

  useEffect(() => {
    getBalance()
  }, [])

  const toggleShow = () => {
    setIsShow(!isShow)
  }

  const getBalance = async () => {
    const { code, data } = await util.request({
      method: 'POST',
      url: 'finance/account/getReferAccountInfo',
      data: {
        currencyTypeId: 'RM'
      }
    })

    if (code === 1) {
      const { orgAccount } = data
      setAvailableAmount(+((+orgAccount.balance + +orgAccount.creditAmount).toFixed(2)))
      setBalance(orgAccount.balance)
      setCreditAmount(orgAccount.creditAmount)
    }
  }

  return (
    <div className={ `${ styles.wallet } ${ props.isPersonal ? styles.higher : '' }` }>
      <h3 className={ styles.title }>我的钱包<span className={ styles['wallet-icon-toggle'] } onClick={ toggleShow }>{ isShow && <EyeOutlined /> }{ !isShow && <EyeInvisibleOutlined />}</span></h3>

      <div className={ styles['wallet-cont'] }>
        <div className={ styles['wallet-item'] }>
          <div className={ styles['wallet-tit'] }>可用金额<span className={ styles['wallet-tit-unit'] }>（元)</span></div>
          <div className={ styles['wallet-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.splitNum2String(availableAmount).int }</span>
              <span className={ styles.dec }>{ util.tools.splitNum2String(availableAmount).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>

        <div className={ styles['wallet-item'] }>
          <div className={ styles['wallet-tit'] }>钱包余额<span className={ styles['wallet-tit-unit'] }>（元)</span></div>
          <div className={ styles['wallet-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.splitNum2String(balance).int }</span>
              <span className={ styles.dec }>{ util.tools.splitNum2String(balance).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>

        <div className={ styles['wallet-item'] }>
          <div className={ styles['wallet-tit'] }>授信额度<span className={ styles['wallet-tit-unit'] }>（元)</span></div>
          <div className={ styles['wallet-price'] }>
            { isShow && <>
              <span className={ styles.int }>{ util.tools.splitNum2String(creditAmount).int }</span>
              <span className={ styles.dec }>{ util.tools.splitNum2String(creditAmount).dec }</span>
            </>}

            { !isShow && '***' }
          </div>
        </div>
      </div>
    </div>
  )
}