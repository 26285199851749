import React, { useState, useContext, useEffect } from 'react'
import util from '../../../../libs/util'
import styles from './FilterPanel.module.css'

import { filterContentContext, setFilterContentContext } from '../ContentList'

import { Select, Input } from 'antd'
const { Option } = Select
const { Search } = Input

// 状态
const auditStatusItems = [
  {
    itemKey: '',
    itemValue: '全部状态',
  },
  {
    itemKey: 'PEND',
    itemValue: '审核中',
  },
  {
    itemKey: 'YES',
    itemValue: '审核通过',
  },
  {
    itemKey: 'NO',
    itemValue: '审核不通过',
  },
]

export const FilterContentPanel: React.FC = () => {
  const filter = useContext(filterContentContext)
  const setFilter = useContext(setFilterContentContext)

  // 单选
  const handleChange = (value) => {
    setFilter(state => {
      return {
        ...state,
        auditStatuses: value,
      }
    })
  }

  const onSearch = (value) => {
    setFilter(state => {
      return {
        ...state,
        contentNote: value,
      }
    })
  }

  const onPressEnter = (e) => {
    setFilter(state => {
      return {
        ...state,
      }
    })
  }

  return (
    <div className={ styles['filter-panel'] }>
      <div className={ styles['filter-type'] }>
        <Select style={{ width: 200 }} value={ filter.auditStatuses } onChange={ handleChange }>
          {
            auditStatusItems.map((status) => {
              return (
                <Option key={ status.itemKey } value={ status.itemKey }>{ status.itemValue }</Option>
              )
            })
          }
        </Select>
        <Search placeholder="请输入标题或id" value={ filter.contentNote } onSearch={ onSearch } onPressEnter={ onPressEnter } enterButton />
      </div>
    </div>
  )
}