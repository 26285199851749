import util from '../libs/util'

export const API = {}

// 获取企业认证信息（实名和身份）
export const getCompanyApply = async(data) => {
  const result = await util.request({
    method: 'GET',
    url: '/uc/getCompanyApply',
    data,
    params: data,
  })

  return result.data
}

// 查询我的菜单树
export const getMenuList = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/queryMyMenuTree',
    data,
    params: data,
  })

  return result.data
}

// 查询我的元素集合（根据菜单ID或KEY）
export const getMyElementList = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/queryMyElementList',
    data,
    params: data,
  })

  return result.data
}

// 获取个人信息
export const getUserInfo = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: '/uc/getMyUserInfo',
    data,
    params: data,
  })

  return result.data
}

// 获取广告素材
export const getMineContent = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/content/searchMineContent`,
    data,
    params: data,
  })

  return result
}

// 获取存储空间
export const getStorageSpace = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/content/getStorageSpace`,
    data,
    params: data,
  })

  return result
}

// 获取腾讯接口临时密钥
// export const getTxTempKeys = async(setting) => {
//   const result = await util.request({
//     method: 'GET',
//     url: `/security/getTxTempKeys?bucketName=${setting.data.bucketName}`,
//     data,
//     params: data,
//   })

//   return result
// }
// 
// 根据MD5获取源文件记录
export const getContentAdByMd5 = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/resource/getContentAdByMd5`,
    data,
    params: data,
  })

  if (result.code === 1 && result.data) {
    return true
  }

  return false
}

// 新建媒体源文件的记录
export const createOriginal = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/createOriginal`,
    data,
    params: data,
  })

  return result
}

// 删除文件
export const deleteContentAd = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/content/deleteContentAd`,
    data,
    params: data,
  })

  return result
}

// 提交审核
export const createContentApprove = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/resource/content/createContentApprove`,
    data,
    params: data,
  })

  return result
}

// 生成预签名的 HTTPS GET URL
export const getPreSignedUrl = async(data = {}) => {
  const result = util.request({
    url: '/security/getPreSignedUrl',
    data,
    params: data,
    method: 'GET',
  })

  return result
}

// 获取上传文件Cos信息
export const getCosAuthInfo = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/security/getCosAuthInfo`,
    data,
    // params: data,
  })

  return result
}

// 查询配置项(无需登录) 
export const getConfigList = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/config/enum/getConfigList`,
    // data,
    params: data,
  })

  return result
}

// 根据当前级ID和类型获取所有下级行政
export const searchChildrenDistrict = async(data = {}) => {
  const result = await util.request({
    method: 'GET',
    url: `/config/district/searchChildrenDistrict`,
    // data,
    params: data,
  })

  return result
}

// 创建外部用户记录
export const createOutsideUser = async(data = {}) => {
  const result = await util.request({
    method: 'POST',
    url: `/uc/outside_user/createOutsideUser`,
    data,
    // params: data,
  })

  return result
}

// 企业登录
// 获取手机号登录Code
export const getPhoneLoginCode = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/getPhoneLoginCode',
    method: 'GET',
    ...setting,
    params: data,
  })

  return result
}

// 根据CODE获取当前端企业列表
export const getOrgUsersByCode = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/org/getOrgUsersByCode',
    method: 'GET',
    ...setting,
    params: data,
  })

  return result
}

// 根据CODE换取登录token
export const codeLogin = async(data = {}, setting) => {
  const result = await util.request({
    url: '/uc/codeLogin',
    method: 'POST',
    data,
    ...setting,
  })

  return result
}

// 获取媒体源文件的创建记录
export const getContentAdById = async(data = {}, setting) => {
  const result = await util.request({
    url: '/resource/content/getContentAdById',
    method: 'GET',
    params: data,
    ...setting,
  })

  return result
}

// 合成制作新视频
export const makeNewVideo = async(data = {}, setting) => {
  const result = await util.request({
    url: '/resource/content/makeNewVideo',
    method: 'POST',
    data,
    ...setting,
  })

  return result
}