import React, { useState, useRef, useEffect, useContext } from 'react'
import styles from './Home.module.css'
import util from '../../libs/util'

import { SignLayout } from '../../layouts'
import { Footer } from '../../components'
import { UserModal } from './userModal'

export const Home: React.FC = () => {
  const [curSceneIndex, setCurSceneIndex] = useState(0)

  const sceneList = [
    {
      id: 'stores',
      name: '推广我的门店',
      icon: 'i-store',
      text: '以您的门店地理位置为中心点，基于消费半径选择目标范围内的影院，将门店地址、产品、活动等信息高效触达附近人群，增强门店在当地的认知度，吸引周边潜在客户到您的店里消费。',
      link: '',
    },
    {
      id: 'goods',
      name: '推广我的商品',
      icon: 'i-goods',
      text: '基于您的商品特色、属性、目标人群，匹配电影观众兴趣特征偏好，选择影片定向投放，让您的商品更容易被理解、被接受、被记忆，从而达到提升销量的推广目的。',
      link: '',
    },
    {
      id: 'brand',
      name: '推广我的品牌',
      icon: 'i-crown',
      text: '在大片前播放您的品牌视频广告，让您的品牌价值观在沉浸式环境里完整表达，强化品牌形象，借助光环效应让您的企业和产品形象得到提升。',
      link: '',
    },
    {
      id: 'activity',
      name: '推广我的活动',
      icon: 'i-activity',
      text: '无论是线下促销，还是线上优惠，都可以借助超大银幕将您的活动信息告知观众，放松愉悦的观影环境容易触发消费需求，大大提升观众参与活动的概率。',
      link: '',
    },
  ]
  // 切换
  const toggleSceneTab = (index) => {
    setCurSceneIndex(index)
  }

  const childRef: any = useRef()
  const onAdd = () => {
    childRef.current.onToggle()
  }

  const [curCinemaIndex, setCurCinemaIndex] = useState(0)
  const tabList = [
    {
      id: 'ad',
      icon: '',
      name: '影院银幕广告',
    },
    {
      id: 'vision',
      icon: 'icon-tab-item-3',
      name: '超大视觉',
    },
    {
      id: 'practice',
      icon: 'icon-tab-item-2',
      name: '沉浸式体验',
    },
    {
      id: 'follow',
      icon: 'icon-tab-item-1',
      name: '高关注度',
    },
    {
      id: 'marketing',
      icon: 'icon-tab-item-4',
      name: '周边营销',
    },
  ]
  // 切换
  const toggleCinemaTab = (index) => {
    setCurCinemaIndex(index)
  }

  const [imgType, setImgType] = useState(new Array(tabList.length).fill('png'))
  const toggleImgType = (index, type) => {
    const temp = [...imgType]
    temp[index] = type
    setImgType(temp)
  }

  // 跳转第二页
  const goToNextPage = () => {
    let height = document.documentElement.clientHeight
    let h = 0
    const scrollTo = () => {
      h += 50
      window.scrollTo(0, h >= height ? height : h)
      if (h >= height) {
        clearInterval(timer)
      }
    }
    let timer = setInterval(scrollTo, 30)
    window.scrollTo(0, document.documentElement.clientHeight)
  }

  const [scrollTop, setScrollTop] = useState(0)
  const [isInView, setIsInView] = useState(true)
  useEffect(() => {
    const onScroll = e => {
      const pageYOffset = window.pageYOffset
      setScrollTop(pageYOffset)
      const bounding = document.querySelector('.content-advantage')?.getBoundingClientRect()
      // 向下滚
      // if (scrollTop < pageYOffset) {
        if (bounding && bounding.y <= document.documentElement.clientHeight && -bounding.y <= bounding.height){
          setIsInView(true)
        } else {
          setIsInView(false)
        }
      // } else {
      //   setIsInView(true)
      // }
      
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/static/jtt-media-buyer-web/home`
  const videoUrl = baseURL + '/video-banner.mp4'
 
  return (
    <div className={ styles.home }>
      <SignLayout isHomePage>
        {/*banner*/}
        <div className={ `${ styles['home-banner'] } page-1` }>
          <video className={ styles['home-banner-video'] } src={ videoUrl } autoPlay muted loop></video>    
          <div className={ styles['btn-arrow'] } onClick={ goToNextPage }></div>    
        </div>

        {/*影院银幕广告优势*/}
        <div className={ `${ styles['home-cinema'] } page-2` }>
          <div className={ styles['home-cinema-tit'] }>影院银幕广告优势</div>
          <div className={ styles['cinema-tabs'] }>
            {
              tabList.map((item, index) => {
                return (
                  <div className={ `${ styles['cinema-tab-item'] } ${ curCinemaIndex === index ? styles['is-selected'] : '' }` } key={ index } onClick={ () => toggleCinemaTab(index) } onMouseOver={ () => toggleImgType(index, 'gif') } onMouseOut={ () => toggleImgType(index, 'png') }>
                    {
                      index !== 0 && <img className={`${ styles['icon-tab-item'] }`} src={ `${ baseURL }/icon-cinema-tab-item-${index}.${imgType[index]}?v=2` } />
                    }
                    {
                      index === 0 && <div>什么是</div>
                    }
                    { item.name }
                    <div className={ styles['cinema-tab-line'] }></div>
                  </div>
                )
              })
            }
          </div>
          <div className={ styles['cinema-conts'] }>
            <div className={ styles['cinema-cont-item'] } style={{ opacity: curCinemaIndex === 0 ? 1 : 0 }}>
              <div className={ styles['cinema-cont-left'] }>
                <div className={ styles['cinema-cont-text'] }>
                  <span className={ styles['cinema-cont-label'] }>传统影院银幕广告</span>
                  指观众入场就坐等候电影放映前的10分钟时间里，影厅银幕上播放的广告
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  <span className={ styles['cinema-cont-label'] }>广告素材：</span>
                  时长为15秒/30秒/60秒的视频或图片幻灯片
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  <span className={ styles['cinema-cont-label'] }>广告播放频次：</span>
                  每场影片前播放一次
                </div>
              </div>
              <div className={ styles['cinema-cont-img'] }>
              </div>
            </div>

            <div className={ styles['cinema-cont-item'] } style={{ opacity: curCinemaIndex === 1 ? 1 : 0 }}>
              <div className={ styles['cinema-cont-left'] }>
                <div className={ styles['cinema-cont-tit'] }>超大视觉</div>
                <div className={ styles['cinema-cont-text'] }>
                  最大的广告视觉体验之一：<span className={ styles['cinema-text-important'] }>10倍</span>于电视屏幕的视觉体验
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  研究表明，电影俘获的观众提供的广告召回率是电视的五倍
                </div>
              </div>
              <div className={ styles['cinema-cont-img'] }>
              </div>
            </div>

            <div className={ styles['cinema-cont-item'] } style={{ opacity: curCinemaIndex === 2 ? 1 : 0 }}>
              <div className={ styles['cinema-cont-left'] }>
                <div className={ styles['cinema-cont-tit'] }>沉浸式体验</div>
                <div className={ styles['cinema-cont-text'] }>
                  广告时间<span className={ styles['cinema-text-important'] }>79%</span>的观众不被手机干扰
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  影院提供了一个整洁的、360度、造梦的营销环境，让观众处于一种轻松、乐于接受的心态，非常适合传达您的信息
                </div>
              </div>
              <div className={ styles['cinema-cont-img'] }>
              </div>
            </div>
            
            <div className={ styles['cinema-cont-item'] } style={{ opacity: curCinemaIndex === 3 ? 1 : 0 }}>
              <div className={ styles['cinema-cont-left'] }>
                <div className={ styles['cinema-cont-tit'] }>高关注度</div>
                <div className={ styles['cinema-cont-text'] }>
                  <div>电影院广告关注度高达<span className={ styles['cinema-text-important'] }>64%</span></div>
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  视频广告“完播率之王”：广告不会被关闭或<br/>滑过
                </div>
                <div className={ styles['cinema-cont-text'] }>
                  相较于其他视频广告，主动观看比例最高
                </div>
              </div>
              <div className={ styles['cinema-cont-img'] }>
              </div>
            </div>

            <div className={ styles['cinema-cont-item'] } style={{ opacity: curCinemaIndex === 4 ? 1 : 0 }}>
              <div className={ styles['cinema-cont-left'] }>
                <div className={ styles['cinema-cont-tit'] }>周边营销</div>
                <div className={ styles['cinema-cont-text'] }>
                  电影院一般都位于购物中心和人流密集的商业区，在特定时间、特定地点很容易触发消费需求，尤其适合距离影院<span className={ styles['cinema-text-important'] }>1公里范围内</span>的商家进行附近宣传推广——观众离开电影院购买您的产品或服务并不难
                </div>
              </div>
              <div className={ styles['cinema-cont-img'] }>
              </div>
            </div>

          </div>
        </div>

        {/*我们的优势*/}
        <div className={ `${ styles['home-advantage'] } content-advantage` }>
          <div className={ `${ styles['home-advantage-inner'] } ${ isInView ? styles['is-in'] : '' }` }>
            <div className={ styles['home-advantage-tit'] }>我们的优势</div>
            <div className={ styles['home-advantage-list'] }>
              <div className={ styles['home-advantage-item'] }>
                <div className={ styles['home-advantage-img'] }></div>
                <div className={ styles['home-advantage-cont'] }>
                  <div className={ styles['home-advantage-item-tit'] }>海量优质资源</div>
                  <div className={ styles['home-advantage-text'] }>只卖电影正片“龙标”<span className={ styles['cinema-text-important'] }>前5分钟</span>以内的映前广告位置，媒体价值高</div>
                  <div className={ styles['home-advantage-text'] }>全国数千家“年观影人次不低于20万”的影院，保障有效流量</div>
                </div>
              </div>
              <div className={ styles['home-advantage-item'] }>
                <div className={ styles['home-advantage-cont'] }>
                  <div className={ styles['home-advantage-item-tit'] }>超高性价比</div>
                  <div className={ styles['home-advantage-text'] }>根据影院观影人次水平，统一规范合理定价，价格公道透明</div>
                  <div className={ styles['home-advantage-text'] }>单银幕1天低至8元，7天起投，不足百元</div>
                  <div className={ styles['home-advantage-text'] }>按实际刊播计费，0错播0漏播</div>
                  <div className={ styles['home-advantage-text'] }>广告素材DCP格式免费转码</div>
                  <div className={ styles['home-advantage-text'] }>7*24小时专业服务</div>
                </div>
                <div className={ styles['home-advantage-img'] }></div>
              </div>
              <div className={ styles['home-advantage-item'] }>
                <div className={ styles['home-advantage-img'] }></div>
                <div className={ styles['home-advantage-cont'] }>
                  <div className={ styles['home-advantage-item-tit'] }>精准投放</div>
                  <div className={ styles['home-advantage-text'] }>支持城市、区域、时间、影片等多 维定向，精准选择意向点位</div>
                  <div className={ styles['home-advantage-text'] }>基于推广目标，通过营销模型计算画像标签，智能推荐投放点位，优化投放方案</div>
                </div>
              </div>
              <div className={ styles['home-advantage-item'] }>
                <div className={ styles['home-advantage-cont'] }>
                  <div className={ styles['home-advantage-item-tit'] }>全链路数字化</div>
                  <div className={ styles['home-advantage-text'] }>广告点位“场所、人群、屏幕尺寸”等信息真实直观呈现，列表/地图双模式在线选点</div>
                  <div className={ styles['home-advantage-text'] }>广告投放直接在线下单，订单即时响应，程序化购买，排期高效锁定</div>
                  <div className={ styles['home-advantage-text'] }>广告素材智能审核、自动上下刊</div>
                  <div className={ styles['home-advantage-text'] }>每日投放监播报告，即时掌握刊播实况，营销费用去向清晰明了</div>
                </div>
                <div className={ styles['home-advantage-img'] }></div>
              </div>
            </div>
          </div>
        </div>

        {/*推广需求*/}
        <div className={ `${ styles['home-scene'] }` }>
          <div className={ styles['home-scene-inner'] }>
            <div className={ styles['home-scene-tit'] }>推广需求</div>
            {/*tabs*/}
            <div className={ styles['home-scene-tabs'] }>          
              {
                sceneList.map((item, index) => {
                  return (
                    <div className={ `${ styles['home-scene-tab'] } ${ curSceneIndex === index ? styles['is-selected'] : '' }` } key={ item.id } onClick={ () => toggleSceneTab(index) }>
                      <div className={ `${ styles['home-scene-tab-icon'] } ifont ${ item.icon }` }></div>
                      <div>{ item.name }</div>
                    </div>
                  )
                })
              }
            </div>

            {/*conts*/}
            <div className={ styles['home-scene-cont'] }>
              {
                sceneList.map((item, index) => {
                  return (
                    <div className={ `${ styles['home-scene-cont-item'] } ${ curSceneIndex === index ? '' : styles['is-hide'] }` } key={ item.id }>
                      <div className={ `${ styles['home-scene-pic'] } `}></div>
                      <div className={ styles['home-scene-cont-r'] }>
                        <div className={ styles['home-scene-cont-tit'] }>{ item.name }</div>
                        <div className={ styles['home-scene-cont-text'] }>{ item.text }</div>
                        <div className={ styles['home-scene-cont-link'] } onClick={ onAdd }>我要推广</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {/*联系我们*/}
        <div className={ `${ styles['home-contact'] }` }>
          <div className={ styles['home-contact-inner']}>
            <div className={ styles['home-contact-l'] }>
              <div className={ styles['home-contact-tit'] }>联系我们</div>
              {/*<div className={ styles['home-contact-text'] }>
                <div className={ `${ styles['home-contact-icon'] } ifont i-tel` }></div>
                <div>电话：<span className={ styles['home-contact-value'] }>186 7560 7393 </span></div>
              </div>*/}
              <div className={ styles['home-contact-text'] }>
                <div className={ `${ styles['home-contact-icon'] } ifont i-email` }></div>
                <div>邮箱：<a className={ styles['home-contact-value'] } href="mailto:jutoutech@jutoutech.com">jutoutech@jutoutech.com</a></div>
              </div>
            </div>
            <div className={ styles['home-contact-r'] }>
              <div className={ styles['home-contact-minicode'] }>
                <div className={ styles['home-contact-minicode-img'] }></div>
                <div>微信扫码关注<br/>热幕公众号</div>
              </div>
              <div className={ styles['home-contact-minicode'] }>
                <div className={ styles['home-contact-minicode-img'] }></div>
                <div>微信扫码登录<br/>热幕小程序</div>
              </div>
            </div>
          </div>

          <footer className={ `${ styles['footer'] }`}>
          ©2023 聚投科技(珠海)有限公司版权所有 <span className={ styles['space'] }>|</span> <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19146099号</a> 
          </footer>
        </div>

        <UserModal childRef={ childRef } />
        
      </SignLayout>
    </div>
  )
}