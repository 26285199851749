import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'

import { NoRecord } from '../../../components'
import { AddMaterialModal } from '../components/add-material'

import util from '../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'

import styles from './AdList.module.css'
import { MailOutlined } from '@ant-design/icons'

interface Props {
  location: {
    search?: any,
  },
}

export const AdList: React.FC<Props> = (props) => {
	const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
  const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'AD_MANAGER') : false
  })
  const checkPayAuth = () => {
    // 个人用户中，只有管理员能支付
    const hasAuth = userType !== 'COMPANY' && isManager || userType === 'COMPANY'
    if (!hasAuth) {
      Modal.warning({
        title: '暂无支付权限',
        content: `请联系企业或管理员进行操作`,
        okText: '我知道了',
        closable: false,
        onOk() {
        },
      })
    }
    return hasAuth
  }
  
	const tabList = [
		{
			id: 'SAVE,ORDER_WAIT,DELIVERY_WAIT,EXECUTING,FINISHED,ORDER_TIMEOUT',
			name: '全部',
		},
		{
			id: 'SAVE',
			name: '计划中',
		},
		{
			id: 'ORDER_WAIT',
			name: '待付款',
		},
		{
			id: 'DELIVERY_WAIT',
			name: '待上刊',
		},
		{
			id: 'EXECUTING',
			name: '刊播中',
		},
		{
			id: 'FINISHED',
			name: '已完成',
		},
	]
	
	useEffect(() => {
		const search = props.location.search
		if (!search) {
			getList(1)
			return
		}
		const status = search?.split('?')[1].split('=')[1]
		const id = tabList.find(i => i.id === status)?.id
		setCurTabId(id)
		getList(1, id)
	}, [])

	const [curTabId, setCurTabId] = useState<any>(tabList[0].id)

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

	const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)
	// 获取列表
  const getList = async (num?: number, id?: string) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    let params = {
      orderByCreateDate: 'DESC',
      planAims: ['NORMAL'],
      showPlanStatuses: (id || curTabId).split(','),
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/searchOrderPlanList?pageNum=${num || pageNum}&pageSize=${pageSize}`,
      data: params,
    })
    if (code === 1) {
	    setList(data)
	    setTotal(total)
	    await getOrderTotal(data)
	    setIsSubmiting(false)
	  }
  }
  const getOrderTotal = async (list) => {
  	if (!list.length) {
      return
    }
  	const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
      	mallOrderPlanIds: list.map(i => i.mallOrderPlanId),
      },
    })

  	if (code === 1) {
	    list.forEach((item, index) => {
	      const orderTotal = data.find(i => i.mallOrderPlanId === item.mallOrderPlanId)
	      item.orderTotal = orderTotal || null
	    })

	    setList(list)
	  }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
    getList(page)
    console.log('onPageChange', page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const toggleTab = (id) => {
  	if (id === curTabId) {
  		return
  	}
  	setCurTabId(id)
  	setPageNum(1)
  	getList(1, id)
  	console.log('toggleTab', id, curTabId)
  }

  const history = useHistory()

  const goToDetailPage = (data) => {
  	history.push(`/ad/detail/${ data.mallOrderPlanId }`)
  }
	
  const addMaterialModalRef: any = useRef()
  const onMaterial = (ad) => {
    addMaterialModalRef.current.onToggle({
      isFormAd: true,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad.deliveryIndustry,
      mallOrderPlanId: ad.mallOrderPlanId,
    })
  }

	// 素材截止上传时间
  const [contentUploadEndTime, setContentUploadEndTime] = useState()
  const getContentUploadEndTime = async(ad) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/tool/getContentUploadEndTime`,
      params: {
        deliveryStart: ad.deliveryStart,
        operatorType: ad.operatorType,
      },
    })

    if (code === 1) {
      const time = data.contentUploadEndTime
      // 距离素材提交截止时间≤3天
      if (+new Date(time.replace(/-/g, '/')) - +new Date() <= 3 * 24 * 60 * 60 * 1000) {
        setContentUploadEndTime(time)
      }
    }
  }
  
  // 去收银台
  const goToCashierPage = (ad) => {
		history.push(`/ad/cashier/${ ad.mallOrderPlanId }/${ false }`)
  }

  const onPay = async(ad) => {
		await getContentUploadEndTime(ad)
    if (!contentUploadEndTime) {
      goToPay(ad)
      return
    }
    Modal.confirm({
      title: '提示',
      content: `广告素材须在${contentUploadEndTime }前提交，请确保您有充足的素材准备时间`,
      cancelText: '素材准备时间不足，返回调整投放周期',
      okText: '已了解，立即支付',
      onOk() {
        goToPay(ad)
      },
      onCancel() {
      },
    })
  }

  // 去支付
  const goToPay = async(ad) => {
    if (isSubmiting) {
      return
    }

		if (ad.showPlanStatus === 'ORDER_WAIT') {
			if (!checkPayAuth()) {
        return
      }
      goToCashierPage(ad)
      return
    }

    setIsSubmiting(true)

    // 更新订单(保存订单名)
    const updateResult = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName: ad.deliveryName,
      },
    })

    if (updateResult.code !== 1) {
      setIsSubmiting(false)
      message.error(updateResult.message || '操作失败')
      return
    }

    // 锁定订单状态
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/deliveryOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
      },
      loadingText: '提交中...',
    })

    setIsSubmiting(false)

    if (code === 1) {
			if (!checkPayAuth()) {
        return
      }
      goToCashierPage(ad)
    } else {
      message.error(message || '操作失败')
    }
  }

  // 去结算
  const onConfirm = async(ad) => {
    // 是待付款状态
    if (ad.showPlanStatus === 'ORDER_WAIT') {
      onPay(ad)
      return
    }
    
    // 区间不满
    const deliveryStart = ad?.deliveryStart || ''
    const deliveryEnd = ad?.deliveryEnd || ''

    const orderTotal = ad.orderTotal
    const realDeliveryStart = orderTotal.realDeliveryStart
    const realDeliveryEnd = orderTotal.realDeliveryEnd

    const day = 24 * 60 * 60 * 1000
    const startDef = Math.floor((new Date(deliveryStart).getTime() - new Date(realDeliveryStart).getTime()) / day)
    const endDef = Math.floor((new Date(deliveryEnd).getTime() - new Date(realDeliveryEnd).getTime()) / day)

    // 部分日期无任何点位投放
    if (startDef < 0 || endDef > 0) {
      Modal.confirm({
        title: '提示',
        content: `投放周期部分日期无任何点位投放，若继续结算，投放周期将自动调整为${ util.tools.formatDateStr(orderTotal.realDeliveryStart) }-${ util.tools.formatDateStr(orderTotal.realDeliveryEnd) }`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan(ad)
        },
        onCancel() {
        },
      })

      return
    }

    // 存在投放日期不满投的点位(innerTotal  < 点位数量 * 天数)
    if (orderTotal.innerTotal < orderTotal.pointUsableCount * ad.deliveryDays) {
      Modal.confirm({
        title: '存在投放日期不满投的点位',
        content: `是否确定选购该影院的点位？`,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          updateOrderPlan(ad)
        },
        onCancel() {
        },
      })

      return
    }

    // 清空失效点位
    if (orderTotal.disablePointCount > 0) {
      await clearDisabledPoint(ad)
    }

    goToConfirmPage(ad)
  }

  // 更新订单
  const updateOrderPlan = async(ad) => {
    const orderTotal = ad.orderTotal

    // 更新订单(更新投放周期)
    const { code } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryContentDuration: ad.deliveryContentDuration,
        deliveryStart: orderTotal.realDeliveryStart,
        deliveryEnd: orderTotal.realDeliveryEnd,
      },
    })

    if (code !== 1) {
      return
    }

    goToConfirmPage(ad)
  }

  // 清除失效点位
  const clearDisabledPoint = async(ad) => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const { code } = await util.request({
      method: 'DELETE',
      url: `/mall/order/clearDisablePoint`,
      data: ad.mallOrderPlanId,
    })

    setIsSubmiting(false)

    if (code === 1) {
      message.success('失效点位清理成功')
    }
  }

  // 确认订单页
  const goToConfirmPage = (ad)=> {
    history.push(`/ad/confirm/${ ad.mallOrderPlanId }`)
  }

  const getLeftBanner = (ad) => {
		/* 平台未及时审核已提交素材 */
		if (ad.showPlanStatus === 'ORDER_TIMEOUT' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('APPROVE_PLATFORM_TIMEOUT')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >平台超时未审核</div>)
		}

		/* 审核中 */
		else if (ad.showPlanStatus !== 'EXECUTING' && ad.showPlanStatus !== 'FINISHED' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('CONTENT_WAIT')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >审核中</div>)
		}

		/* 不符合投放要求 */
		else if (ad.showPlanStatus !== 'EXECUTING' && ad.showPlanStatus !== 'FINISHED' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NO') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >不符合投放要求</div>)
		}

		/* 素材已删除 */
		else if (ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('CONTENT_DELETE')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >素材已删除</div>)
		}

		/* 未提交素材 */
  	else if (ad.showPlanStatus !== 'EXECUTING' && ad.showPlanStatus !== 'FINISHED' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NULL'))) {
			return (<div className={ `icon ${ styles['ad-material-default'] }` }></div>)
		}
  }

	const onRemove = (record) => {
		Modal.confirm({
			title: '确认要删除订单？',
			cancelText: '取消',
			okText: '确定',
			onOk() {
				goToRemove(record.mallOrderPlanId)
			},
			onCancel() {
			},
		})
	}
	// 去删除
  const goToRemove = async (id) => {
		setIsSubmiting(true)
		const { data = [], message: msg, code } = await util.request({
      method: 'DELETE',
      url: `/mall/order/clearOrderPlan/${ id }`,
    })

  	if (code === 1) {
      message.success('删除成功')
			getList(1)
    } else {
      message.error(msg || '操作失败')
    }
    setIsSubmiting(false)
  }

	const onReport = (ad) => {
  	history.push(`/ad/report/home/${ ad.mallOrderPlanId }`)
  }

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <div className={ `${ styles['ad-page-tit'] }` }>
        <h3>我的广告计划</h3>
      </div>

      <div className={ `${ styles['ad-cont'] }` }>
      	<div className={ `${ styles['ad-cont-tit'] }` }>我的广告计划</div>

      	<div className={ `${ styles['ad-list-top'] }` }>
	      	{/*tab*/}
	      	<div className={ `${ styles['tab-list'] }` }>
		      	{
		      		tabList.map((tab) => {
		      			return (
		      				<div className={ `${ styles['tab-item'] } ${ curTabId === tab.id ? styles['is-selected'] : '' }` } key={ tab.id } onClick={ () => { toggleTab(tab.id) } }>{ tab.name }</div>
		      			)
		      		})
		      	}
	      	</div>
      	</div>

      	{/*list*/}
      	<Spin spinning={ isSubmiting } tip="请稍候...">
      		{
      			(list.length > 0) && (
      				<div className={ `${ styles['ad-list'] }` }>
	      				{
	      					list.map((ad) => {
	      						return (
	      							<div className={ `${ styles['ad-item'] }` } key={ ad.mallOrderPlanId }>
	      								<div className={ `${ styles['ad-labels'] }` }>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad.operatorType) }
	      										{
	      											util.tools.convertAdChannelName(ad) && (<span className={ `${ styles['channel-label'] }` }>{ util.tools.convertAdChannelName(ad) }</span>)
	      										}
	      									</div>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad.deliveryCinemaModel) }</div>
	      								</div>
												
												<div className={ `${ styles['ad-item-top-r'] }` }>
													{
														(ad?.showPlanStatus === 'ORDER_WAIT' && ad?.adAgentUcOrgId && ad?.agentPayStatus === 'YES') && <div className={ `${ styles['ad-replay-pay'] }` }>已申请代理代支付</div>
													}
	      									<div className={ `${ styles['ad-status'] }` }>{ util.tools.orderStatus(ad.showPlanStatus).text }</div>
												</div>

	      								
	      								<div className={ `${ styles['ad-item-cont'] }` } onClick={ () => { goToDetailPage(ad) } }>
	      									{ 
	      										(ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED' || (ad.showPlanStatus === 'ORDER_TIMEOUT' && !ad.showPlanStatusMore.includes('PAY_TIMEOUT') && !ad.showPlanStatusMore.includes('CANCEL_PAY') && (ad.showPlanStatusMore.includes('APPROVE_PLATFORM_TIMEOUT') || ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('APPROVE_NO')))) && (
	      												<div className={ `${ styles['ad-material'] }` }>
	      													{
	      														(ad.thumbnailUrl) && (<img className={ `${ styles['ad-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/180x75` } />)
	      													}
	      													{
	      														getLeftBanner(ad)
	      													}
  															</div>
	      											)
	      									}
	      									{/*广告图*/}
													<div className={ `${ styles['ad-item-r'] }` }>
			      								<div className={ `${ styles['delivery-name'] }` }>
															<span className={ `${ styles['delivery-name-text'] }` }>{ ad.deliveryName }</span>
															{/* 价格 */}
															<div className={ `${ styles['ad-price'] }` }>
																{
																	(ad.showPlanStatus === 'SAVE') && (
																	<div className={ `${ styles['ad-price-item'] }` }>
																		<div className={ `${ styles['ad-price-value'] }` }>
																			<div className={ `${ styles['text-price'] }` }>
																				<div className={ `${ styles['text-price-unit'] }` }>￥</div>
																				<div className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad.activityOrder?.buyPrice || ad.orderTotal?.originalBuyPrice).int }</div>
																				<div className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad.activityOrder?.buyPrice || ad.orderTotal?.originalBuyPrice).dec }</div>
																			</div>
																		</div>
																	</div>)
																}

																{
																	(ad.showPlanStatus === 'ORDER_WAIT' || ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED' || ad.showPlanStatus === 'ORDER_TIMEOUT') && (<div className={ `${ styles['ad-price-item'] }` }>
																	<div className={ `${ styles['ad-price-label'] }` }>
																		{
																			(ad.showPlanStatus === 'ORDER_WAIT' || ad.showPlanStatusMore.includes('PAY_TIMEOUT') || ad.showPlanStatusMore.includes(ad.showPlanStatusMore, 'CANCEL_PAY')) && '需'
																		}
																		{
																			(!(ad.showPlanStatus === 'ORDER_WAIT' || ad.showPlanStatusMore.includes('PAY_TIMEOUT') || ad.showPlanStatusMore.includes(ad.showPlanStatusMore, 'CANCEL_PAY'))) && '实'
																		}付款：</div>
																	<div className={ `${ styles['ad-price-value'] }` }>
																		<div className={ `${ styles['text-price'] }` }>
																			<div className={ `${ styles['text-price-unit'] }` }>￥</div>
																			<div className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad.showPlanStatus === 'SAVE' ? (ad.activityOrder?.buyPrice || ad.orderTotal?.planAmount) : ad.orderTotal?.realPayAmount).int }</div>
																			<div className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad.showPlanStatus === 'SAVE' ? (ad.activityOrder?.buyPrice || ad.orderTotal?.planAmount) : ad.orderTotal?.realPayAmount).dec }</div>
																		</div>
																	</div>
																</div>)
																}
															</div>
														</div>

			      								{/*订单详细数据*/}
														<div className={ `${ styles['ad-form'] }` }>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
																	投放周期：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ util.tools.formatDateStr(ad.deliveryStart) }-{ util.tools.formatDateStr(ad.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
																	{ 
																		(ad.deliveryDateStatus !== 'YES') && (<span className={ `${ styles['ad-form-value-tips'] }` }>已失效</span>)
																	}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
																	广告时长：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
																	{
																		(ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
																	}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
																	播放时段：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{
																		util.tools.convertTimePeriods(ad)
																	}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
																	投放点位：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ (ad.orderTotal?.pointUsableCount + ad.orderTotal?.disablePointCount) || 0 }个点位
																	{
																		(ad.orderTotal?.denyPointCount > 0) && <span className={ `${ styles['ad-form-value-tips'] }` }>{ ad.orderTotal?.denyPointCount }个谢绝投放</span>
																	}
																	{
																		(ad.showPlanStatus === 'SAVE' && ad.orderTotal?.disablePointCount > 0) && <span className={ `${ styles['ad-form-value-tips"'] }` }>{ ad.orderTotal?.disablePointCount }个已失效</span>
																	}
																</div>
															</div>
														</div>
													</div>
												</div>

												{/*右边操作及提醒*/}
												<div className={ `${ styles['ad-item-opts'] }` }>
													{/*计划关闭提醒*/}
													{
														(ad.showPlanStatus === 'ORDER_TIMEOUT') && (
															<div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.orderTimeoutStatus(ad.showPlanStatusMore) }</div>
														)
													}

													{/*待付款提醒 1分钟之内不显示提示*/}
													{
														(ad.showPlanStatus === 'ORDER_WAIT' && util.tools.hasLeftTime(ad.cancelOrderTime)) && (
															<>
																<div className={ `${ styles['ad-content-tips'] }` }>剩余付款时间<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad.cancelOrderTime) }</span></div>
															</>
															)
													}

													{/*素材提交提醒*/}
													{
														(ad.showPlanStatus === 'DELIVERY_WAIT' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) && (
															<>
																<div className={ `${ styles['ad-content-tips'] }` }>素材需在<span className={ `${ styles['ad-end-date'] }` }>{ ad.contentUploadDateEnd }</span>前提交</div>
															</>
															)
													}

													{/*刊播提醒 */}
													{
														(ad.showPlanStatus === 'DELIVERY_WAIT' && ad.showPlanStatusMore && ad.showPlanStatusMore.includes('CONTENT_YES')) && (
															<div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.startBetweenDays }</span>天</div>
															)
													}

													{/*刊播数据*/}
													{
														(ad.showPlanStatus === 'EXECUTING' && ad.hasDeliveryDays > 0) && (
															<div className={ `${ styles['ad-content-tips'] }` }>已刊播<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.hasDeliveryDays }</span>天</div>
															)
													}

													{/* 各种操作 */}
													{/*删除*/}
													{
														(ad.showPlanStatus === 'SAVE' || ad.showPlanStatus === 'ORDER_TIMEOUT') && (
															<>
																<button className={ `${ styles['btn-confirm'] }` } onClick={ () => { onRemove(ad) } }>删除</button>
															</>
															)
													}
													{/* 去结算 */}
													{
														(ad.showPlanStatus === 'SAVE') && (
															<>
																<button className={ `${ styles['btn-confirm'] }`} disabled={ ad.deliveryDateStatus !== 'YES' || !ad.orderTotal?.pointUsableCount } onClick={ () => { onConfirm(ad) } }>去结算</button>
															</>
															)
													}
													{/* 去付款 */}
													{
														(ad.showPlanStatus === 'ORDER_WAIT' && ad.agentPayStatus !== 'YES') && (
															<>
																<button className={ `${ styles['btn-confirm'] }` } onClick={ () => { goToPay(ad) } }>去付款</button>
															</>
															)
													}
													{/* 提交素材 */}
													{
														(ad.showPlanStatus === 'DELIVERY_WAIT' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) && (
															<button className={ `${ styles['btn-confirm'] }` } onClick={ () => { onMaterial(ad) } }>提交素材</button>
														)
													}
													{/*投放报告*/}
													{
														(ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED') && (
															<button className={ `${ styles['btn-confirm'] }` } onClick={ () => { onReport(ad) } }>投放报告</button>
														)
													}
												</div>
	      							</div>
	      						)
	      					})
	      				}
	      			</div>
      			)
      		}
	      	
	      	{
	          // 翻页
	          (list.length > 0) && (
	            <div className={ `${ styles.paging } flex justify-end` }>
	              <Pagination 
	                showQuickJumper 
	                showSizeChanger
	                showTotal={ total => `共 ${total} 条`}
	                defaultCurrent={ defaultCurrent }
	                current={ pageNum }
	                pageSize={ pageSize }
	                pageSizeOptions={ pageSizeOptions }
	                total={ total } 
	                onChange={ onPageChange }
	                onShowSizeChange={ onShowSizeChange }
	                 />
	            </div>
	          )
	        }

	        {
	        	list.length <= 0 && <NoRecord />
	        }
      	</Spin>
      </div>

      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } callBack={ () => { getList(1) } } />
    </div>
  )
}