import React, { useState, useEffect, useRef } from 'react'
import { DatePicker, Cascader, Slider, Spin, message, Select } from 'antd'
import { useHistory } from 'react-router-dom'
import type { Moment } from 'moment'
import { SelectCitysModal } from '../select-citys-modal'

import moment from 'moment'

import util from '../../../../libs/util'

import styles from './CreateForm.module.css'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const durationList = [15, 30, 60]
const maxDayLen = 96

type RangeValue = [Moment | null, Moment | null] | null

interface Props {
  form?: any,
  ad?: any,
  deliveryIndustryMore?: any,
  mallOrderPlanId?: string,
  reloadFn?: Function,
}

export const CreateForm: React.FC<Props> = ({ form, ad, reloadFn }) => {
  const history = useHistory()
  
  const [submitForm, setSubmitForm] = useState<any>(Object.assign({}, form))
  const [isFormChecked, setIsFormChecked] = useState(false)
  const [mallOrderPlanId, setMallOrderPlanId] = useState(ad?.mallOrderPlanId || '')

  useEffect(() => {
    if (submitForm) {
      const form = submitForm
      setSubmitForm((state) => {
        return {
          ...state,
          ...submitForm,
        }
      })
      form.deliveryStart && setSelectedDate([moment(form.deliveryStart, dateFormat), moment(form.deliveryEnd, dateFormat)])

      form.timePeriods.length && setOrder([form.timePeriods.join(','), form.playSequence].join(','))
      setPeriods(getPeriods(form.timePeriods, form.playSequence))
      setIsFormChecked(true)

      console.log('setSubmitForm', submitForm)
    }
    
    getCalendarValidRange()
    initIndustryList()

    getEle()
  }, [])

  const checkForm = (hasChecked?) => {
    let checkArr = ['deliveryStart', 'deliveryContentDuration', 'deliveryIndustry']

    const needCheckArr = checkArr.filter(i => i !== hasChecked)
    let isChecked = true
    for (let key in submitForm) {
      if (needCheckArr.includes(key) && !submitForm[key]) {
        isChecked = false
        break
      }
    }
    setIsFormChecked(isChecked)

    console.log(hasChecked, isChecked, isFormChecked)
  }

  // 投放区域
  const [selectedCityNames, setSelectedCityNames] = useState(ad?.districtList || [])
  const onSelectCityFinished = (list) => {
    setSubmitForm((state) => {
      return {
        ...state,
        administrativeAreaId: list.map(i => i.districtId),
      }
    })
    setSelectedCityNames(list)
  }

  // 投放周期
  const [selectedDate, setSelectedDate] = useState<RangeValue>(null)
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const [value, setValue] = useState<RangeValue>(null)
  // 7天起投
  const onDateChange = (date, dateString) => {
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryStart: dateString[0],
        deliveryEnd: dateString[1],
      }
    })
    if (!dateString[0]) {
      setIsFormChecked(false)
      setSelectedDate(null)
    } else {
      setSelectedDate(date)
      checkForm('deliveryStart')
    }
  }
  // TODO 重新打开后赋值
  const onOpenChange = (open: boolean) => {
    if (open) {
      setSelectedDate([null, null])
    } else {
      setSelectedDate(null)
    }
  }
  const disabledDate = (current) => {
    if (!selectedDate) {
      return false
    }
    const tooLate = selectedDate[0] && current.diff(selectedDate[0], 'days') < 6
    const tooEarly = selectedDate[1] && selectedDate[1].diff(current, 'days') < 6

    // 不能选最小日期之前的或最大日期之后的 且7天起投
    return (!!tooLate || !!tooEarly) || current && (moment(current).valueOf() < moment(minDate).valueOf() || moment(current).valueOf() > moment(maxDate).valueOf())
  }

  // 广告时长
  const onToggleDuration = (value) => {
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryContentDuration: value,
      }
    })
    checkForm('deliveryContentDuration')
  }
  
  // 播放时段
  const periodArr = new Array(10).fill(1)
  const [order, setOrder] = useState<any>()
  // 第1分钟，第2－5分钟，第6－10分钟
  const orderList = [
    {
      id: 'CPT_CORE_PLACE_RATIO',
      value: [2, 0].join(','),
      label: '倒1位置',
    },
    {
      id: 'CPT_TWO_PLACE_RATIO',
      value: [2, 99].join(','),
      label: '映前2分钟内',
    },
    {
      id: 'CPT_FIVE_PLACE_RATIO',
      value: [5, 99].join(','),
      label: '映前5分钟内',
    },
    {
      id: 'CPT_TEN_PLACE_RATIO',
      value: [6,7,8,9,10, 99].join(','),
      label: '映前6-10分钟',
    },
  ]
  const [periods, setPeriods] = useState<any>([])
  const onOrderChange = (tempValue) => {
    const value = tempValue.split(',')
    const len = value.length
    const timePeriods = len === 1 ? [value[0]] : value.slice(0, len - 1)
    const playSequence = value[len - 1]
    setOrder(tempValue)
    setSubmitForm((state) => {
      return {
        ...state,
        timePeriods,
        playSequence,
      }
    })
    setPeriods(getPeriods(timePeriods, playSequence))
    checkForm('timePeriods')
  }
  const getPeriods = (timePeriods, playSequence) => {
    let temPeriods:any = []
    if (timePeriods.length > 1) {
      timePeriods.forEach(i => {
        temPeriods.push(parseInt(i))
      })
    } else {
      if (parseInt(playSequence) === 0) {
        temPeriods = [1]
      } else {
        for (let i = 1; i <= timePeriods[0]; i++) {
          temPeriods.push(i)
        }
      }
    }
    return temPeriods
  }

  // 广告所属行业
  const [currentIndustry, setCurrentIndustry] = useState('INDUSTRY')
  const [industryOptions, setIndustryOptions] = useState<any>([])
  const [selectedIndustry, setSelectedIndustry] = useState<any>(ad?.deliveryIndustryMore ? [ad?.deliveryIndustryMore.groupKey, Object.keys(ad?.deliveryIndustryMore.itemMap).join(',')] : [])
  const onIndustryChange = (value, selectedOptions) => {
    const deliveryIndustry = value && value.length > 1 ? value[1] : ''
    setSubmitForm((state) => {
      return {
        ...state,
        deliveryIndustry,
      }
    })
    setSelectedIndustry(value)
    if (!deliveryIndustry) {
      setIsFormChecked(false)
    } else {
      checkForm('deliveryIndustry')
    }
  }
  const getIndustryList = async(cIndustry) => {
    const params = {
      groupKeyArray: cIndustry,
      excludeItems: cIndustry === 'INDUSTRY' ? ['AGENT', 'PUBLIC-AD-TYPE'].join(',') : null,
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    return { data, code }
  }
  const initIndustryList = async() => {
    const { code, data } = await getIndustryList(currentIndustry)
    let list = data[currentIndustry]
    if (code === 1) {
      list.forEach(i => {
        i.value = i.itemKey
        i.label = i.itemValue
        i.isLeaf = false
      })
      
      const result = await getIndustryList(list.map(i => i.itemKey).join(','))
      if (result.code === 1) {
        const childrenList = result.data
        list.forEach(i => {
          const itemKey = i.itemKey
          i.value = itemKey
          i.label = i.itemValue
          i.isLeaf = false
          i.children = childrenList[itemKey]
          i.children.forEach(j => {
            j.value = j.itemKey
            j.label = j.itemValue
            j.isLeaf = true
          })
        })
        setIndustryOptions([...list])
      }
    }
  }

  // 获取日期可选区间
  const getCalendarValidRange = async() => {
    const { data, code } = await  util.request({
      method: 'GET',
      url: `/config/biz/getBizConfig`,
      params: {
        itemKey: 'CINEMA_AD_DELIVERY_START_DAY',
      }
    })

    if (code === 1) {
      const minDate = new Date(+new Date() + data.itemValue * 24 * 60 * 60 * 1000)
      const customMinDate = (Math.abs(+new Date(util.tools.formatDate(new Date())) - minDate.getTime()) / (24 * 60 * 60 * 1000))
      const maxDate = new Date(minDate.getTime() + (maxDayLen * (24 * 60 * 60 * 1000)))

      setMinDate(util.tools.formatDate(minDate))
      setMaxDate(util.tools.formatDate(maxDate))

      console.log('getCalendarValidRange', customMinDate, util.tools.formatDate(minDate), util.tools.formatDate(maxDate))
    }
  }

  const [isSpinning, setIsSpinning] = useState(false)
  // 创建广告计划
  const onCreate = async() => {
    setIsSpinning(true)
    const { data, code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/createOrderPlan`,
      data: submitForm,
    })

    setIsSpinning(false)
    if (code === 1) {
      message.success('操作成功！')
      history.push(`/ad/detail/${ data.mallOrderPlanId }`)
    } else {
      message.error(msg || '操作失败！')
    }
  }

  // 更新
  const onUpdate = async() => {
  	if (isSpinning) {
      return
    }

    checkForm()
    if (!isFormChecked) {
      return
    }

    let form = { ...submitForm }

    // 创建订单时 deliveryModel不传了 目前都是SPLIT
    delete submitForm.deliveryModel

    setIsSpinning(true)

    const { data, code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
	      mallOrderPlanId,
	      ...submitForm,
	    },
    })

    if (submitForm.operatorType !== 'CINEMA') {
      delete submitForm.deliveryCinemaModel
    }

    setIsSpinning(false)

    if (code === 1) {
      reloadFn && reloadFn()
    } else {
    	message.error(msg || `操作失败`)
    }
  }
  
  const selectCitysModalRef: any = useRef()
  const showSelectCitysModal = () => {
    selectCitysModalRef.current.showModal()
  }

  const [cptStandardPriceRatio, setCptStandardPriceRatio] = useState<any>([])
  const getEle = async() => {
    const key = 'CPT_STANDARD_PRICE_RATIO_TWO'
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: key,
      },
    })

    if (code === 1) {
      console.log()
      setCptStandardPriceRatio(data[key])
    }
  }
  const getTips = (id) => {
    if (!cptStandardPriceRatio.length) {
      return ''
    }
    const value = cptStandardPriceRatio?.find(i => i.itemKey == id).itemValue
    if (value > 1) {
      return `原价×${ value }`
    } else if (value == 1) {
      return `原价`
    } else {
      return `${ value * 10 }折`
    } 
  }

  return (
  	<Spin spinning={ isSpinning }>
    <div className={ `${ styles['ad-form'] }` }>
      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-location` }></div>
          投放区域：
        </div>
        <div className={ `${ styles['ad-form-value'] } ${ styles['ad-form-select'] } ${ submitForm.administrativeAreaId.length <=0 ? '' : styles['start'] }` } onClick={ showSelectCitysModal }>
          {
            (submitForm.administrativeAreaId.length > 0) && (
              <>
              {
                (selectedCityNames.length > 2) && (
                  <>
                    { selectedCityNames[0].districtName }
                    {
                      (selectedCityNames.length > 1) && (<>等{ selectedCityNames.length }个</>)
                    }
                  </>
                )
              }
              {
                (selectedCityNames.length <= 2) && (<>
                  {
                    selectedCityNames.map((district, index) => {
                      return (
                        <span key={ index }>
                          { district.districtName }
                          {
                            (index < selectedCityNames.length - 1) && ('，')
                          }
                        </span>
                      )
                    })
                  }
                </>)
              }
              </>
            )
          }
          {
            (submitForm.administrativeAreaId.length <=0 ) && (<>
              <div className={ `${ styles['ad-form-placeholder'] }` }>请选择</div>
              <div className={ `ifont i-trangle-b ${ styles['icon-arrow'] }` }></div>
            </>)
          }
        </div>
      </div>

      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
          投放周期：
        </div>
        <div className={ `${ styles['ad-form-value'] }` }>
          <RangePicker
            size="large"
            format={ dateFormat }
            style={{ width: 365 }}
            value={ selectedDate || value }
            disabledDate={ disabledDate }
            onCalendarChange={ onDateChange }
            onChange={ val => setValue(val) }
            onOpenChange={ onOpenChange }
          />
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
          广告时长：
        </div>
        <div className={ `${ styles['ad-form-value'] } ${ styles['flex'] }` }>
          
          {
            durationList.map((duration, index) => {
              return (
                <div 
                  className={ `${ styles['duration-item'] } ${ submitForm.deliveryContentDuration === duration ? styles['is-selected'] : '' }` } 
                  key={ index }
                  onClick={ () => onToggleDuration(duration) }
                  >
                  { duration }秒
                </div>
              )
            })
          }
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] } ${ styles['baseline'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
          播放时段：
        </div>
        <div className={ `${ styles['ad-form-value'] } ${ styles['col'] }` }>
          <div className={ `${ styles['ad-form-value'] } ${ styles['flex'] }` }>
          {
            orderList.map((item, index) => {
              return (
                <div 
                  className={ `${ styles['duration-item'] } ${ order === item.value ? styles['is-selected'] : '' }` } 
                  key={ index }
                  onClick={ () => onOrderChange(item.value) }
                  >
                  { item.label }<span className={ `${ styles['duration-item-tips'] }` }>{ getTips(item.id) }</span>
                </div>
              )
            })
          }
          </div>
          <div className={ `${ styles['periods'] }` }>
            <div className={ `${ styles['periods-label'] }` }>正片<br/>放映</div>
            <div className={ `${ styles['periods-list-wrap'] }` }>
              <div className={ `${ styles['periods-list-label'] }` }>银幕广告时间</div>
              <div className={ `${ styles['periods-list'] }` }>
              {
                periodArr.map((item, index) => {
                  return (
                    <div className={ `${ styles['periods-item'] }` } key={ index }>
                      <div className={ `${ styles['periods-item-label'] } ${ periods.includes(index + 1) ? styles['is-selected'] : '' }` }></div>
                      <div className={ `${ styles['periods-item-value'] }` }>{ index + 1 }</div>
                    </div>
                  )
                })
              }
              </div>
            </div>
            <div className={ `${ styles['periods-label'] }` }>检票<br/>时间</div>
          </div>
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] }` }>
        <div className={ `${ styles['ad-form-label'] }` }>
          <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
          广告所属行业：
        </div>
        <div className={ `${ styles['ad-form-value'] }` }>
          <Cascader
            options={ industryOptions }
            onChange={ onIndustryChange }
            value={ selectedIndustry }
            changeOnSelect
            size="large"
            style={{ width: 365 }}
          />
        </div>
      </div>
      <div className={ `${ styles['ad-form-item'] } ${ styles['flex-end'] }` }>
      	{
      		!mallOrderPlanId && <button className={ `${ styles['btn-create'] }` } disabled={ !isFormChecked } onClick={ onCreate }>创建广告计划</button>
      	}
      	{
      		mallOrderPlanId && <button className={ `${ styles['btn-create'] }` } disabled={ !isFormChecked } onClick={ onUpdate }>完成</button>
      	}
      </div>

      {/* 选择投放区域 */}
      <SelectCitysModal childRef={ selectCitysModalRef } selectedCities={ selectedCityNames } onSelectCityFinished={ onSelectCityFinished } />
    </div>
    </Spin>
  )
}