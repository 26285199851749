import { 
  MineHome,
  UserCenter,
} from '../pages'

const routes = [
	{
    path: '/mine/home',
    component: MineHome,
  },
  {
    path: '/user/center',
    component: UserCenter,
  },
]

export default routes