import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Input } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../../libs/util.constants'
import { AddMaterialModal } from '../add-material'
import { PreviewEffectModal } from '../preview-effect-modal'
import { CreateForm } from '../create-form'
import { ScreenEffectModal } from '../screen-effect-modal'

import { Ad, defaultAd } from '../../index'
import styles from './SimpleBaseInfo.module.css'

interface Props {
  ad: Ad,
  onUpDateDeliveryName?: Function,
  onUpdateBaseInfo?: Function,
  isConfirmPage?: boolean,
}

// 确认广告计划时
export const SimpleBaseInfo: React.FC<Props> = ({ ad, onUpDateDeliveryName, onUpdateBaseInfo, isConfirmPage }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [isEditMode, setIsEditMode] = useState(!isConfirmPage)
  const [showMaterial, setShowMaterial] = useState(true)

  const [submitForm, setSubmitForm] = useState<any>()
  useEffect(() => {
    setSubmitForm({
      planAim: ad.planAim,
      administrativeAreaId: ad?.administrativeAreaId,
      deliveryStart: ad.deliveryStart,
      deliveryEnd: ad.deliveryEnd,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad?.deliveryIndustry,
      timePeriods: ad?.timePeriods,
      customerId: ad?.customerId,
    })
  }, [])
  useEffect(() => {
    getDayOrderState()
  }, [ad.mallOrderPlanId])

  const screenEffectModalRef: any = useRef()
  const onShowMaterial = () => {
    screenEffectModalRef.current.onToggle()
  }

  const previewMedia = () => {

  }

  const addMaterialModalRef: any = useRef()
  const onMaterial = (record) => {
    hideMenu()
    addMaterialModalRef.current.showModal(record)
  }

  const [isMenuShow, setIsMenuShow] = useState(false)
  const showMenu = () => {
    setIsMenuShow(true)
  }
  const hideMenu = () => {
    setIsMenuShow(false)
  }
  const onChangeMaterial = () => {
    history.push(`/ad/content/list/${ ad.mallOrderPlanId }`)
    hideMenu()
  }

  const previewEffectModalRef: any = useRef()
  const onPreview = (record) => {
    previewEffectModalRef.current.showModal(record)
  }

  // 广告所属行业
  const getDeliveryIndustry = (ad) => {
    return (<>{ Object.values(ad.deliveryIndustryMore?.itemMap).join(',') }</>)
  }

  // 是否显示左边
  const getNeedShowLeft = (ad) => {
    return showMaterial || (ad.showPlanStatus === 'DELIVERY_WAIT' && (ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH')))
  }

  const [deliveryName, setDeliveryName] = useState('')
  // 获取广告计划序号
  const getDayOrderState = async() => {
    if (!ad.mallOrderPlanId) {
      return
    }
    const { data } = await util.request({
      method: 'GET',
      url: `/mall/order/getDayOrderState`,
    })
    const deliveryNameIndex = data + 1
    const districtList = ad.districtList || []
    const operatorTypeName = operatorTypeItems.find(i => i.itemKey === ad.operatorType)?.itemValue

    const deliveryName = ad.deliveryName || `${ districtList[0].districtName }${ districtList.length > 1 ? districtList.length + '城' : '' }-${ ad.deliveryStart?.split('-').join('') }至${ ad.deliveryEnd?.split('-').join('') }-${ operatorTypeName }-${ util.tools.fixZero(deliveryNameIndex, 3) }`
    setDeliveryName(deliveryName)
    onUpDateDeliveryName && onUpDateDeliveryName(deliveryName)
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const afterUpdateBaseInfo = () => {
    onModalCancel()
    onUpdateBaseInfo && onUpdateBaseInfo()
  }

  const inputRef = useRef<any>(null)
  const onDeliveryNameChange = ({ target }) => {
    setDeliveryName(target.value)
  }
  const onDeliveryNameBlur = async() => {
    // 更新订单(保存订单名)
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName: deliveryName,
      },
    })

    if (code !== 1) {
      message.error(msg || '操作失败')
    } else {
      message.success('广告计划名称更新成功')
      onUpDateDeliveryName && onUpDateDeliveryName(deliveryName)
    }
  }
  const clearInput = () => {
    setDeliveryName('')
  }
  const focusInput = () => {
    inputRef.current.focus({
      cursor: 'start',
    })
  }

  const getHasMenu = () => {
    return ad?.showPlanStatusMore && ad?.showPlanStatusMore.length && ad.showPlanStatusMore[0] === 'CONTENT_YES' && (ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED')
  }

  const getHasSeeRequirements = () => {
    return ad.showPlanStatus === 'DELIVERY_WAIT' && (ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))
  }

  return (
  	<>
			<div className={ `${ styles['ad-page-top'] }` }>
        <div className={ `${ styles['ad-labels'] }` }>
          <div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad?.operatorType) }
            {
              util.tools.convertAdChannelName(ad) && (<span className={ `${ styles['channel-label'] }` }>{ util.tools.convertAdChannelName(ad) }</span>)
            }
          </div>
          <div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad?.deliveryCinemaModel) }</div>
        </div>
      </div>

      <div className={ `${ styles['ad-page-cont-inner'] }` }>     
        <div className={ `${ styles['ad-page-cont-inner-r'] }` }>
          {
            (ad?.deliveryName) && (
              <div className={ `${ styles['flex'] }` }>
                <div className={ `${ styles['ad-tit-label'] }` }>广告计划名称</div>
                <div className={ `${ styles['delivery-name'] }` }>
                  {
                    isConfirmPage && (
                      <Input 
                        className={ `${ styles['delivery-name-ipt'] }` } 
                        value={ deliveryName }
                        size='large'
                        ref= { inputRef }
                        onChange={ onDeliveryNameChange }
                        onBlur={ onDeliveryNameBlur }
                        maxLength={ 32 }
                         />
                    )
                  }

                  { !isConfirmPage && ad.deliveryName }
                </div>
              </div>
            )
          }

          <div className={ `${ styles['ad-form-wrap'] }` }>
            <div className={ `${ styles['ad-tit-label'] }` }>投放需求</div>
            {
              (isEditMode) && (
                <div className={ `${ styles['btn-edit'] }` } onClick={ showModal }><span className={ `${ styles['icon-pencile'] } imediafont i-pencile-simple` }></span>编辑投放需求</div>
              )
            }

            {/*订单详细数据*/}
            {
              ad?.timePeriods && (
                <div className={ `${ styles['ad-form'] }` }>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-location` }></div>
                      投放区域：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        (ad?.districtList && ad?.districtList?.length > 2) && (
                          <>
                            { ad.districtList[0].districtName }
                            {
                              (ad.districtList.length > 1) && (<>等{ ad.districtList.length }个</>)
                            }
                          </>
                        )
                      }
                      {
                        (ad.districtList && ad.districtList.length && ad.districtList.length <= 2) && (<>
                          {
                            ad.districtList.map((district, index, arr) => {
                              return (
                                <>
                                  { district.districtName }
                                  {
                                    (index < arr.length - 1) && (',')
                                  }
                                </>
                              )
                            })
                          }
                        </>)
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
                      投放周期：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      { util.tools.formatDateStr(ad.deliveryStart) }-{ util.tools.formatDateStr(ad.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
                      广告时长：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
                      {
                        (ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
                      播放时段：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        util.tools.convertTimePeriods(ad)
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
                      广告所属行业：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        getDeliveryIndustry(ad)
                      }
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>

      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } />

      {/* 编辑投放需求 弹窗*/}
      <Modal 
        width={ 1104 }
        title="编辑投放需求"
        footer={ null }
        visible={ isModalVisible }
        onCancel={ onModalCancel }
        >
          {/* 表单 */}
          <CreateForm 
            form={ submitForm } 
            deliveryIndustryMore={ ad?.deliveryIndustryMore } 
            mallOrderPlanId={ ad?.mallOrderPlanId }
            reloadFn={ afterUpdateBaseInfo } />
      </Modal>

      {/*效果预览*/}
      {
        (getHasMenu()) && <PreviewEffectModal childRef={ previewEffectModalRef } imageUrl={ `${ baseURL }${ ad?.thumbnailUrl[0] }` } />
      }

      {/*广告素材制作要求*/}
      <ScreenEffectModal childRef={ screenEffectModalRef }  />
  	</>
  )
}