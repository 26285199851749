import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { getUserInfoAction } from '../../store/user/action'
import { UPDATE_USER } from '../../store/actionTypes'
import { defaultState as defaultUserState } from '../../store/user/reducer'
import styles from './Header.module.css'

import { useLocation, useHistory, Link } from 'react-router-dom'

import { appContext } from '../../App.state'

import miniCode from '../../assets/images/common/mini-code.jpg'

import util from '../../libs/util'
import { Line } from '../../components'
import { SignUpGuideModal } from './signUpGuideModal'
import { UserInfoModal } from './userInfoModal'

interface PropsType {
  fixed?: boolean,
  hasLogoLink?: boolean,
  isHomePage?: boolean,
}

interface AuthInfoType {
  chinaName: string
}

interface MyUserInfoType {
  userType: 'COMPANY' | 'PERSONAL',
  productId: 'MEDIA' | string,
}

const defaultAuthInfo: AuthInfoType = {
  chinaName: ''
}

const defaultMyUserInfo: MyUserInfoType = {
  userType: 'COMPANY',
  productId: 'MEDIA'
}

export const Header: React.FC<PropsType> = (props) => {
  const isHomePage = !!props.isHomePage
  // const [isHomePage] = useState(!!props.isHomePage)
  const appValue = useContext(appContext)

  const [fixed] = useState(props.fixed === undefined ? true : props.fixed)
  const [hasLogoLink] = useState(props.hasLogoLink === undefined ? true : props.hasLogoLink)
  const location = useLocation()
  const history = useHistory()
  const childRef: any = useRef()

  const [pathname, setPathname] = useState<string>('')
  

  const [authInfo, setAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [unAuthInfo, setUnAuthInfo] = useState<AuthInfoType | null>(defaultAuthInfo)
  const [myUserInfo, setMyUserInfo] = useState<MyUserInfoType>(defaultMyUserInfo)
  const [userRoleList, setUserRoleList] = useState<any[]>([])

  const [signedIn, setSignedIn] = useState(false)
  useEffect(() => {
    const userToken = util.storage.get('userToken')
    userToken ? setSignedIn(true) : setSignedIn(false)
    if (userToken) {
      dispatch(getUserInfoAction({}))
    }
  }, [])

  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const onScroll = e => {
      setScrollTop(window.pageYOffset)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  useEffect(() => {
    getPathname()
  }, [signedIn])

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  const dispatch = useDispatch()

  useEffect(() => {
    setUserInfo()
  }, [userInfo])

  const getPathname = () => {
    const pathname = location.pathname
    setPathname(pathname)
    if (pathname === '/sign/signIn') {
      util.storage.delete('userToken')
      setSignedIn(false)
    }
  }

  const onSignUpGuide = () => {
    childRef.current.onToggle()
  }

  const setUserInfo = () => {
    const { companyAuth, personAuth, myUserInfo, userRoleList } = userInfo
    if (!myUserInfo) {
      util.storage.delete('userToken')
      setSignedIn(false)
      return
    }

    if (myUserInfo.userType === 'PERSON') {
      setAuthInfo(personAuth)
      setUnAuthInfo(companyAuth)
      setUserRoleList(userRoleList)
    } else if (myUserInfo.userType === 'COMPANY') {
      setAuthInfo(companyAuth)
      setUnAuthInfo(personAuth)
    }

    setMyUserInfo(myUserInfo)
  }

  const onLogout = async () => {
    const result = await util.request({
      method: 'POST',
      url: '/uc/userLogout'
    })

    if (result.code === 1) {
      setSignedIn(false)
      // util.storage.delete('userToken')
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      util.storage.clear()
      util.Cookies.remove('userToken', { path: '/', domain: '.remudooh.com' })
      history.replace('/sign/signIn')
    }
  }

  const navHome = () => {
    history.replace('/home')
  }

  const goToCinemaPage = () => {
    const url = `${ appValue.cinemaPageUrl }/assistant/login`
    console.log('goToCinemaPage', url)

    // 新开窗
    const win: any = window.open(url, '_blank')
    win.focus()
  }

  const goToMediaPage = () => {
    const env = appValue.env
    const url = `https://${ env }media.remudooh.com${ env ? ':8888' : ''}/`
    console.log('goToMediaPage', url)

    const win: any = window.open(url, '_blank')
    win.focus()
  }

  const goToPlatForm = () => {
    history.replace('/mine/home')
  }

  const goToSignin = () => {
    history.replace('/sign/signIn')
  }

  return (
    <header className={ `${ styles.header } flex justify-between align-center ${ fixed ? '' : styles['static']} ${ scrollTop <= 0 ? styles['is-first-page'] : '' } ${ isHomePage ? styles['is-home'] : '' }` }>
      <div className={ `${ styles['header-inner'] }` }>
        <div className={ `${ styles.left } flex justify-start align-center` }>
          <div className={ styles.logo } onClick={ navHome }></div>
        
          {/*{
            (signedIn && myUserInfo.userType === 'COMPANY' && myUserInfo.productId === 'MEDIA') ? 
            <div className="ml4 mt4">
              切换为<span style={{ color: '#E5922F' }}>广告主端</span>
            </div>
            : 
            ''
          }*/}
          
        </div>

        <div className={ `${ styles['header-r'] }` }>
          {
            pathname !== '/sign/signIn' && pathname !== '/choose' ?
            (
              <div className={ `flex align-center` }>
                {/*{
                  signedIn === false && 
                  <div className={ `${ styles['header-r-item'] } ${ styles['to-media'] }` } onClick={ goToMediaPage }>
                    <div className={ `${ styles.name }` }>媒体商户中心</div>
                  </div>
                }*/}
                

                <div className={ `${ styles['header-r-item'] } ${ styles.miniprogram }` }>
                  <div className={ `flex justify-start align-center` }>
                    <i className={ `ifont i-minicode-0 ${ styles['icon-mini'] }` }></i>
                    <div className={ `${ styles.name }` }>微信小程序</div>
                  </div>

                  <div className={ `${ styles['header-r-item'] } ${ styles['mini-code'] } tc` }>
                    <img width="180" src={ miniCode } alt="" />
                    <h3 className={ `pt2 tc` }>微信扫描小程序码</h3>
                  </div>
                </div>
              </div>
            ) : null
          }

          {
            signedIn && isHomePage && <div className={ `${ styles['btn-platform'] }` } onClick={ goToPlatForm }>工作台</div>
          }
          
          {
            (pathname !== '/sign/signIn' && pathname !== '/choose' && signedIn === false) ?
            (
              <>
              {/*<div className={ `${ styles['header-r-item'] } ${ styles.line }` }></div>*/}
              <div className={ `${ styles['header-r-item'] } ${ styles.user } flex align-center` }>
                {/*<i className={ `ifont i-user ${ styles['icon-user'] }` }></i>*/}
                <div className={ `${ styles['sign-in'] }` } onClick={ goToSignin }>登录</div>
                <div className={ styles['btn-register'] } onClick={ onSignUpGuide }>注册</div>
              </div>
              </>
            ) 
            : 
            (
              signedIn && 
              <>
                {/*<div className={ `${ styles['header-r-item'] } ${ styles.line }` }></div>*/}
                <div className={ `${ styles['header-r-item'] } ${ styles['last'] }` }>
                  <UserInfoModal
                    authInfo={ authInfo }
                    unAuthInfo={ unAuthInfo }
                    myUserInfo={ myUserInfo }
                    isHomePage={ isHomePage }
                    isFirstPage={ scrollTop <= 0 }
                    userRoleList={ userRoleList }
                    onLogout={ onLogout }
                     />
                </div>
              </>
            )
          }
        </div>

        <SignUpGuideModal childRef={ childRef } />
      </div>
    </header>
  )
}