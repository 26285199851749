import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Modal, } from 'antd'
import { RootState } from '../../../../../store'
import styles from './Profile.module.css'

import util from '../../../../../libs/util'

import { Line, Icons } from '../../../../../components'
import { MemberShip } from './membership'

export const Profile: React.FC = () => {
  const [currUserInfo, setCurrUserInfo] = useState<any>({})
  const [bindingNumber, setBindingNumber] = useState('')

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })

  useEffect(() => {
    if (!userInfo.myUserInfo.userType) {
      return
    }
    setCurrUserInfo(userInfo.companyAuth)
    const phoneNumber = userInfo.myUserInfo.phoneNumber || userInfo.myUserInfo?.bindingNumber.split('-')[1]
    setBindingNumber(util.tools.convertPhone(phoneNumber))

    getCurrentOrgGrade()
  }, [userInfo])

  const history = useHistory()
  const goToUserPage = () => {
    history.push('/user/center')
  }

  const [level, setLevel] = useState(1)
  const getCurrentOrgGrade = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: '/uc/org/getCurrentOrgGrade',
    })

    if (code === 1) {
      setLevel(data.orgGrade)
    }
  }

  // 会员弹窗
  const memberShipModalRef: any = useRef()
  const showMemberShipModal = () => {
    memberShipModalRef.current.showModal()
  }

  return (
    <div className={ `${ styles.profile } flex align-center` }>
      <div className={ styles.avatar }>
        {
          currUserInfo?.avatarUrl ? 
          <img width="112" src={ currUserInfo?.avatarUrl } alt="" />
          :
          <div className={ `${ styles['icon-avatar'] } ifont i-building` }></div>
        }
      </div>
      <div className={ styles['main-info'] }>
        <h3 className={ `${ styles.name }` }>{ currUserInfo?.chinaName }
          <span className={ `icon ${ styles['icon-level'] } ${ level === 1 ? styles['icon-level-1'] : styles['icon-level-2'] }` } onClick={ showMemberShipModal }></span>
        </h3>
        <div className={ `${ styles.excerpt } flex` }>热幕号：{ currUserInfo?.userShowId } <span className="ml1"><Icons color="#FE8135" icon="company-auth" /></span></div>
        <div className={ `${ styles.excerpt } flex` }>绑定手机号：{ bindingNumber }</div>
      </div>

      <div className={ styles['btn-link'] } onClick={ goToUserPage }>进入用户中心<span className={ `${ styles['icon-link'] } ifont i-trangle-b` }></span></div>
      
      <MemberShip childRef={ memberShipModalRef } />
    </div>
  )
}