import moment from 'moment'
import events from 'events'
import request from './util.request'
import storage from './util.storage'
import tools from './util.tools'
import Cookies from 'js-cookie'


const util = {
  moment,
  bus: new events(),
  request,
  storage,
  tools,
  Cookies,
}

export default util