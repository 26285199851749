import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { RootState } from '../../../../../store'
import styles from './Profile.module.css'

import util from '../../../../../libs/util'

import { Line, Nail, Icons } from '../../../../../components'
import { roleList } from '../../../../../components/nail'

export const Profile: React.FC = () => {
  const [currUserInfo, setCurrUserInfo] = useState<any>({})
  const [bindingNumber, setBindingNumber] = useState('')
  const [roleLabel, setRoleLabel] = useState<any>()

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })

  useEffect(() => {
    if (!userInfo.myUserInfo.userType) {
      return
    }
    setCurrUserInfo(Object.assign({}, userInfo.myUserInfo, userInfo.personAuth))
    setBindingNumber(util.tools.convertPhone(userInfo.myUserInfo.phoneNumber))

    const { userRoleList } = userInfo
    if (userInfo.myUserInfo.userType !== 'COMPANY' && userRoleList && userRoleList.length) {
      const role = userRoleList[0]
      setRoleLabel(roleList?.find(i => i.id === role?.roleItemKey)?.name)
    }
  }, [userInfo])

  const history = useHistory()
  const goToUserPage = () => {
    history.push('/user/center')
  }

  return (
    <div className={ `${ styles.profile } flex align-center` }>
      <div className={ styles.avatar }>
        {
          currUserInfo?.avatarUrl ? 
          <img width="112" src={ currUserInfo?.avatarUrl } alt="" />
          :
          <div className={ `${ styles['icon-avatar'] } ifont i-user }` }></div>
        }
      </div>
      <div className={ styles['main-info'] }>
        <h3 className={ `${ styles.name }` }>{ currUserInfo?.chinaName || '匿名' }
          {
            roleLabel && <span className={ `${ styles['role-label'] }` }>{ roleLabel }</span>
          }
        </h3>
        <div className={ `${ styles.excerpt } flex` }>热幕号：{ currUserInfo?.userShowId } <span className="ml1"><Icons color="#FE8135" icon="personal-auth" /></span></div>
        <div className={ `${ styles.excerpt } flex` }>绑定手机号：{ bindingNumber }{/*<Line />所属公司：{ userInfo.companyAuth?.chinaName }*/}</div>
        <div className={ `${ styles.excerpt } flex` }>所属企业：{ userInfo.companyAuth?.chinaName }</div>
      </div>

      <div className={ styles['btn-link'] } onClick={ goToUserPage }>进入用户中心<span className={ `${ styles['icon-link'] } ifont i-trangle-b` }></span></div>
    </div>
  )
}