import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { getUserInfoAction } from '../../../store/user/action'
import styles from './SignIn.module.css'

import util from '../../../libs/util'
import store from '../../../redux/store'
import auth from './auth'

import { Footer } from '../../../components'
import { CompanySignIn, PersonalSignIn } from './index'

interface Props {
  handleSignIn?: () => void
}

interface Tabs {
  key: string,
  name: string,
  active: boolean
}

export const inputStyle = {
  paddingLeft: '16px',
  height: '56px', 
  borderRadius: '8px', 
  backgroundColor: '#F5F5F5',
  fontSize: '16px',
}

const defaultTabs = [
  {
    key: 'COMPANY',
    name: '企业登录',
    active: true
  },
  {
    key: 'PERSONAL',
    name: '个人登录',
    active: false
  }
]

export const SignIn: React.FC<Props> = (props) => {
  const history = useHistory()

  const [loginMode, setLoginMode] = useState<'COMPANY' | 'PERSONAL'>('COMPANY')
  const [tabs, setTabs] = useState<Tabs[]>(defaultTabs)

  const dispatch = useDispatch()

  useEffect(() => {
    console.log(defaultTabs)
    defaultTabs.map(item => {
      if (item.key === 'COMPANY') {
        item.active = true
      } else {
        item.active = false
      }
    })

    setTabs([...defaultTabs])
    // util.storage.delete('userToken')
    util.storage.clear()
  }, [])

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  useEffect(() => {
    console.log('SignIn', userInfo)
    // 已登录
    if (userInfo.myUserInfo && userInfo.myUserInfo.userType) {
      const myAuth: any = auth(userInfo)

      history.push({ 
        pathname: myAuth.url, 
        state: myAuth 
      })
    }
  }, [userInfo])

  const handleSignIn = async () => {
    // getUserInfo()
    console.log('handleSignIn')
    dispatch(getUserInfoAction({}))
  }

  const onSwitch = (event) => {
    tabs.map((item, index) => {
      if(item.key === event.key) {
        item.active = true
      }else {
        item.active = false
      }
    })

    setLoginMode(event.key)
    setTabs([...tabs])
  }

  const handleSignUp = () => {
    util.bus.emit('handleSignUp')
  }

  return (
    <div className={ styles['sign-in'] }>
      <div className={ `${ styles.sign }` }>
        <div className={ styles.tabs }>
          <div className={ `${ styles.switch }` }>
            <div className="flex justify-start px14">
              {
                tabs.map((item, index) => {
                  return (
                    <div 
                      className={ `${ styles.tab } ${ item.active ? styles.active : '' } pb2 mr3` }
                      key={ index }
                      data-item={ item }
                      onClick={ onSwitch.bind(this, item) }
                    >{ item.name }</div>
                  )
                })
              }
            </div>
          </div>

          <div className={ `${ styles.panel } px14` }>
            { loginMode === 'COMPANY' ? 
              <CompanySignIn handleSignIn={ handleSignIn } /> : 
              <PersonalSignIn handleSignIn={ handleSignIn } /> 
            }
            <p 
              className={ styles['btn-registe'] }
              onClick={ handleSignUp }
            >注册</p>
          </div>
        </div>
      </div>

      <div className={ styles.footer }>
        <Footer />
      </div>
    </div>
  )
}