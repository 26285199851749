interface User {}

const defaultState: User = {}

export default (state = defaultState, action) => {
  const { type, payload } = action
  switch (type) {
    case 'UPDATE_USER':
      return { ...payload }
    default:
      return state
  }
}