import React, { useState, useImperativeHandle, useRef } from 'react'
import { Modal } from 'antd'
import styles from './PreviewImg.module.css'
import logo from '../../assets/logo.png'

interface Props {
  childRef?: any,
  isVideo?: boolean,
  entityInfo?: any,
}

export const PreviewImg: React.FC<Props> = ({ childRef, isVideo, entityInfo }) => {
  useImperativeHandle(childRef, () => ({
    onToggle(url) {
      setCurImgUrl(url)
      showModal()
    }
  }))

  const [isPreModalVisible, setIsPreModalVisible] = useState(false)
  const [curImgUrl, setCurImgUrl] = useState('')
  const showModal = () => {
    setIsPreModalVisible(true)
    console.log('entityInfo', entityInfo)
  }

  const videoRef = useRef<any>(null)
  const onModalCancel = () => {
    setIsPreModalVisible(false)
    videoRef?.current?.pause()
  }
  
  return (
  	<Modal 
      title="查看大图" 
      width={ 1248 }
      visible={ isPreModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={ onModalCancel }
      >
        <div className={ `${ styles['preview-cont'] }` }>
          {
            isVideo && <video src={ curImgUrl } ref= { videoRef } controls className={ `${ styles['preview-img'] }` } />
          }
          {
            !isVideo && <img src={ curImgUrl } className={ `${ styles['preview-img'] }` } />
          }
          {
            (!isVideo && entityInfo) && <div className={ `${ styles['preview-label'] }` }>
              <div className={ `${ styles['preview-logo-wrap'] }` }><img className={ `${ styles['preview-logo'] }` } width="30" src={ logo } alt="" />{ entityInfo.entityName }，{ entityInfo.pointName }</div>
              {
                (entityInfo.exifDateTime && entityInfo.exifDateTime !== '1970-01-01 00:00:00') && (<div>拍摄时间: { entityInfo.exifDateTime }</div>)
              }
            </div>
          }
        </div>
    </Modal>
  )
}