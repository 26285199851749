import React, { useState, useEffect } from 'react'
import styles from './Staff.module.css'

import util from '../../../../../libs/util'

interface ListItem {
  value: number,
  name: string,
  id: string,
  color: string
}

const defaultList: ListItem[] = [
  {
    value: 0,
    name: '管理员',
    id: 'AD_MANAGER',
    color: '#FE8135'
  },
  {
    value: 1,
    name: '营销人员',
    id: 'AD_OPERATION',
    color: '#2395FF'
  },
]

export const Staff: React.FC = () => {
  const [list, setList] = useState<ListItem[]>(defaultList)

  useEffect(() => {
    getOrgSummary()
  }, [])

  const getOrgSummary = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'uc/org/getOrgSummary'
    })

    if(result.code === 1) {
      const { roleTotalList } = result.data

      defaultList.forEach(item => {
        item.value = 0
      })

      setList([...defaultList])

      if(roleTotalList.length === 0) return

      list.forEach(record => {
        const roleItemKey = record.id
        const index = roleTotalList.findIndex(i => i.roleItemKey === roleItemKey)
        if (index !== -1) {
          record.value = roleTotalList[index].roleTotal
          roleTotalList.splice(index, 1)
        }
      })

      setList([...list])
    }
  }

  return (
    <div className={ styles.staff }>
      <h3 className={ styles.title }>已授权人员</h3>
      <ul className={ styles['staff-list'] }>
        {
          list.map((item, index) => (
            <li className="tc" key={ index }>
              <span style={{ color: item.color }}>{ item.value }</span>
              <p className="mt2">{ item.name }</p>
            </li>
          ))
        }
      </ul>
    </div>
  )
}