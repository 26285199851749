import React, { useState, useEffect, useRef } from 'react'
import { Spin, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'

import { CreateForm } from '../components/create-form'
import { Crumb } from '../../../components'

import styles from './AdCreate.module.css'

const defaultTabList = [
  {
    id: 'SELF',
    name: '影院线下自营',
  },
  {
    id: 'EXCLUSIVE_MEDIA',
    name: '代理商独家包销',
  },
]

export const defaultSubmitForm = {
  planAim: 'NORMAL',
  standardType: 'CPT',
  deliveryModel: 'SPLIT',
  operatorType: 'CINEMA',
  // 通投DELIVERY_HALL 跟片DELIVERY_FILM
  deliveryCinemaModel: 'DELIVERY_HALL',
  administrativeAreaId: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryContentDuration: 0,
  deliveryIndustry: '',
  timePeriods: [],
  playSequence: 99,
  customerId: null,
}

export const AdCreate: React.FC = () => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [tabList, setTabList] = useState(defaultTabList)
  const [curTabId, setCurTabId] = useState(defaultTabList[0].id)
  const [curSubTabId, setCurSubTabId] = useState('DELIVERY_HALL')

  const [submitForm, setSubmitForm] = useState<any>(Object.assign({}, defaultSubmitForm))

  const toggleTab = (id) => {
    if (curTabId === id) {
      return
    }
    setCurTabId(id)
    setSubmitForm((state) => {
      return {
        ...state,
        planAim: id,
      }
    })
  }

  return (
    
    <div className={ `${ styles['ad-page'] }` }>
      <div className={ `${ styles['ad-page-crumb'] }` }>投广告</div>

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>投广告</div>

        <div className={ `${ styles['ad-create'] }` }>
          {/* <div className={ `${ styles['tabs'] }` }>
            {
              deliveryCinemaModelItems.map((tab, index) => {
                return (
                  <div className={ `${ styles['tab-item'] } ${ curSubTabId === tab.itemKey ? styles['is-selected'] : '' }` } key={ tab.itemKey }>
                    { tab.itemValue }
                  </div>
                )
              })
            }
          </div> */}

          
          {/* 表单 */}
          <CreateForm form={ submitForm } />
        </div>
        
      </div>
    </div>
  )
}