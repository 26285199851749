import { 
  Home, 

  Sign, 
  SignIn, 
  Welcome,

  Choose,

  NotFound,
} from '../pages'

const routes = [
	{
    path: '/home',
    component: Home,
    name: 'home',
  },
  {
    path: '/sign',
    component: Sign,
    routes: [
      {
        path: '/sign/signIn',
        component: SignIn,
      },
    ],
  },
  {
    path: '/choose',
    component: Choose,
  },
  {
    path: '/welcome/:id/:status',
    component: Welcome,
  },
]

export default routes