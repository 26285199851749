import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import util from '../../../../libs/util'
import styles from './Bottom.module.css'

import { TechnicPriceModal } from '../../components/technic-price-modal'

interface Props {
	ad?: any,
  entity?: any,
}

export const Bottom: React.FC<Props> = ({ ad, entity }) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const goToDetailPage = () => {
    history.push(`/ad/detail/${ ad.mallOrderPlanId }`)
  }

  // 服务费
  const technicPriceModalRef: any = useRef()
  const onPreviewFee = () => {
    technicPriceModalRef.current.onToggle()
  }

  return (
    <div className={ `${ styles['ad-page-cont'] } ${ styles['ad-page-bottom'] }` }>
      <div className={ `${ styles['ad-page-tips'] }` }><span className={ `${ styles['ad-page-tips-label'] }` }>当前广告计划：</span><div className={ `${ styles['ad-tit-tips'] }` }>已选点位 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.pointCount || 0 }</span> 个，覆盖场所 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.entityCount || 0 }</span> 个，预估触达<span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.reachTime || 0 }</span>人</div></div>

      <div className={ `${ styles['ad-page-bottom-l'] }` }>
        <div className={ `${ styles['text-price'] }` }>
          <span className={ `${ styles['text-price-unit'] }` }>￥</span>
          <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(entity ? entity?.orderEntityState?.salePrice : ad.orderTotal?.originalBuyPrice).int }</span>
          <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(entity ? entity?.orderEntityState?.salePrice : ad.orderTotal?.originalBuyPrice).dec }</span>
        </div>
        <button className={ `${ styles['btn-comfirm'] } ${ styles['big-margin'] }` } onClick={ goToDetailPage }>完成，并回到广告计划</button>
      </div>

      { (ad?.mallOrderPlanId || ad?.mallOrderMediaId) && <TechnicPriceModal childRef={ technicPriceModalRef } mallOrderPlanId={ ad?.mallOrderPlanId } mallOrderMediaId={ ad?.mallOrderMediaId }  />
        }
    </div>
  )
}