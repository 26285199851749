import { 
  ContentList,
} from '../pages'

const routes = [
	{
    path: '/content/list',
    component: ContentList,
  },
]

export default routes