import * as actionTypes from '../actionTypes'
import { getUserInfo } from '../../api'

// 获取个人信息
export const getUserInfoAction = data => async dispatch => {
	const res = await getUserInfo(data)
  dispatch({
    type: actionTypes.UPDATE_USER,
    payload: res,
  })
}