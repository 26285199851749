import { createStore, combineReducers } from 'redux'

import userReducer from './user/reducer'

// const store = createStore(userReducer)

// TODO 后续把这里的清理掉，集中放入store文件夹目录下
const rootReducer = combineReducers({
  userReducer,
})
const store = createStore(rootReducer,
	window && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
	)

export default store