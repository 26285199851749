import React, { useState, useEffect } from 'react'
import styles from './UserInfo.module.css'

import { Nail, Icons } from '../../../components'
import { Button, Popover } from 'antd'

interface PropsType {
  authInfo: object | null,
  unAuthInfo: object | null,
  myUserInfo: any | null,
  userRoleList: any[] | undefined,
  isHomePage: Boolean,
  isFirstPage: Boolean,
  onLogout: Function,
}

export const UserInfoModal: React.FC<PropsType> = (props) => {
	const [authInfo, setAuthInfo] = useState<any>()
  const [myUserInfo, setMyUserInfo] = useState<any>()
  const [unAuthInfo, setUnAuthInfo] = useState<any>()
  const [userRoleList, setUserRoleList] = useState<any[]>()
  const [tel, setTel] = useState('')

  useEffect(() => {
    setAuthInfo(props.authInfo)
    setUnAuthInfo(props.unAuthInfo)
    setMyUserInfo(props.myUserInfo)

    let tel = props.myUserInfo?.phoneNumber || props.myUserInfo?.bindingNumber?.split('-')[1].toString() || ''
    tel = tel.substring(0,3) + ' **** ' + tel.slice(-4)
    setTel(tel)
    const authInfo:any = props.authInfo
    // 通过个人认证
    if (authInfo && authInfo.status === 'YES') {
      setUserRoleList(props.userRoleList)
    }
  }, [props])

  const onLogout = () => {
    props.onLogout()
  }

  const content = (
    <div className={ `${ styles['user-popup'] }`}>
      <div className={ `${ styles['user-name'] }` }>
        <span className={ `${ styles['china-name'] }` }>{ authInfo?.chinaName || '未认证用户' }</span>
        {
          userRoleList ?
            userRoleList.map((role) => {
              return (<span key={ role.roleItemKey } className={ `${ styles['role-label'] }` }><Nail roleItemKey={ role.roleItemKey } /></span>)
            })
            : ''
        }
      </div>
      <ul className={ `${ styles['info-list'] }` }>
        {/*<li className="mb3 flex">
          <span className={ styles.label }>昵称：</span>
          <span style={{ width: '170px', wordWrap: 'break-word' }}>{ myUserInfo?.nickName }</span>
        </li>*/}
        <li className="mb3 flex">
          <span className={ styles.label }>热幕号：</span>
          <span>{ myUserInfo?.userShowId }</span>
        </li>
        {
          (props.myUserInfo?.phoneNumber || props.myUserInfo?.bindingNumber) && 
          <li className="mb3 flex">
            <span className={ styles.label }>绑定手机号：</span>
            <span>{ tel }</span>
          </li>
        }
        {
          unAuthInfo?.chinaName && 
          <li className="mb3 flex">
            <span className={ `${ styles.label } fs` }>所属公司：</span>
            <p>{ unAuthInfo?.chinaName }</p>
          </li>
        }
      </ul>
      
      <div className={ `${ styles['btn-sign-out'] }` } onClick={ onLogout }>
        退出登录
      </div>
    </div>
  )

	return (
		<Popover 
      placement="bottom" 
      title={null} 
      content={content} 
      trigger="hover" 
      arrowPointAtCenter
      >
      <div className={ `${ styles['header-r'] } ${ props.isFirstPage ? styles['is-first-page'] : '' } ${ props.isHomePage ? styles['is-home'] : '' }` }>
        <div className={ styles.avatar }>
          {
            myUserInfo?.avatarUrl ? 
            <img width="34" src={ myUserInfo?.avatarUrl } alt="" />
            :
            <Icons icon="user" size="34" />
          }
        </div>

        <div className={ `${ styles['user-info'] }` }>
          <div className={ `${ styles['user-name'] }` }>
            <span className={ `${ styles['china-name'] }` }>{ authInfo?.chinaName || '未认证用户' }</span>
            {
              userRoleList ?
                userRoleList.map((role) => {
                  return (<span key={ role.roleItemKey } className={ `${ styles['role-label'] }` }><Nail roleItemKey={ role.roleItemKey } /></span>)
                })
                : ''
            }
          </div>
          {/*{
            unAuthInfo?.chinaName ? 
            <>
              <div className={ `${ styles.company }` }>
                <span className={ `${ styles.label }` }>所属公司：</span>
                <span className={ `${ styles.name }` }>{ unAuthInfo?.chinaName }</span>
              </div>
            </> : ''
          }*/}
        </div>
      </div>
    </Popover>
	)
}