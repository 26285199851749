import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Modal, Checkbox } from 'antd'

import styles from './RegionGroupItem.module.css'

interface Props {
  childRef?: any,
  item?: any,
  groupChange?: any,
}

export const RegionGroupItem: React.FC<Props> = ({ childRef, item, groupChange }) => {
  useImperativeHandle(childRef, () => ({
    reset() {
    }
  }))

  useEffect(() => {
    reset()
  }, [item])

  const reset = () => {
    setSelectedList([])
    setIndeterminate(false)
    setCheckAll(false)
  }

  const [selectedList, setSelectedList] = useState<any>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const onCheckAllChange = (e, v) => {
    const isChecked = e.target.checked
    const value = isChecked ? item.childList.map(i => i.itemKey) : []
    setIndeterminate(false)
    setCheckAll(isChecked)
    setSelectedList(value)
    groupChange({
      id: item.itemKey,
      isChecked,
      list: value,
    })
  }

  const onRegionCityChange = (list) => {
    setIndeterminate(!!list.length && list.length < item.childList.length)
    const isChecked = list.length === item.childList.length
    setCheckAll(isChecked)
    setSelectedList(list)
    groupChange({
      id: item.itemKey,
      isChecked,
      list,
    })
  }

	return (
    <div className={ `${ styles['mutli-item'] }` }>
      <div className={ `${ styles['mutli-item-perant'] }` }>
      <Checkbox 
        indeterminate={ indeterminate } 
        onChange={ (e) => {
          onCheckAllChange(e, item?.itemKey)
        } } 
        checked={ checkAll }
        >{ item?.itemValue }</Checkbox>
      </div>
      <div className={ `${ styles['list'] }` }>
        <Checkbox.Group 
          onChange={ onRegionCityChange }
          value={ selectedList } 
          style={{ display: 'flex', flexWrap: 'wrap', }}>
          {
            item.childList?.map((record) => {
              return (
                <span className={ `${ styles['item'] } ${ styles['big'] }` } key={ record.itemKey }><Checkbox value={ record.itemKey }>{ record?.itemValue }</Checkbox></span>
              )
            })
          }
        </Checkbox.Group>
      </div>
    </div>
  )
}