import React, { useState, useEffect } from 'react'
import styles from './SignLayout.module.css'

import { Header } from '../../components'
interface PropsType {
  isHomePage?: boolean,
}

export const SignLayout: React.FC<PropsType> = (props) => {
  const isHomePage = props.isHomePage
  const [height, setHeight] = useState(0)

  // useEffect(() => {
  //   const innerHeight = window.innerHeight
  //   setHeight(innerHeight - 100)
  // }, [height])


  return (
    <div className={ styles['main'] }>
      <Header isHomePage={ isHomePage } />
      <div 
        className={ `${ styles['sign-content'] } ${ isHomePage ? styles['is-home'] : '' }` }
        // style={{ height: `${ height }px`, paddingTop: '100px' }}
      >
        { props.children }
      </div>
    </div>
  )
}