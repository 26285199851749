import * as actionTypes from '../actionTypes'

export const defaultState = {
	myUserInfo: {
  	userType: '',
  },
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
    	return {
        ...action.payload,
      }

    default:
      return state
  }
  return state
}

export default reducer