import React from 'react'
import styles from './Sign.module.css'
import { renderRoutes } from 'react-router-config'
import { SignLayout } from '../../layouts'

export const Sign: React.FC = (props: any) => {
  return (
    <SignLayout>
      <div className={ styles.sign }>
      	{ renderRoutes(props.route.routes) }
        {/*{ props.children }*/}
      </div>
    </SignLayout>
  )
} 