import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../store'
import { getUserInfoAction } from '../../../store/user/action'
import styles from './Choose.module.css'

import util from '../../../libs/util'
import { Header, Footer } from '../../../components'
import { getOrgUsersByCode, codeLogin } from '../../../api'
import auth from '../signIn/auth'

interface Props {
  location: {
    state: {
      id?: String,
      phoneNumber?: String,
    }
  },
}

export const Choose: React.FC<Props> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [list, setList] = useState<any[]>([])
  const [phoneNumber, setPhoneNumber] = useState(props.location.state.phoneNumber)
  const [tempCode, setTempCode] = useState(props.location.state.id)
  console.log('props', props.location.state.id)

  const userInfo = useSelector((state: RootState) => {
    return state.user
  })
  useEffect(() => {
    console.log('choose SignIn', userInfo)
    // 已登录
    if (userInfo.myUserInfo && userInfo.myUserInfo.userType) {
      const myAuth: any = auth(userInfo)

      history.replace({ 
        pathname: myAuth.url, 
        state: myAuth 
      })
    }
  }, [userInfo])

  useEffect(() => {
    getList()
  }, [])

  // 获取列表
  const getList = async() => {
    const { code, data } = await getOrgUsersByCode({
      tempCode,
    })
    if (code === 1) {
      setList(data)
    }
  }

  const login = async(record) => {
    const { code, data } = await codeLogin({
      tempCode,
      userInId: record.userInId,
    }, {
      headers: {
        userToken: '',
      }
    })
    if (code === 1) {
      const userToken = data.userToken
      util.storage.set('userToken', userToken)
      // util.Cookies.set('userToken', userToken, { path: '/', domain: '.remudooh.com' })
      util.Cookies.set('userToken', userToken)
      dispatch(getUserInfoAction({}))
    }
  }
  return (
    <>
      <Header fixed={ false } />
      <div className={ styles.cont }>
        <div className={ styles['cont-tit'] }>{ phoneNumber } 手机号有<span className={ styles['cont-tit-number'] }>{ list.length }</span>个广告主账号，请选择登录账号</div>

        <div className={ styles.list }>
          {
            list.map((record, index) => {
              return (
                <div className={ styles.item } key={ record.userInId } onClick={ () => login(record) }>
                  <div className={ styles['company-name'] }>{ record.chinaName || '-' }</div>
                  <div className={ styles['company-id'] }>热幕号：{ record.userShowId }</div>
                  { record.roleStatus !== 'YES' && <div className={ styles.status }><span className={ `${ styles['icon-status'] } ifont i-tips`}></span>广告主入驻审核{ (record.roleStatus === 'NO' || record.status === 'NO') && '不通过' }{ record.roleStatus === 'WAIT' && '中' }</div> }

                  <div className={ `${ styles['icon-more'] } ifont i-trangle-b`}></div>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

