import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal } from 'antd'
import styles from './ScreenEffectModal.module.css'
import { CinemaScreenTypeItems, VideoInfo } from '../../../../libs/util.constants'
import picTemplate from '../../../../assets/images/ad/pic-template.jpg'

const ratioList = [
  {
    ratio: 2.39,
    tips: '视觉最佳',
    effectList: [
      {
        width: 202,
        height: 86,
        text: '全屏铺满',
      },
      {
        width: 202,
        height: 84,
        text: '上下黑边',
      },
    ],
  },
  {
    ratio: 2.35,
    tips: '',
    effectList: [
      {
        width: 194,
        height: 86,
        text: '左右微小黑边',
      },
      {
        width: 202,
        height: 78,
        text: '上下黑边',
      },
    ],
  },
  {
    ratio: 1.85,
    tips: '',
    effectList: [
      {
        width: 154,
        height: 86,
        text: '左右黑边',
      },
      {
        width: 202,
        height: 108,
        text: '全屏铺满',
      },
    ],
  },
  {
    ratio: 1.78,
    tips: '',
    subtips: '16:9',
    effectList: [
      {
        width: 134,
        height: 86,
        text: '左右黑边',
      },
      {
        width: 182,
        height: 108,
        text: '左右微小黑边',
      },
    ],
  },
]

interface Props {
  childRef: any,
}

export const ScreenEffectModal: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    onToggle() {
      showEffectModal()
    }
  }))
  const [isEffectModalVisible, setIsEffectModalVisible] = useState(false)

  const showEffectModal = () => {
    setIsEffectModalVisible(true)
  }

  const [isFold, setIsFold] = useState(false)
  const toggle = () => {
    setIsFold(!isFold)
  }

  {/*广告素材呈现效果（以4种常见视频比例示意）*/}
  return (
    <Modal 
      title="广告素材制作要求" 
      width={ 1104 }
      visible={ isEffectModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsEffectModalVisible(false) }}
      >
      <div className={ styles['effect-modal-tips'] }>
      1. 为让广告在各类画幅的银幕上呈现最佳效果，建议上传{ VideoInfo.bestRatio }:1（最低像素{ VideoInfo.bestWidth }*{ VideoInfo.bestHeight }）的视频。
      </div>

      <div className={ styles['effect-modal-cont'] } style={{ height: isFold ? '45px' : 'auto' }}>
        <div className={ styles['effect-modal-cont-tit'] }>
          <span>4种常见视频比例的银幕呈现效果</span>
          <span className={ styles['text-more'] } onClick={ toggle }>{ isFold ? '展开' : '收起' }
            <span className={ `${ styles['btn-toggle'] } ifont i-trangle-b ${ isFold ? null : styles['top'] }` }></span></span>
        </div>
        <div className={ styles['ratio-list'] }>
          {
            ratioList.map((item, ratioIndex) => {
              return (
                <div className={ styles['ratio-item'] } key={ ratioIndex }>
                  <div className={ styles['ratio-item-tit'] }>
                    <div className={ styles['ratio-label'] }>{ ratioIndex + 1 }</div>
                    <div className={ styles['ratio-text'] }>视频比例：<span className={ `${ item.tips ? styles['important'] : ''}` }>{ item.ratio }:1</span></div>
                    {
                      item.subtips && <div className={ styles['ratio-subtips'] }>({ item.subtips })</div>
                    }
                    {
                      item.tips && <div className={ styles['ratio-tips'] }>
                      <div className={ styles['icon-thumb-star'] }></div>
                      { item.tips }
                    </div>
                    }
                  </div>
                  <div className={ styles['effect-list'] }>
                    {
                      item.effectList.map((effect, index) => {
                        return (
                          <div className={ styles['effect-item'] } key={ index }>
                            <div className={ styles['effect-item-top'] }>
                              <div className={ styles['effect-item-label'] }>{ CinemaScreenTypeItems[index].itemValue }{ CinemaScreenTypeItems[index].tips }</div>
                              <img src={ picTemplate } className={ styles['effect-item-image'] } style={{ width: effect.width + 'px', height: effect.height + 'px' }} />
                            </div>
                            <div className={ styles['effect-item-text'] }>{ effect.text }</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className={ styles['effect-modal-tips'] }>2. 仅支持{
                VideoInfo.videoType.map((record, index) => {
                  return (<span key={ index }>{ record }{ index < VideoInfo.videoType.length - 1 && '、' }</span>)
                })}格式</div>
    </Modal>
  )
}