import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { Switch, Spin, Modal, Table, Pagination, Radio, message } from 'antd'

import { NoRecord } from '../../../../components'
import util from '../../../../libs/util'

import { ComposeModal } from '../../../content/list/composeModal/index'
import { AddVideoModal } from '../../../content/list/addVideoModal/index'
import { VideoPlayModal } from '../../../../components/video-play-modal'

import styles from './Material.module.css'

const RadioGroup = Radio.Group

interface Props {
  childRef: any,
  callBack?: Function,
}

interface AdQueryForm {
  leContentDuration: Number,
  industries: any,
}

const defaultAdQueryForm = {
  leContentDuration: 0,
  industries: [],
}

export const AddMaterialModal: React.FC<Props> = ({ childRef, callBack }) => {
	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
	const history = useHistory()

	const [order, setOrder] = useState()
	const [deliveryStart, setDeliveryStart] = useState()
	const [mallOrderPlanId, setMallOrderPlanId] = useState()
	const [usableDelivery, setUsableDelivery] = useState(false)
	useImperativeHandle(childRef, () => ({
    onToggle(record) {
      showModal()
      console.log('addVideoModal', record)
      setAdQueryForm((state) => {
	      return {
	        ...state,
	        leContentDuration: record.deliveryContentDuration * 1000 + 499,
	        industries: record.deliveryIndustry,
	      }
	    })
	    setDeliveryStart(record.deliveryStart)
	    setMallOrderPlanId(record.mallOrderPlanId)
      setOrder(record)
    }
  }))

	// 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const onSwitchChange = (value) => {
  	setUsableDelivery(value)
    setPageNum(1)
  	console.log('onSwitchChange', value)
  }

  // 上传视频
  const addVideoModalRef: any = useRef()
  const goToUpload = () => {
    addVideoModalRef.current.onToggle()
  }

  // 合成视频
  const composeModalRef: any = useRef()
  const goToCompose = () => {
    composeModalRef.current.onToggle()
  }

  const [adQueryForm, setAdQueryForm] = useState<AdQueryForm>(defaultAdQueryForm)

	const [list, setList] = useState<any>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(5)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if (!isModalVisible) {
      return
    }
    getContentList(pageNum)
  }, [pageNum, usableDelivery, isModalVisible])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 3秒查询一次
  const queryIntervalTime = 3000
  const [queryTimes, setQueryTimes] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      if (!queryTimes || queryTimes < 0) {
        clearInterval(timer)
        setIsSubmiting(false)
        return
      }
      getContentList(1)
     }, queryIntervalTime)

    return () => clearInterval(timer)
  }, [queryTimes])

  const [isSubmiting, setIsSubmiting] = useState(false)
	// 获取内容列表
  const getContentList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    const { data = [], total = 0, code } = await util.request({
      method: 'GET',
      url: `/resource/content/searchMineContent?pageNum=${pageNum}&pageSize=${pageSize}`,
      params: {
	      contentType: 'VIDEO',
	      usableDelivery,
	      ...( usableDelivery ? adQueryForm : null ),
	    },
    })
    if (code === 1) {
      // 如果有待检测的
      const hasPending = data.find(item => item.auditStatus === 'NONE' || item.auditStatus === 'PEND')
      if (!hasPending) {
        setQueryTimes(0)
      }
      
	    setList(data)
	    setIsSubmiting(false)
      setTotal(total)
	  }
  }

  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const [selectedContentAdId, setSelectedContentAdId] = useState('')
  const onRadioGroupChange = ({ target }) => {
  	setSelectedContentAdId(target.value)
  }

  const onConfirm = () => {
  	if (!selectedContentAdId) {
  		return
  	}
  	Modal.confirm({
      title: '是否确定提交？',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: addContentToAd,
    })
  }

  // 绑定内容到订单
  const addContentToAd = async() => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const url = deliveryStart ? '/mall/order/replaceOrderContent' : '/mall/order/updateOrderContent'
    let params:any = {
      contentAdId: selectedContentAdId,
      mallOrderPlanId,
    }

    if (deliveryStart) {
      params.deliveryStart = deliveryStart
    }

    const { data, message: msg, code } = await util.request({
      method: 'POST',
      url,
      data: params,
    })

    if (code === 1) {
    	message.success('操作成功')
    	// 关闭弹窗
    	onModalCancel()
    	// 重新加载页面
    	callBack && callBack()
	  } else {
	  	message.error(msg || '操作失败')
	  }
	  setIsSubmiting(false)
  }

  const videoPlayModalRef:any = useRef()
  const previewMedia = (url) => {
    videoPlayModalRef.current.onToggle(url)
  }

  const reloadContentList = () => {
    setQueryTimes(queryIntervalTime)
  }

	return (
		<Modal 
	    width={ 1104 }
	    title="提交素材"
	    footer={ null }
	    visible={ isModalVisible }
      onCancel={ onModalCancel }
	    >
	    <div className={ `${ styles['modal-top'] }` }>
	    	<div className={ `${ styles['modal-top-l'] }` }>
	    		<div className={ `${ styles['btn-opt'] }` } onClick={ goToUpload }><span className={ `${ styles['btn-icon'] } ifont i-upload` }></span>上传视频</div>
	    		<div className={ `${ styles['btn-opt'] }` } onClick={ goToCompose }><span className={ `${ styles['btn-icon'] } ifont i-image` }></span>图片合成视频</div>
          <div className={ `${ styles['btn-opt'] }` } onClick={ () => { getContentList(1) } }><span className={ `${ styles['btn-icon'] } ifont i-reload` }></span>重新加载列表</div>
	    	</div>
	    	<div className={ `${ styles['modal-top-r'] }` }>
	    		<span className={ `${ styles['modal-switch-label'] }` }>只看可投放</span><Switch defaultChecked={false} onChange={ onSwitchChange } />
	    	</div>
	    </div>

	    {/* 内容列表 */}
      <div className={ `${ styles['table-head'] }` }>
        <div className={ `${ styles['table-td'] }` }>广告素材</div>
        <div className={ `${ styles['table-td'] }` }>标签</div>
        <div className={ `${ styles['table-td'] }` }>是否可投放</div>
      </div>

      {
      	(list.length > 0) && (
      		<div className={ `${ styles['content-list'] }` }>
      			<RadioGroup 
              style={{ width: '100%' }}
              onChange={ onRadioGroupChange } 
              value={ selectedContentAdId }>
      			{
      				list.map(item => {
      					return (
                  <div className={ `${ styles['content-item-wrap'] }` } key={ item.contentAdId }>
                  <Radio 
                    style={{ display: 'flex', width: '100%', alignItems: 'center' }}
                    value={ item.contentAdId } 
                    disabled={ !util.tools.isContentCanSelect(item, adQueryForm) }>
      						<div className={ `${ styles['content-item'] }` }>
	      						<div className={ `${ styles['content-td'] }` } onClick={ () => { previewMedia(item.contentUrl) } }>
	      							
	      							<div className={ `${ styles['content-cont'] }` }>
		      							<div className={ `${ styles['content-cont-l'] }` }>
		      								{
		      									(item.auditStatus !== 'NONE' && item.auditStatus !== 'PEND' && item.auditStatus !== 'NO') && (
		      										<img className={ `${ styles['content-image'] }` } src={ `${ baseURL }${ item.thumbnailUrl && item.thumbnailUrl[0] ? item.thumbnailUrl[0] : item.contentUrl }?imageMogr2/thumbnail/200x84` } />
		      									)
		      								}
		      								
						              {/*<!-- 自动检测中 -->*/}
						              {
						              	(item.auditStatus === 'NONE' || item.auditStatus === 'PEND') && (
						              		<div className={ `${ styles['content-pending-tips'] }` }>自动检测中...</div>
						              	)
						              }
						              
						              {/*<!-- 内容不合规，视频已强制删除 -->*/}
						              {
						              	(item.auditStatus === 'NO') && (
						              		<div className={ `${ styles['content-del-tips'] }` }>
							              		<div>内容不合规</div>
							                	<div>视频已强制删除</div>
						              		</div>
						              	)
						              }

						              {
						              	(item.contentDuration && (item.auditStatus !== 'NONE' && item.auditStatus !== 'PEND')) && (
						              		<>
						              			<div className={ `${ styles['content-image-mask'] }` }></div>
						              			<div className={ `${ styles['content-duration-wrap'] }` }>
						              				<div className={ `${ styles['btn-play'] } ifont i-play` }></div>
						              				<div className={ `${ styles['content-duration'] }` }>{ util.tools.fixedTime(item.contentDuration / 1000) }</div>
						              			</div>
						              		</>
						              	)
						              }
		      							</div>

		      							<div className={ `${ styles['content-cont-r'] }` }>
		      								<div className={ `${ styles['content-cont-tit'] }` }>{ item.contentNote }</div>
		      								<div className={ `${ styles['content-number'] }` }>{ item.contentNumber }</div>
		      								<div className={ `${ styles['content-length'] }` }>{ util.tools.convertStorage(item.originalContentLength) }</div>
		      							</div>
	      							</div>
	      						</div>

                    {/* 标签 */}
	      						<div className={ `${ styles['content-td'] }` }>
	      							{
	      								(item.approveStatus === 'YES' && item.industryList) && (
	      									<div className={ `${ styles['content-labels'] }` }>
	      										{

	      											(item.industryList.length > 0) && (
	      												item.industryList.map((industryGroup, industryGroupIndex) => {
	      													{/*return (
	      														<div key={ industryGroupIndex }>{ industryGroup }</div>
	      													)*/}
	      													return (
                                    <span className={ `${ styles['industry-label'] }` } key={ industryGroupIndex }>
                                    { Object.values(industryGroup?.itemMap).join(',') }
                                    {/*{ Object.values(industryGroup?.itemMap)?.map((industry, industryIndex) => {
                                        return (
                                          <span key={ industryIndex } className={ `${ styles['industry-label'] }` }>{ industry }
                                          </span>
                                        )
                                      })
                                    }*/}
                                    </span>
                                  )
	      												})
	      											)
	      										}
						              </div>
	      								)
	      							}
	      						</div>

                    {/* 是否可投放 */}
	      						<div className={ `${ styles['content-td'] }` }>
                      {
                        (item.auditStatus !== 'NONE' && item.auditStatus !== 'PEND') && (
                          <>
                            <div className={ `${ styles['content-reasons-tit'] }` }>{ order && util.tools.isContentCanSelect(item, adQueryForm) ? '可投放' : '不可投放' }</div>
                            <div className={ `${ styles['content-reasons'] }` }>
                              {
                                (item.approveStatus === 'NO') && (<>{ item.approveOpinion }</>)
                              }
                              {
                                (item.auditStatus === 'NO') && (<>内容不合规</>)
                              }
                              {
                                (item.auditStatus === 'NONE') && (<>素材自动检测中</>)
                              }
                              {
                                (item.approveStatus === 'YES' && !item.industry.includes(adQueryForm.industries)) && (
                                  <>该素材所属行业与当前广告计划所属行业不符</>
                                )
                              }
                              {
                                (Math.round(item.contentDuration / 1000) > (Number(adQueryForm.leContentDuration) / 1000)) && (
                                  <>素材时长超出广告计划投放时长</>
                                )
                              }
                            </div>
                          </>
                        )
                      }
	      						</div>
	      					</div>
                  </Radio>
                  </div>
      					)
      				})
      			}
      			</RadioGroup>
      		</div>
      	)
      }

      {
        // 翻页
        (list.length > 0) && (
          <div className={ `${ styles.paging } flex justify-end` }>
            <Pagination 
              showQuickJumper 
              showSizeChanger
              showTotal={ total => `共 ${total} 条`}
              defaultCurrent={ defaultCurrent }
              current={ pageNum }
              pageSize={ pageSize }
              pageSizeOptions={ pageSizeOptions }
              total={ total } 
              onChange={ onPageChange }
              onShowSizeChange={ onShowSizeChange }
               />
          </div>
        )
      }

      {
      	list.length <= 0 && <NoRecord />
      }


      <div className={ `${ styles['modal-foot'] }` }>
      	<div className={ `${ styles['btn-confirm'] } ${ !selectedContentAdId ? styles['is-disabled'] : '' }` } onClick={ onConfirm }>确认提交</div>
      </div>

      <AddVideoModal 
        childRef={ addVideoModalRef }
        callBack={ () => { reloadContentList() } }
         />

      <ComposeModal childRef={ composeModalRef } callBack={ () => { reloadContentList() } } />

      {/* 视频预览 */}
      <VideoPlayModal childRef={ videoPlayModalRef } />

	  </Modal>
	)
}