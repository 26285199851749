import * as actionTypes from '../actionTypes'

const initialMenu = {
  // 菜单列表
  list: [],
  // 按钮权限
  elemList: [],
}

const reducer = (state = initialMenu, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU_LIST:
      return {
        ...state,
        list: action.payload,
      }

    case actionTypes.SET_ELEM_LIST:
      return {
        ...state,
        elemList: action.payload,
      }

    default:
      return state
  }
  return state
}

export default reducer