import util from './util'
import { getCosAuthInfo as getCosAuthInfoApi, getPreSignedUrl as getPreSignedUrlApi } from '../api'
const defaultBucket = `${ util.tools.env || 'pro' }-1259503787`

const getCosAuthInfo = async ({ file, type, callBack }) => {
  const now = new Date()
  // const fileName = `${util.tools.formatTime(now, '', '', '')}.${file.name}` 
  const fileName = `${ now.getTime() }.${ getUid() }.${ file.type.split('/')[1] }`
  const filePath = `${type}/${util.tools.formatDateYm(now)}/${fileName}`
  const fileSize = file.size

  const result = await getCosAuthInfoApi({
    fileLength: fileSize,
    filePath: filePath,
    httpMethod: 'PUT',
    bucketName: defaultBucket
  })

  if (result.code === 1) {
    return uploadFile({ cosAuthInfo: result.data, file, fileName, filePath, type, callBack })
  } else {
    throw new Error(`获取签名失败:${JSON.stringify(result)}`)
  }
}

const uploadFile = ({ cosAuthInfo, file, fileName, filePath, type, callBack }) => {
  const url = cosAuthInfo.url
  const authorization = cosAuthInfo.authorization
  const securityToken = cosAuthInfo.securityToken

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Authorization', authorization)
    securityToken && xhr.setRequestHeader('x-cos-security-token', securityToken)
    xhr.upload.onprogress = function (e) {
      callBack && callBack(Math.round(e.loaded / e.total * 10000) / 100)
    }
    xhr.onload = function () {
      if (/^2\d\d$/.test('' + xhr.status)) {
        console.log(url, `/${ filePath }`, xhr.getResponseHeader('etag'))
        resolve({
          wholeLocation: url,
          location: `/${ filePath }`,
        })
      } else {
        reject('文件 ' + filePath + ' 上传失败，状态码：' + xhr.status)
      }
    }
    xhr.onerror = function () {
      reject('文件 ' + filePath + ' 上传失败，请检查是否没配置 CORS 跨域规则')
    }
    xhr.send(file)
  })
}

export const getSecFileUrl = async (path, bucket) => {
  const result = await getPreSignedUrlApi({
    bucketName: bucket,
    filePath: path,
  })

  if (result.code === 1) {
    return {
      secUrl: result.data,
      path: `/${path}`,
    }
  } else {
    console.log('生成预签名的地址失败', JSON.stringify(result))
  }
}

export const uploadMedia = async ({ file, type, callBack }) => {
  try {
    return await getCosAuthInfo({ file, type, callBack })
  } catch(e) {
    throw new Error(e)
  }
}

const getUid = () => {
  let d = new Date().getTime()
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (d + Math.random()*16)%16 | 0
    d = Math.floor(d/16)
    return (c == 'x' ? r : (r&0x7|0x8)).toString(16)
  });
  return uuid
}