import { 
  AdList, 
  AdCreate,
  AdDetail,
  AdManageContent,
  AdSelectPoints,
  AdSelectHall,
  AdConfirm,
  AdCashier,
  AdReportHome,
  AdReportList
} from '../pages'

const routes = [
	{
    path: '/ad/list',
    component: AdList,
  },
  {
    path: '/ad/create',
    component: AdCreate,
  },
  {
    path: '/ad/detail/:mallOrderPlanId',
    component: AdDetail,
  },
  {
    path: '/ad/content/list/:mallOrderPlanId',
    component: AdManageContent,
  },
  {
    path: '/ad/select-points/:mallOrderPlanId',
    component: AdSelectPoints,
  },
  {
    path: '/ad/select-hall/:mallOrderPlanId/:filterData/:id/:deliveryContentDuration/:deliveryDays/:timePeriods/:playSequence/:showPlanStatus/:deliveryModel/:planAim',
    component: AdSelectHall,
  },
  {
    path: '/ad/confirm/:mallOrderPlanId',
    component: AdConfirm,
  },
  {
    path: '/ad/cashier/:mallOrderPlanId/:isFromConfirmPage',
    component: AdCashier,
  },
  {
    path: '/ad/report/home/:mallOrderPlanId',
    component: AdReportHome,
  },
  {
    path: '/ad/report/list/:mallOrderPlanId/:query',
    component: AdReportList,
  },
]

export default routes