import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message, Table } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { PlayCircleOutlined } from '@ant-design/icons'

import util from '../../../../libs/util'
import { AddMaterialModal } from '../../components/add-material'
import { Crumb } from '../../../../components'

import styles from './List.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
    },
  },
}

export const AdManageContent: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const { mallOrderPlanId } = props.match.params

  const onBack = () => {
    history.go(-1)
  }

  useEffect(() => {
    getAdDetail()
  }, [])

  // 详情
  const [ad, setAd] = useState<any>()
  const [isSubmiting, setIsSubmiting] = useState(false)

  const [minReplaceStartDate, setMinReplaceStartDate] = useState('')
  const [minDate, setMinDate] = useState(0)
  const [maxDate, setMaxDate] = useState(0)
  const [detail, setDetail] = useState<any>()
  const [list, setList] = useState<any>()
  const [auditList, setAuditList] = useState<any>()

  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1) {
      setAd(data)

      const now = new Date()
      if (!data?.ads) {
        return
      }
      const ads = data?.ads || []
      // 未废弃的并审核通过的
      const tempList = ads.filter(i => i.status !== 'OBSOLETE' && i.fullContentAd && i.fullContentAd.approveStatus === 'YES')
      let list:any = []
      for (let i = 0; i < tempList.length; i++) {
        const nowNumber = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
        let item:any = tempList[i]
        const isStartGtNow = new Date(item.deliveryStart.replace(/-/g, '/')).getTime() > nowNumber
        const isEndLtNow = new Date(item.deliveryEnd.replace(/-/g, '/')).getTime() < nowNumber
        item.contentStatus = isStartGtNow ? '待使用' : (isEndLtNow ? '已使用' : '使用中')
        list.push(item)
      }
      const auditList = ads.reverse()

      const keys = ['ads', 'mallOrderPlanId', 'deliveryContentDuration', 'deliveryIndustry', 'deliveryStart', 'deliveryEnd', 'mallOrderPlanId']
      let detail = {}
      keys.forEach(key => {
        detail[key] = data[key]
      })

      // 替换内容时的最小起始日期，若为null表示已经不可能再替换
      setMinReplaceStartDate(data.minReplaceStartDate)
      setMinDate(new Date(data.minReplaceStartDate).getTime())
      setMaxDate(new Date(data.deliveryEnd).getTime())
      setDetail(detail)
      setList(list)
      setAuditList(auditList)

      setIsSubmiting(false)
    }
  }

  const columns = [
    {
      title: '广告素材',
      dataIndex: 'content',
      key: 'content',
      width: 300,
      render(text, record, index) {
        return (
          <div className={ styles['video-wrap'] }>
            <img className={ styles['video-image'] } src={ `${ baseURL}${ record.fullContentAd.thumbnailUrl[0] }?imageMogr2/thumbnail/x84` } />
            <div className={ styles['video-mask'] }><PlayCircleOutlined className={ styles['video-btn-icon'] } /><div className={ styles['video-duration'] }>{ util.tools.fixedTime(record.fullContentAd.contentDuration / 1000) }</div></div>
          </div>
        )
      },
    },
    {
      title: '广告名称',
      dataIndex: 'contentNote',
      key: 'contentNote',
      width: 400,
      render(text, record, index) {
        return <span className={ `${ styles['bold'] }` }>{ record.fullContentAd.contentNote }</span>
      },
    },
    {
      title: '投放期间',
      dataIndex: 'deliveryStart',
      key: 'deliveryStart',
      width: 300,
      render(text, record, index) {
        return (
          <>
            { util.tools.formatDateStr(record.deliveryStart) }-{ util.tools.formatDateStr(record.deliveryEnd) }
          </>
        )
      },
    },
    {
      title: '使用状态',
      dataIndex: 'contentStatus',
      key: 'contentStatus',
      // width: 180,
      render(text, record, index) {
        return (
          <div className={ `${ styles['table-td-inner'] }`}>
            <span className={ `${ styles['icon-status'] } ${ text === '已使用' ? styles['gray'] : (text === '使用中' ? styles['suc'] : styles['warn']) }` }></span>{ text }
          </div>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render(text, record, index) {
        // 待使用 || 最后一条使用中的记录
        return (
          <>
            {
              (record.contentStatus === '待使用' || record.contentStatus === '使用中' && index === list.length - 1) && <div className={ styles['btn-opt'] } onClick={ () => onMaterial(record) }>
              更换素材
            </div>
            }
          </>
        )
      },
    },
  ]

  const auditColumns = [
    {
      title: '广告素材',
      dataIndex: 'content',
      key: 'content',
      width: 300,
      render(text, record, index) {
        return (
          <div className={ styles['video-wrap'] }>
            <img className={ styles['video-image'] } src={ `${ baseURL}${ record.fullContentAd.thumbnailUrl[0] }?imageMogr2/thumbnail/x84` } />
            <div className={ styles['video-mask'] }><PlayCircleOutlined className={ styles['video-btn-icon'] } /><div className={ styles['video-duration'] }>{ util.tools.fixedTime(record.fullContentAd.contentDuration / 1000) }</div></div>
          </div>
        )
      },
    },
    {
      title: '广告名称',
      dataIndex: 'contentNote',
      key: 'contentNote',
      width: 280,
      render(text, record, index) {
        return <span className={ `${ styles['bold'] }` }>{ record.fullContentAd.contentNote }</span>
      },
    },
    {
      title: '投放期间',
      dataIndex: 'deliveryStart',
      key: 'deliveryStart',
      render(text, record, index) {
        return (
          <>
            { util.tools.formatDateStr(record.deliveryStart) }-{ util.tools.formatDateStr(record.deliveryEnd) }
          </>
        )
      },
    },
    {
      title: '提交时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
    },
    {
      title: '审核状态',
      dataIndex: 'approveStatus',
      key: 'approveStatus',
      width: 180,
      render(text, record, index) {
        return (
          <div className={ `${ styles['table-td-inner'] }`}>
            <span className={ `${ styles['icon-status'] } ${ (record.fullContentAd?.approveStatus === 'NO' || !record.fullContentAd?.industry?.includes(ad.deliveryIndustry) ? styles['error'] : record.fullContentAd?.approveStatus === 'YES' ? styles['suc'] : styles['gray']) }` }></span>
            {
              (record.fullContentAd?.approveStatus === 'WAIT') && '审核中'
            }

            {
              (record.fullContentAd?.approveStatus !== 'WAIT' && (record.fullContentAd?.approveStatus === 'NO' || !record.fullContentAd?.industry?.includes(ad.deliveryIndustry))) && '不符合投放要求'
            }

            {
              (record.fullContentAd?.approveStatus === 'YES' && record.fullContentAd?.industry?.includes(ad.deliveryIndustry)) && '通过'
            }
          </div>
        )
      },
    },
    {
      title: '原因',
      dataIndex: 'operation',
      key: 'operation',
      width: 250,
      render(text, record, index) {
        return (
          <span className={ `${ styles['text-error'] } `}>
            {
              (record.fullContentAd.approveStatus !== 'WAIT' && (record.fullContentAd.approveStatus === 'NO' || !record.fullContentAd?.industry?.includes(ad.deliveryIndustry))) && (
                <>
                  {
                    (record.fullContentAd?.approveStatus === 'NO') && <>{ record.fullContentAd?.approveOpinion }<br/></>
                  }
                  {
                    (record.fullContentAd?.approveStatus !== 'NO' && record.fullContentAd?.approveStatus !== 'WAIT' && !record.fullContentAd?.industry?.includes(ad.deliveryIndustry)) && '素材的行业与广告计划投放的行业不符'
                  }
                </>
              )
            }
          </span>
        )
      },
    },
  ]

  const addMaterialModalRef: any = useRef()
  const [isInUse, setIsInUse] = useState(false)
  const [curItem, setCurItem] = useState()
  const [deliveryStart, setDeliveryStart] = useState('')
  const onMaterial = (item) => {
    const isExpire = !minReplaceStartDate
    // 素材列表中,有素材正在审核中
    const isAuditing = ad.ads.find(i => i.fullContentAd.approveStatus === 'WAIT' || i.fullContentAd.approveStatus === 'NONE')
    // 素材更换日期已截止
    if (isExpire) {
      Modal.confirm({
        title: '素材更换时间已截至，无法更换素材',
        okText: '我知道了',
      })
    } else if (isAuditing) {
    // 正在审核中
      Modal.confirm({
        title: `您提交的变更素材正在审核中，\n请勿重复提交`,
        okText: '我知道了',
      })
    } else if (item.contentStatus === '使用中') {
      setIsInUse(true)
      setCurItem(item)
      // 弹窗选择时间 TODO isDatePopupShow
      // 弹窗选了时间后,再调 onDatePopupConfirm方法
      // 默认是最小可替换时间
      setDeliveryStart(minReplaceStartDate)
      setMinDate(new Date(minReplaceStartDate?.replace(/-/g, '/')).getTime())
    } else if (item.contentStatus === '待使用') {
    // 待使用
      setIsInUse(true)
      setCurItem(item)
      setDeliveryStart(item.deliveryStart)

      Modal.confirm({
        title: '更换的新素材需通过平台审核，审核通过后，原本待使用的素材将被替换',
        content: '是否确定更换？',
        onOk: () => {
          onDatePopupConfirm({
            isInUse: true,
            curItem: item,
            deliveryStart: item.deliveryStart,
          })
        },
      })
    } else {
      setIsInUse(false)
      setCurItem(item)
      setDeliveryStart(item.deliveryStart)

      Modal.confirm({
        title: '更换的新素材需通过平台审核，审核通过后，原本待使用的素材将被替换',
        content: '是否确定更换？',
        onOk: () => {
          onDatePopupConfirm({
            isInUse: false,
            curItem: item,
            deliveryStart: item.deliveryStart,
          })
        },
      })
    }
  }

  const onDatePopupConfirm = async({ isInUse, curItem, deliveryStart }) => {
    // 正在使用的,要去到内容列表页
    if (isInUse) {
      const params = Object.assign({}, curItem, {
        deliveryContentDuration: ad.deliveryContentDuration,
        deliveryIndustry: ad.deliveryIndustry,
      })
      addMaterialModalRef.current.onToggle(params)
    } else {
    // 待使用的,直接修改时间
      setIsSubmiting(true)
      const { data, message: msg, code } = await util.request({
        method: 'POST',
        url: `/mall/order/changeOrderContentStartDate`,
        data: {
          id: curItem.id,
          deliveryStart,
        },
      })
      if (code === 1) {
        message.success('修改成功')
        getAdDetail()
      } else {
        message.error(msg || '修改失败')
      }
    }
  }

  const crumbList = [
    {
      name: '我的广告计划',
      path: '/ad/list',
    },
    {
      name: '广告计划详情',
      path: `/ad/detail/${ mallOrderPlanId }`,
    },
    {
      name: '素材使用管理',
      path: '',
    }
  ]

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbList } />

      {/*页头*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } ifont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>素材使用管理</div>
        </div>

        <div className={ `${ styles['ad-page-cont-tit'] }` }>刊播使用的素材</div>
        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
            投放周期：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            { util.tools.formatDateStr(ad?.deliveryStart) }-{ util.tools.formatDateStr(ad?.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad?.deliveryDays }天)</span>
          </div>
        </div>

        {/*列表*/}
        <Table 
          className={ styles['content-table'] } 
          dataSource={ list } 
          columns={ columns } 
          rowKey="id"
          pagination={ false } />
        
      </div>

      {/*素材提交申请记录*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-cont-tit'] }` }>素材提交申请记录</div>
        {/*列表*/}
        <Table 
          className={ styles['content-table'] } 
          dataSource={ auditList } 
          columns={ auditColumns } 
          rowKey="id"
          pagination={ false } />
      </div>
      
      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } callBack={ getAdDetail } />
    </div>
  )
}