import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Pagination, Spin, Modal, message, Checkbox } from 'antd'
import util from '../../../../libs/util'
import { RegionGroupItem } from './region-group-item'

import styles from './SelectCitysModal.module.css'

interface Props {
  childRef?: any,
  selectedCities?: any,
  onSelectCityFinished?: any,
}

interface Filter {
  cityGrades?: any,
  districtIds?: any,
}
const defaultFilter:Filter = {
  cityGrades: [],
  districtIds: [],
}

export const SelectCitysModal: React.FC<Props> = ({ childRef, selectedCities, onSelectCityFinished }) => {
  useImperativeHandle(childRef, () => ({
    showModal() {
      // 重置一些数据
      
      // 省份
      setIsRegionFold(true)
      setFilter((state) => {
        return {
          ...state,
          districtIds: [],
        }
      })

      // 城市线级 cityGrades
      setFilter((state) => {
        return {
          ...state,
          cityGrades: [],
        }
      })
      setIndeterminate(false)
      setCheckAll(false)

      showModal()
    }
  }))

	// 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = async() => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    isModalVisible && init()
  }, [isModalVisible])
  const init = async() => {
    getRecommendCities()
    const result = await getEnumItemList()
    await getEnumItemList(result?.params, result?.list)
    await getCityList(filter)
  }
  const [defaultValueSelectedHotCities, setDefaultValueSelectedHotCities] = useState([])

  const [filter, setFilter] = useState<Filter>(defaultFilter)

  const getRecommendCities = async() => {
    const key = 'HOT_CITY'
    const { data, code } = await util.request({
      method: 'POST',
      url: `/resource/findResourceEnumItemList`,
      data: {
        groupKeyArray: [key],
      },
    })

    if (code === 1) {
      const hotCityList = data[key]
      setHotCityList(hotCityList)
      // 推荐城市里的选中
      const selectedCitiesId = selectedCities.map(i => i.districtId)
      let list = hotCityList.filter(i => selectedCitiesId.includes(i.itemKey)).map(i => JSON.stringify(i))
      setDefaultValueSelectedHotCities(list)
    }
  }
  const getEnumItemList = async(params?, list?) => {
    const regionName = 'REGION_LIST'
    const cityLevelName = 'CITY_LEVEL'
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: (params || [regionName, cityLevelName]).join(','),
      },
    })

    if (code === 1) {
      if (!params) {
        setRegionList(data[regionName])
        setCityLevelList(data[cityLevelName])
        return {
          params: data[regionName].map(i => `${ regionName }=${ i.itemKey }`),
          list: data[regionName]
        }
      } else {
        const regionName = 'REGION_LIST'
        let tempRegionList = [...list]
        tempRegionList.forEach((region, index) => {
          region.childList = data[`${ regionName }=${ region.itemKey }`]
        })
        setRegionList(tempRegionList)
        console.log('tempRegionList', tempRegionList)
      }
    }
  }

  const [hotCityList, setHotCityList] = useState<any>([])
  const [isHotCityFold, setIsHotCityFold] = useState(true)
  const [selectedCityList, setSelectedCityList] = useState<any>(JSON.parse(JSON.stringify(selectedCities)))
  const toggleHotCity = () => {
    setIsHotCityFold(!isHotCityFold)
  }
  const onHotCityChange = (val) => {
    // 是个选中的数组
    let result:any = [...selectedCityList]

    // 剔除掉在热门城市里除val外的城市
    const hotCityIds = hotCityList.map(i => i.itemKey)
    const selectedIds = val.map(item => JSON.parse(item).itemKey)
    const expIds = hotCityIds.filter(i=> !selectedIds.includes(i))
    expIds.forEach(item => {
      // 已选的列表里有当前选中的城市
      const index = result.findIndex(i => i.districtId === item)
      if (index !== -1) {
        result.splice(index, 1)
      }
    })

    val.forEach(itemStr => {
      const item = JSON.parse(itemStr)
      // 如果结果列表中没有当前选中这些城市
      if(!result.find(i => i.districtId === item.itemKey)) {
        result.push({
          districtId: item.itemKey,
          districtName: item.itemValue,
        })
      }
    })
    setSelectedCityList(result)
    console.log('onHotCityChange', result, val)
    // 热门城市的选中
    setDefaultValueSelectedHotCities(val)
  }

  const [regionList, setRegionList] = useState<any>([])
  const [isRegionFold, setIsRegionFold] = useState(true)
  const toggleRegion = () => {
    setIsRegionFold(!isRegionFold)
  }

  const onRegionChange = ({ isChecked, id, list }) => {
    let districtIds = [...filter.districtIds]
    // list 是当前选中的结果值
    // 如果不是全选 要剔除 当项里除list外的其他项
    if (!isChecked) {
      const delIds = regionList.find(i => i.itemKey === id).childList.filter(i => !list.includes(i.itemKey)).map(i => i.itemKey)
      districtIds = districtIds.filter(i => !delIds.includes(i))
    }
    // 再加当前选中的项进去 
    districtIds.push(...list)
    districtIds = Array.from(new Set(districtIds))
    
    setFilter((state) => {
      return {
        ...state,
        districtIds,
      }
    })
    getCityList(Object.assign({}, filter, {
      districtIds,
    }))
  }

  const [cityLevelList, setCityLevelList] = useState<any>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const onCheckAllChange = (e) => {
    const isChecked = e.target.checked
    setFilter((state) => {
      return {
        ...state,
        cityGrades: isChecked ? cityLevelList.map(i => i.itemKey) : [],
      }
    })
    setIndeterminate(false)
    setCheckAll(isChecked)
    getCityList(Object.assign({}, filter, {
      cityGrades: isChecked ? cityLevelList.map(i => i.itemKey) : [],
    }))
  }
  const onCityLevelChange = (list) => {
    setIndeterminate(!!list.length && list.length < cityLevelList.length)
    setCheckAll(list.length === cityLevelList.length)
  	setFilter((state) => {
      return {
        ...state,
        cityGrades: list,
      }
    })
    getCityList(Object.assign({}, filter, {
      cityGrades: list,
    }))
  }

  const [cityList, setCityList] = useState<any>([])
  const getCityList = async(filter) => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/district/searchDistrictList`,
      params: {
        districtType: 'CITY',
        districtIds: filter.districtIds.join(','),
        cityGrades: filter.cityGrades.join(','),
      },
    })

    if (code === 1) {
      let cityList:any = []
      let curInitial = ''

      data.map(item => {
        const letter = item.pinYin.substring(0, 1).toLocaleUpperCase()
        if (selectedCityList.find(i => i.districtId === item.districtId)) {
          item.selected = true
        }
        if (curInitial !== letter) {
          curInitial = letter
          cityList.push({
            initial: curInitial,
            groupDistrict: [item]
          })
        } else {
          cityList[cityList.length - 1].groupDistrict.push(item)
        }
      })

      setCityList(cityList)
    }
  }

  const toggleCity = (city) => {
    let list = [...selectedCityList]
    const index = list.findIndex(i => i.districtId === city.districtId)
    // 选中
    if (index === -1) {
      list.push(city)
    } else {
    // 去掉
      list.splice(index, 1)
    }
    setSelectedCityList(list)
    // 去掉上头的热门城市里的选中
    let temp:any = [...defaultValueSelectedHotCities].filter(item => list.map(i => i.districtId).includes(JSON.parse(item).itemKey))
    // 如果没选则要加上
    if (index === -1 && !defaultValueSelectedHotCities.find(item => JSON.parse(item).itemKey === city.districtId)) {
      temp.push(JSON.stringify({
        itemKey: city.districtId,
        itemValue: city.districtName,
        groupKey: 'HOT_CITY',
      }))
    }
    setDefaultValueSelectedHotCities(temp)
  }

  const clearCity = (districtId) => {
    let list = [...selectedCityList]
    const index = list.findIndex(i => i.districtId === districtId)
    list.splice(index, 1)
    setSelectedCityList(list)
    // 去掉上头的热门城市里的选中
    let temp = [...defaultValueSelectedHotCities].filter(i => JSON.parse(i).itemKey !== districtId)
    setDefaultValueSelectedHotCities(temp)
  }

  // 清空
  const clearAllCitys = () => {
    setSelectedCityList([])
    setDefaultValueSelectedHotCities([])
  }

  const hasSelectedThisLetter = (ids) => {
    return selectedCityList.filter(i => ids.includes(i.districtId)).length > 0
  }

  const onConfirm = () => {
    onSelectCityFinished(selectedCityList)
    onModalCancel()
  }
  
  return (
  	<Modal 
      width={ 1104 }
      title="选择投放区域"
      maskClosable={ false }
      footer={ null }
      visible={ isModalVisible }
      onCancel={ onModalCancel }
      >
      {/* form */}
      <div className={ `${ styles['modal-cont'] }` }>
        {/* 推荐城市 */}
        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>推荐城市：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['list'] } ${ isHotCityFold ? styles['small'] : '' }` }>
	  				<Checkbox.Group 
              style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} 
              value={ defaultValueSelectedHotCities }
              onChange={ onHotCityChange }>
	  					{
	  						hotCityList?.map((item) => {
	  							return (
	  								<span className={ `${ styles['item'] }` } key={ item.itemKey }><Checkbox value={ JSON.stringify(item) }>{ item?.itemValue }</Checkbox></span>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
	  				<div className={ `${ styles['btn-more'] }` } onClick={ toggleHotCity }>{ isHotCityFold ? '更多' : '收起' }<div className={ `${ styles['icon-other'] } ifont i-trangle-b ${ isHotCityFold ? styles['bot'] : '' }` }></div></div>
  				</div>
  			</div>

        {/* 省份 */}
        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>省份：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['region-list'] } ${ isRegionFold ? styles['small'] : '' }` }>
            {
              regionList?.map((item, index) => {
                return (
                  <RegionGroupItem item={ item } key={ index } groupChange={ onRegionChange } />
                )
              })
            }
	  				<div className={ `${ styles['btn-more'] }` } onClick={ toggleRegion }>{ isRegionFold ? '更多' : '收起' }<div className={ `${ styles['icon-other'] } ifont i-trangle-b ${ isRegionFold ? styles['bot'] : '' }` }></div></div>
  				</div>
  			</div>
        
        {/* 城市线级 */}
        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>城市线级：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['list'] }` }>
            <span className={ `${ styles['item'] } ${ styles['big'] }` }><Checkbox indeterminate={ indeterminate } onChange={ onCheckAllChange } checked={ checkAll }>全部</Checkbox></span>
	  				<Checkbox.Group 
              style={{ display: 'flex', flexWrap: 'wrap', }} 
              value={ filter.cityGrades }
              onChange={ onCityLevelChange }>
	  					{
	  						cityLevelList?.map((item) => {
	  							return (
	  								<span className={ `${ styles['item'] } ${ styles['big'] }` } key={ item.itemKey }><Checkbox value={ item.itemKey }>{ item?.itemValue }</Checkbox></span>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
  				</div>
  			</div>
      </div>

      {/* 城市列表 */}
      <div className={ `${ styles['city-cont'] }` }>
        <div className={ `${ styles['city-wrap'] }` }>
          {
            cityList?.map((item, index) => {
              return (
                <div className={ `${ styles['city-wrap-item'] } ${ selectedCityList.find(i => i.pinYin?.substring(0, 1).toLocaleUpperCase() === item.initial || hasSelectedThisLetter(item.groupDistrict.map(i => i.districtId))) ? styles['is-selected'] : '' }` } key={ index }>
                  {/* A B C */}
                  <div className={ `${ styles['letter-label'] }` }>{ item?.initial }</div>

                  <div className={ `${ styles['city-list'] }` }>
                    {
                      item.groupDistrict.map((city) => {
                        return (<span className={ `${ styles['city-item'] } ${ selectedCityList.find(i => i.districtId === city.districtId) ? styles['is-selected'] : '' }` } key={ city.districtId } onClick={ () => { toggleCity(city) } }>{ city.districtName } </span>)
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>


      {/* footer */}
      <div className={ `${ styles['modal-footer'] }` }>
        <div className={ `${ styles['modal-footer-l'] }` }>
          <div className={ `${ styles['modal-footer-l-inner'] }` }>
            <div className={ `${ styles['modal-l-label'] }` }>已选：</div>
            <div className={ `${ styles['modal-l-label'] }` }>(共<span className={ `${ styles['modal-l-count'] }` }>{ selectedCityList.length }</span>个)</div>
            <div className={ `${ styles['btn-clear'] }` } onClick={ clearAllCitys }>清空</div>
          </div>
          <div className={ `${ styles['modal-footer-city-list'] }` }>
            {
              selectedCityList.map((city) => {
                return (<div className={ `${ styles['city-item'] } ${ styles['is-selected'] }` } key={ city.districtId } onClick={ () => { clearCity(city.districtId) }}>{ city.districtName }<span className={ `${ styles['btn-del'] }` }><span className={ `ifont i-close ${ styles['icon-del'] }` }></span></span></div>)
              })
            }
          </div>
        </div>
        <div className={ `${ styles['modal-footer-r'] }` }>
          <button className={ `${ styles['btn-confirm'] }` } onClick={ onConfirm }>完成</button>
        </div>
      </div>
    </Modal>  
  )
}