const storage = {}

/**
 * @description 存储 localStorage 值
 * @param {string} key 
 * @param {any} value 
 */ 
storage.set = function(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * @description 拿到 localStorage 值
 * @param {String} key localStorage key
 */ 
storage.get = function(key) {
  return JSON.parse(localStorage.getItem(key))
}

/**
 * @description 删除 localStorage 值
 * @param {String} key localStorage key
 */ 
storage.delete = function(key) {
 	localStorage.removeItem(key)
}

storage.clear = function() {
 	localStorage.clear()
}

export default storage