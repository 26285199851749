import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Modal, Progress } from 'antd'
import styles from './MemberShip.module.css'

import util from '../../../../../../libs/util'
import { DetailModal } from './detail-modal'

interface Props {
  childRef: any,
}
const nextLevelScore = 10000
const levelList = [
  {
    level: 1,
    score: 9999,
    rightsList: [
      {
        amount: 10,
        discount: 9.9,
      },
      {
        amount: 30,
        discount: 9.8,
      },
      {
        amount: 50,
        discount: 9.7,
      },
      {
        amount: 70,
        discount: 9.6,
      },
      {
        amount: 90,
        discount: 9.5,
      },
    ],
  },
  {
    level: 2,
    score: 10000,
    rightsList: [
      {
        amount: 0,
        discount: 9.5,
      },
      {
        amount: 100,
        discount: 9.3,
      },
      {
        amount: 400,
        discount: 9,
      },
      {
        amount: 1000,
        discount: 8.7,
      },
      {
        amount: 2000,
        discount: 8.5,
      },
    ],
  },
]

export const MemberShip: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    showModal() {
      showModal()
    }
  }))

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const history = useHistory()
  const goToOrder = () => {
    history.push('/ad/create')
  }

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    getCurrentOrgGrade()
    getCurrentOrgPoints()
  }

  const today = new Date()
  const validPeriod = util.tools.formatDate(new Date(+new Date(today.getFullYear(), today.getMonth() + 1, 1) - 1 * 24 * 60 * 60 * 1000), '.')
  const [level, setLevel] = useState(1)
  const [curLevelIndex, setCurLevelIndex] = useState(0)
  const [curLevelNumber, setCurLevelNumber] = useState(1)
  // 获取本企业的等级
  const getCurrentOrgGrade = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: '/uc/org/getCurrentOrgGrade',
    })

    if (code === 1) {
      const { orgGrade } = data
      setLevel(orgGrade)
      setCurLevelIndex(levelList.findIndex(i => i.level === orgGrade) || 0)
      setCurLevelNumber(orgGrade)
    }
  }
  const [score, setScore] = useState(0)
  const [processPercent, setProcessPercent] = useState(0)
  // 获取本企业的当前总积分
  const getCurrentOrgPoints = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: '/uc/org/getCurrentOrgPoints',
    })

    if (code === 1) {
      setScore(data)
      setProcessPercent((data / nextLevelScore) * 100)
    }
  }

  const [isFold, setIsFold] = useState(true)
  const toggle = () => {
    setIsFold(!isFold)
  }

  // 积分明细弹窗
  const detailModalRef: any = useRef()
  const showDetailModal = () => {
    detailModalRef.current.showModal()
  }

  return (
    <Modal 
      width={ 1104 }
      title="会员权益"
      footer={ null }
      visible={ isModalVisible }
      onCancel={ onModalCancel }
      >
      <div className={ `${ styles['level-list'] }` }>
      {
          levelList.map((item, index) => {
            return (
              <div className={ `${ styles['level-item'] }` } key={ index }>
                <div className={ `${ styles['level-top'] }` }>
                  <div className={ `icon ${ styles['level-icon'] }` }></div>
                  <div className={ `${ styles['level-top-r'] }` }>
                    <div className={ `${ styles['level-top-tit'] }` }>
                      <span className={ `${ styles['level-top-label'] }` }>R{ item.level }</span>
                      {
                        (curLevelNumber === item.level) && ('当前级别')
                      }
                      {
                        (curLevelNumber > item.level) && ('已达成')
                      }
                      {
                        (curLevelNumber < item.level) && ('未达成')
                      }
                    </div>
                    {
                      (curLevelIndex === index) && <div className={ `${ styles['level-valid-period'] }` }>{ validPeriod }到期</div>
                    }
                    
                    <div className={ `${ styles['level-top-progress-wrap'] }` }>
                      {
                        (curLevelIndex === index && curLevelNumber === item.level) && (
                          // 进度条
                          <div className={ `${ styles['level-top-progress'] }` }>
                            <Progress percent={ processPercent } strokeColor="#FE8135" trailColor="#FFCEA0" showInfo={false} />
                          </div>
                        )
                      }
                      {/* 当前级别 */}
                      {
                        (curLevelNumber === item.level) && <span onClick={ showDetailModal  }><span className={ `${ styles['score-number'] }` }>{ score }</span>积分<span className={ `ifont i-trangle-b ${ styles['btn-right'] }` }></span></span>
                      }
                    </div>
                    <div>
                      {/* 您已超越该等级 */}
                      {
                        (curLevelNumber > item.level) && ('您已超越该等级')
                      }
                      {/* 已达最高级别，去下单 */}
                      {
                        (curLevelIndex === index && index === levelList.length - 1) && (<div onClick={ goToOrder }>已达最高级别，去下单<span className={ `ifont i-trangle-b ${ styles['btn-right'] }` }></span></div>)
                      }
                      {/* 还需xxx积分解锁R2铂金会员，去下单 */}
                      {
                        (curLevelIndex === index && curLevelIndex < levelList.length - 1) && (<div onClick={ goToOrder }>还需{ levelList[index + 1].score - score }积分解锁R{ curLevelNumber + 1 }铂金会员，去下单<span className={ `ifont i-trangle-b ${ styles['btn-right'] }` }></span></div>)
                      }
                      {/* 未解锁，解锁还需xxx积分 */}
                      {
                        (curLevelIndex < index) && (<div>未解锁，解锁还需{ item.score - score }积分</div>)
                      }
                    </div>
                  </div>
                </div>

                <div className={ `${ styles['right-list'] }` }>
                  <div className={ `${ styles['right-list-tit'] }` }>尊享{ item.rightsList.length }项特权</div>
                  {
                    item.rightsList.map((record, recordIndex) => {
                      return (
                        <div className={ `${ styles['right-item'] }` } key={ recordIndex }>
                          <span className={ `${ styles['right-item-index'] }` }>{ recordIndex + 1 }.</span>
                          单笔订单
                          <span className={ `${ styles['right-item-label'] }` }>满{ record.amount }{ record.amount > 0 ? '万' : '' }元</span>
                          <span className={ `${ styles['right-item-label'] }` }>享{ record.discount }折</span>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>

      <div className={ styles['rules-cont'] } style={{ height: isFold ? '64px' : 'auto' }}>
        <div className={ styles['rules-cont-tit'] }>
          <span>了解会员等级体系规则</span>
          <span className={ styles['text-more'] } onClick={ toggle }>{ isFold ? '展开' : '收起' }
            <span className={ `${ styles['btn-toggle'] } ifont i-trangle-b ${ isFold ? null : styles['top'] }` }></span></span>
        </div>

        <div className={ styles['rules-item'] }>
          <div className={ styles['rules-item-tit'] }>等级体系介绍</div>
          <div className={ styles['rules-item-text'] }>共2个等级。</div>
          <div className={ styles['rules-item-text'] }><span className={ `icon ${ styles['icon-level'] }  ${ styles['icon-level-1'] }` }></span>R1高级会员：广告主入驻即为R1会员</div>
          <div className={ styles['rules-item-text'] }><span className={ `icon ${ styles['icon-level'] }  ${ styles['icon-level-2'] }` }></span>R2铂金会员：需累计1万积分，即自动升级为R2会员</div>
        </div>

        <div className={ styles['rules-item'] }>
          <div className={ styles['rules-item-tit'] }>等级体系更新规则</div>
          <div className={ styles['rules-item-text'] }>会员等级每月最后一日22:00更新，由用户前12个自然月（包含本月）累计获取的积分决定。</div>
          <div className={ styles['rules-item-text'] }>每月中，用户最近12个自然月（包含本月）累计有效积分达到更高等级门槛的，即可实时升级。</div>
          <div className={ styles['rules-item-text'] }>每个等级可享受不同级别的会员权益，等级越高，权益越丰富。</div>
        </div>

        <div className={ styles['rules-item'] }>
          <div className={ styles['rules-item-tit'] }>积分统计、到账规则</div>
          <div className={ styles['rules-item-subtit'] }>统计规则：</div>
          <div className={ styles['rules-item-text'] }>积分是用户最近12个自然月（包含本月）的热幕广告投放订单的实际成交总金额换算得到的动态分值。</div>
          <div className={ styles['rules-item-text'] }>每笔广告投放订单的实际成交金额每满100元兑换1积分，不足100元的金额不计入积分。</div>
          <div className={ styles['rules-item-text'] }>广告投放订单的实际成交金额=下单支付金额-影院谢绝投放退款的金额</div>
          <div className={ styles['rules-item-subtit'] }>到账规则：</div>
          <div className={ styles['rules-item-text'] }>积分将在广告投放订单支付后的24小时内到账。可在 [我的 - 会员权益] 页面查看。</div>
        </div>

        <div className={ styles['rules-item'] }>
          <div className={ styles['rules-item-tit'] }>如何查看会员权益</div>
          <div className={ styles['rules-item-text'] }>可在会员页面通过左右滑动的方式查看各个等级的会员权益，其中未达到的等级显示为未解锁状态，会员等级越高，权益越丰富。</div>
        </div>
      </div>

      <DetailModal childRef={ detailModalRef } />
    </Modal>
  )
}