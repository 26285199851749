import React from 'react'
import styles from './Nail.module.css'

interface Props {
  roleItemKey: string
}

export const roleList = [
  {
    id: 'AD_ROOT',
    name: '广告主',
    class: '',
    res: [],
  },
  {
    id: 'AD_MANAGER',
    name: '管理员',
    class: 'oper',
    res: [],
  },
  {
    id: 'AD_OPERATION',
    name: '营销人员',
    class: 'oper',
    res: [],
  },
  {
    id: 'AD_DELIVERY',
    name: '投放人员',
    class: 'deli',
    res: [],
  },

  {
    id: 'MEDIA_ROOT',
    name: '点位主',
    class: '',
    res: [],
  },
  {
    id: 'MEDIA_MANAGER',
    name: '广告营销',
    class: 'manager',
    res: ['点位管理', '广告运营', '上刊', '巡刊'],
  },
  {
    id: 'MEDIA_PUBLISH',
    name: '上刊人员',
    class: 'publish',
    res: ['点位激活', '上刊'],
  },
  {
    id: 'MEDIA_CHECK',
    name: '广告执行',
    class: 'check',
    res: ['上刊回执上传'],
  },
  {
    id: 'MEDIA_OPERATION',
    name: '运营人员',
    class: 'oper',
    res: ['媒体资源管理', '广告运营'],
  },
  {
    id: 'MEDIA_DELIVERY',
    name: '自用点位管理员',
    class: 'deli',
    res: ['自用点位的广告投放'],
  },
]

const roles = new Map([
  ['AD_ROOT', '广告主'], 
  ['AD_OPERATION', '营销人员'], 
  ['AD_DELIVERY', '投放人员'], 
  ['MEDIA_ROOT', '点位主'], 
  ['MEDIA_OPERATION', '运营人员'],
  ['MEDIA_PUBLISH', '上刊人员'],
  ['MEDIA_CHECK', '广告执行'],
  ['MEDIA_MANAGER', '广告营销'],
  ['MEDIA_DELIVERY', '自用点位管理员'],
])

export const Nail: React.FC<Props> = (props) => {
  const role = roleList.find(i => i.id === props.roleItemKey)

  return (
    role ? 
      <span className={ `${ styles.nail }` }>
        { props.roleItemKey ? role.name : '' }
      </span> : null
  )
}