import React, { useState, useEffect, useRef } from 'react'
import { Spin, Modal, message, Checkbox } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'
import { NoRecord, Crumb } from '../../../components'

import { SimpleBaseInfo } from '../components/simple-base-info'
import { EntityList } from '../components/entity-list'
import { OrderInfo } from '../components/order-info'
import { OrderBottom } from '../components/order-bottom'
import { Ad, defaultAd } from '../index'

import styles from './Confirm.module.css'

const { confirm } = Modal
const CheckboxGroup = Checkbox.Group

interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
    },
  },
}

export const AdConfirm: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const crumbList = [
    {
      name: '我的广告计划',
      path: '/ad/list',
    },
    {
      name: '广告计划详情',
      path: '',
    }
  ]

  const onBack = () => {
    history.goBack()
  }

  const { mallOrderPlanId } = props.match.params

  useEffect(() => {
    getAdDetail()
    // getEnumItems()
  }, [])

  // 详情
  const [ad, setAd] = useState<Ad>(defaultAd)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1) {
      setAd(data)
      setIsEditMode(data.showPlanStatus === 'DRAFT' || data.showPlanStatus === 'SAVE')
      await getOrderTotal(data)

      setIsSubmiting(false)
    }
  }

  const getOrderTotal = async (list) => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      setAd(state => {
        return {
          ...state,
          orderTotal: data.find(i => i.mallOrderPlanId === mallOrderPlanId) || null,
        }
      })
    }
  }

  // 计划名称
  const onUpDateDeliveryName = (name) => {
    setAd(state => {
      return {
        ...state,
        deliveryName: name,
      }
    })
  }

  return (
    <>
    {
      !isSubmiting && (
        <div className={ `${ styles['ad-page'] }` }>
          <Crumb list={ crumbList } />

          {/*广告计划名称*/}
          <div className={ `${ styles['ad-page-cont'] }` }>
            <div className={ `${ styles['ad-page-tit'] }` }>
              <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
                <div className={ `${ styles['icon-right'] } ifont i-trangle-b` }></div>
                <div className={ `${ styles['btn-back-text'] }` }>返回</div>
              </div>
              <div className={ `${ styles['ad-page-tit-text'] }` }>确认广告计划</div>
            </div>

            <SimpleBaseInfo ad={ ad } onUpDateDeliveryName={ onUpDateDeliveryName } isConfirmPage />
          </div>

          {/*投放点位*/}
          {
            ad?.showPlanStatus && (<EntityList ad={ ad } isConfirmPage />)
          }

          {/*广告计划信息*/}
          { (ad?.showPlanStatus !== 'DRAFT' && ad?.showPlanStatus !== 'SAVE') && <OrderInfo ad={ ad } /> }

          {/*底部操作*/}
          { ad?.showPlanStatus && <OrderBottom ad={ ad } isEditMode={ isEditMode } isConfirmPage /> }

        </div>
      )
    }
    
    
    </>
  )
}