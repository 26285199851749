import React, { useState, useContext, useEffect, useRef } from 'react'
import { Modal, Button, Pagination } from 'antd'
import { getMineContent, getStorageSpace, deleteContentAd, createContentApprove } from '../../../../api'
import util from '../../../../libs/util'
import styles from './List.module.css'

import { Table, message, Progress, Select, Input } from 'antd'
import { ExclamationCircleOutlined, ExclamationCircleFilled, UploadOutlined, PictureOutlined, PlayCircleOutlined } from '@ant-design/icons'

import { ComposeModal } from '../composeModal/index'
import { AddVideoModal } from '../addVideoModal/index'

import { filterContentContext, setFilterContentContext } from '../ContentList'

const { Option } = Select
const { Search } = Input

// 状态
const approveStatusItems = [
  {
    itemKey: '',
    itemValue: '全部状态',
  },
  {
    itemKey: 'NONE',
    itemValue: '待提交审核',
  },
  {
    itemKey: 'WAIT',
    itemValue: '审核中',
  },
  {
    itemKey: 'YES',
    itemValue: '审核通过',
  },
  {
    itemKey: 'NO',
    itemValue: '审核不通过',
  },
]

const defaultFilter = {
  contentType: 'VIDEO',
  // ['NONE', 'PEND', 'YES'].join(',')
  // auditStatuses: '',
  contentNote: '',
  // approveStatuses: '',
}

const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

export const ContentInnerList: React.FC = () => {
  // console.log('mediainfo', )
  const [modal, contextHolder] = Modal.useModal()
  const [auditResultTips, setAuditResultTips] = useState('')
  
  // const filter = useContext(filterContentContext)
  // const setFilter = useContext(setFilterContentContext)
  const [filter, setFilter] = useState<any>({ ...defaultFilter })
  useEffect(() => {
    getList(1)
  }, [filter.approveStatuses])

  const [currentVideoSrc, setCurrentVideoSrc] = useState('')
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
  useEffect(() => {
    // 关闭弹窗时，视频停止播放
    if (!isVideoModalVisible) {
      const video:any = document.getElementById('video')
      video?.pause()
    }
  }, [isVideoModalVisible])

  const setVideo = (record) => {
    const contentUrl = record.contentUrl
    if (contentUrl) {
    // 有视频
      setCurrentVideoSrc(contentUrl)
      toggleVideoModal()
    }
  }

  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true)
  // useEffect(() => {
  //   getList(pageNum)
  // }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 状态选择
  const handleChange = (value) => {
    setFilter(state => {
      return {
        ...state,
        approveStatuses: value,
        auditStatuses: value ? ['NONE', 'PEND', 'YES'].join(',') : '',
      }
    })
  }
  const onSearch = (value) => {
    getList(1)
  }
  const onInputChange = (e) => {
    setFilter(state => {
      return {
        ...state,
        contentNote: e.target.value,
      }
    })
  }
  const onPressEnter = (e) => {
    getList(1)
  }

  // 获取列表
  const getList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    const pNum = num || pageNum
    const result = await getMineContent({
      pageNum: pNum,
      pageSize,
      ...filter,
    })
    const data = result.data || []
    const total = result.total || 0
    setList(data)
    setTotal(total)
    setIsFirstTimeLoad(false)
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
    getList(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  // 获取存储空间
  const [spaceTotal, setSpaceTotal] = useState(0)
  const [usedContentLength, setUsedContentLength] = useState(0)
  const getStorageSpaceFn = async () => {
    const result = await getStorageSpace()
    const data = result.data
    setSpaceTotal(data.spaceTotal)
    setUsedContentLength(data.usedContentLength)
  }
  useEffect(() => {
    getStorageSpaceFn()
  }, [])

  // 视频播放弹窗
  const toggleVideoModal = (show?) => {
    setIsVideoModalVisible(show === undefined ? !isVideoModalVisible : show)
  }
  const showNoVideoTips = () => {
    Modal.warning({
      title: '提示',
      content: '视频转码中，请稍后查看...',
      okType: 'primary',
      okButtonProps: {
        danger: true,
      },
    });
  }

  // 删除内容
  const deleteContent = (contentAdId) => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除该素材吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await deleteContentAd({
          contentAdId,
        })

        if (result.code === 1) {
          message.success('操作成功！')
          init()
        } else {
          message.error(result.message || '操作失败，请重试！')
        }
      }
    })
  }
  // 提交审核
  const approveContent = async (record) => {
    const contentAdId = record.contentAdId
    const auditStatus = record.auditStatus
    if (auditStatus === 'NONE' || auditStatus === 'PEND') {
      Modal.info({
        title: '提示',
        content: '正在自动检测中，检测通过后再进行审核。',
        okText: '确定',
      })
      return
    }
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '是否确认提交审核？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await createContentApprove({
          contentAdId,
        })

        if (result.code === 1) {
          message.success('操作成功！')
          init()
        } else {
          message.error(result.message || '操作失败，请重试！')
        }
      }
    })
  }

  const init = () => {
    // 刷新列表
    getList(1)
    // 获取存储空间
    getStorageSpaceFn()
  }

  const addVideoModalRef: any = useRef()
  const onAddVideo = () => {
    addVideoModalRef.current.onToggle()
  }

  const composeModalRef: any = useRef()
  const onSelectCompose = () => {
    composeModalRef.current.onToggle()
  }

  const columns = [
    {
      title: '广告素材',
      dataIndex: 'content',
      key: 'content',
      width: 200,
      render(text, record, index) {
        return (
          <div className={ styles['video-wrap'] }>
            {/*自动检测中*/}
            {
              (record.auditStatus === 'NONE' || record.auditStatus === 'PEND') && <div onClick={ showNoVideoTips } className={ styles['video-result-tips'] }>自动检测中...</div>
            }
            {/*内容不合规，视频已强制删除*/}
            {
              record.auditStatus === 'NO' && <div className={ styles['video-result-tips'] }>内容不合规<br/>视频已强制删除</div>
            }
            {/*不可用于投放*/}
            {
              record.approveStatus === 'NO' && <div className={ styles['video-result-tips'] }>不可用于投放</div>
            }

            {/*有视频*/}
            {
              record.auditStatus === 'YES' && 
              (
                <div onClick={ () => setVideo(record) } className={ styles['cursor-pointer'] }>
                  <img className={ styles['video-image'] } src={ baseURL + (record.thumbnailUrl && record.thumbnailUrl.length ? record.thumbnailUrl[0] : record.contentUrl) } />
                  { record.contentUrl ? <div className={ styles['video-mask'] }><PlayCircleOutlined className={ styles['video-btn-icon'] } /><div className={ styles['video-duration'] }>{ util.tools.fixedTime(record.contentDuration / 1000) }</div></div> : '' }
                </div>
              )
            }
          </div>
        )
      },
    },
    {
      title: 'ID',
      dataIndex: 'contentNumber',
      key: 'contentNumber',
      width: 150,
    },
    {
      title: '广告名称',
      dataIndex: 'contentNote',
      key: 'contentNote',
    },
    {
      title: '标签',
      dataIndex: 'industryList',
      key: 'industryList',
      render(text, record, index) {
        if (record.auditStatus !== 'NO' && record.approveStatus === 'YES') {
          return (
            record.industryList.map((group, index) => {
              return (<div className={ styles['industry-label'] } key={ index }>
                { 
                  Object.values(group.itemMap)
                }
              </div>)
            })
          )
        } else {
          return ''
        }
      },
    },
    {
      title: '文件大小',
      dataIndex: 'contentLength',
      key: 'contentLength',
      render(text, record, index) {
        return util.tools.convertStorage(record.contentLength)
      },
      width: 100,
    },
    {
      title: '提交者',
      dataIndex: 'operatorUserName',
      key: 'operatorUserName',
    },
    {
      title: '提交时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      render(text, record, index) {
        return (
          <div className={ styles['btn-opt'] } onClick={ () => deleteContent(record.contentAdId) }>
            删除
          </div>
        )
      },
    },
  ]

  return(
    <div className={ styles['content-cont'] }>
      {/*过滤条件*/}
      <div className={ styles['filter-panel'] }>
        <div className={ styles['filter-type'] }>
          {/*<Select className={ styles['filter-select'] } value={ filter.approveStatuses } bordered={ false } onChange={ handleChange }>
            {
              approveStatusItems.map((status) => {
                return (
                  <Option key={ status.itemKey } value={ status.itemKey }>{ status.itemValue }</Option>
                )
              })
            }
          </Select>*/}
          <div className={ styles['search-opts'] }>
            <Input placeholder="请输入关键词" style={{ width: '230px' }} allowClear bordered={ false } onChange={ onInputChange } onPressEnter={ onPressEnter } />
            <div className={ `${ styles['imediafont'] } ifont i-search`} onClick={ onSearch }></div>
          </div>
        </div>
        <div className={ styles['content-storage-wrap'] }>
          <div className={ styles['content-storage'] }>
            <div className={ styles['content-storage-text'] }>
              <div><span className={ styles['content-storage-used'] }>已用{ util.tools.convertStorage(usedContentLength) }</span>/{ util.tools.convertStorage(spaceTotal) }</div>
              <Progress className={ styles['content-storage-progress'] } percent={ usedContentLength / spaceTotal } showInfo={ false } size="small" />
            </div>
            { (spaceTotal - usedContentLength) < 100 * 1024 * 1024 ? <div className={ styles['content-storage-tips'] }>仅剩余{ util.tools.convertStorage(spaceTotal - usedContentLength) }可用空间，请及时清理</div> : null }
          </div>
          <Button type="primary" className={ styles['btn-confirm'] } onClick={ onAddVideo }><UploadOutlined />上传视频</Button>
          <Button type="primary" className={ styles['btn-confirm'] } onClick={ onSelectCompose }><PictureOutlined />图片合成视频</Button>
        </div>
      </div>

      {/*列表*/}
      <Table 
        className={ styles['content-table'] } 
        dataSource={ list } 
        columns={ columns } 
        rowKey="contentNumber"
        pagination={ false } />
      
      {
        // 翻页
        (list.length > 0) && (
          <div className={ `${ styles.paging } flex justify-end` }>
            <Pagination 
              showQuickJumper 
              showSizeChanger
              defaultCurrent={ defaultCurrent }
              current={ pageNum }
              pageSize={ pageSize }
              pageSizeOptions={ pageSizeOptions }
              total={ total } 
              onChange={ onPageChange }
              onShowSizeChange={ onShowSizeChange }
               />
          </div>
        )
      }

      {/*<div className={ `${ styles['content-table-td'] }` }>
        {
          record.auditStatus !== 'NO' && record.approveStatus === 'NONE' && <div className={ `${ styles['text-warning'] } `}>待提交审核</div>
        }

        {
          record.auditStatus !== 'NO' && record.approveStatus === 'WAIT' && <div className={ `${ styles['text-warning'] } `}>审核中</div>
        }

        {
          record.auditStatus !== 'NO' && record.approveStatus === 'NO' && <><div className={ `${ styles['text-failed'] } `}>审核不通过</div><div className={ styles['approve-opinion'] }>{ record.approveOpinion.join('，') }</div></>
        }

        {
          record.auditStatus !== 'NO' && record.approveStatus === 'YES' && <>审核通过</>
        }

        {
          record.auditStatus === 'NO' && <>-</>
        }
      </div>*/}
      {/*{
        (record.auditStatus !== 'NO' && record.approveStatus === 'NONE') && 
        <div className={ styles['btn-opt'] } onClick={ () => approveContent(record) }>
          <span className={ `${styles['imediafont']} imediafont i-auth` }></span>提交审核
        </div>
      }*/}

      <AddVideoModal 
        childRef={ addVideoModalRef }  
        callBack={ ()=> { getList(1) } } />

      <ComposeModal childRef={ composeModalRef } callBack={ ()=> { getList(1) } } />

      {/*有视频播放*/}
      <Modal 
        title="" 
        width={ 760 }
        bodyStyle={{
          padding: '40px 30px',
        }}
        visible={ isVideoModalVisible }
        onOk={ () => setIsVideoModalVisible(true) }
        onCancel={ () => setIsVideoModalVisible(false) }
        centered
        footer={ false }>
          <div className={ styles['video-modal-content'] }>
            <video id="video" className={ styles['video-modal'] } controls src={ baseURL + currentVideoSrc } />
          </div>
      </Modal>
    </div>
  )
}