import React from 'react'
import styles from './Footer.module.css'

export const Footer = () => {
  return (
    <footer className={ `${ styles['footer'] }`}>
    	<div className={ styles['footer-inner'] }>
      ©2023 聚投科技(珠海)有限公司版权所有 <span className={ styles['space'] }>|</span> <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19146099号</a> 
      </div>
    </footer>
  )
}