import React, { useState, useEffect, useImperativeHandle } from 'react'
import styles from './UserModal.module.css'
import { getConfigList, searchChildrenDistrict, createOutsideUser } from '../../../api'

import { Modal, Button, Form, Input, Select, Radio, Row, Col, message } from 'antd'
import type { FormInstance } from 'antd/es/form'
import util from '../../../libs/util'

interface Props {
  childRef: any,
  isCinema?: Boolean,
  typeText?: String,
}

export const UserModal: React.FC<Props> = ({ childRef, isCinema, typeText }) => {
  const [show, setShow] = useState(false)

  const [form] = Form.useForm()

  const [industryList, setIndustryList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])

  const [isChecked, setIsChecked] = useState(false)
  const [isCityDisabled, setIsCityDisabled] = useState(true)

  useEffect(() => {
    form.setFieldsValue({
      applyNameLast: '先生',
    })
    getIndustryList()
    getProvinceList()
  }, [])

  useImperativeHandle(childRef, () => ({
    onToggle() {
      onToggle()
    }
  }))
  
  // const onToggle = (isShow) => {
  //   setShow(isShow === undefined ? !show : isShow)
  // }
  const onToggle = () => {
    setShow(!show)
  }

  // 获取省市
  const getProvinceList = async () => {
    const { data = [] } = await searchChildrenDistrict({
      districtType: '',
      districtId: '',
    })
    let list = [] as any
    data.forEach(i => {
      list.push({
        label: i.districtName,
        value: i.districtId,
      })
    })
    setProvinceList(list)
  }
  const onProvinceChange = async (value) => {
    const { data = [] } = await searchChildrenDistrict({
      districtType: 'PROVINCE',
      districtId: value,
    })
    let list = [] as any
    data.forEach(i => {
      list.push({
        label: i.districtName,
        value: i.districtId,
      })
    })
    setCityList(list)
    form.setFieldsValue({
      applyCity: '',
    })
    setIsCityDisabled(false)
  }

  // 获取行业列表
  const getIndustryList = async () => {
    const name = 'INDUSTRY'
    // const { data } = await util.request({
    //   method: 'GET',
    //   url: '/config/enum/findEnumItemList',
    //   params: {
    //     groupKeyArray: [name].join(','),
    //     excludeItems: ['AGENT', 'PUBLIC-AD-TYPE'].join(','),
    //   },
    // })
    const { data } = await getConfigList({
      groupKeyArray: [name].join(','),
      excludeItems: ['AGENT', 'PUBLIC-AD-TYPE'].join(','),
    })
    let list = [] as any
    data[name].forEach(i => {
      list.push({
        label: i.itemValue,
        value: i.itemKey,
      })
    })
    setIndustryList(list)
  }

  const formRef = React.createRef<FormInstance>()

  const onFinish = async (values: any) => {
    console.log('onFinish', values)
    const { data, code, message: mg } = await createOutsideUser({
      ...values,
      applyProductChannelId: 'AD_WEB',
    })

    if (code === 1) {
      onToggle()
      message.success('提交成功')
    } else {
      message.error(mg || '提交失败，请重试！')
    }
  }

  const resetData = () => {
    form.setFieldsValue({
      applyCompanyName: '',
      applyNameFirst: '',
      applyNameLast: '先生',
      applyProvince: '',
      applyCity: '',
      applyIndustry: '',
      applyContacts: '',
    })
    setIsCityDisabled(true)
  }

  const onCheck = () => {
    const formValue = form.getFieldsValue(true)
    setIsChecked(formValue.applyCompanyName && formValue.applyNameLast && formValue.applyContacts)
  }

  const selectDropdownStyle = {
  }
  return (
    <Modal 
      width={ 600 }
      visible={ show }
      centered={ true }
      footer={ null }
      bodyStyle={{ padding: 0 }}
      style={{ borderRadius: 16 }}
      closable={ true }
      wrapClassName="modal-wrap"
      onCancel={ onToggle }
      afterClose={ resetData }
      destroyOnClose={ true }
      className={ styles.modal }
    >
      <div className={ styles.content }>
        <div className={ `${ styles.header }` }>
          <div className={ `${ styles.title }` }>留下您的联系方式</div>
          <div className={ styles.subtitle }>我们会尽快与您联系</div>
        </div>

        <div className={ styles.form }>
          <Form 
            className={ styles.form }
            form={form}
            ref={ formRef } 
            name="user-form" 
            onFinish={ onFinish }>
            <Form.Item name="applyCompanyName" label="" rules={[{ required: true, message: '请输入企业名称', }]}>
              <Input className={ styles.ipt } placeholder="企业名称（必填）" onChange={ onCheck } maxLength={ 32 } />
            </Form.Item>
            <Row>
              <Col span={16}>
                <Form.Item name="applyNameFirst" rules={[{ required: true, message: '请输入您的称呼', }]} >
                  <Input className={ `${ styles.ipt } ${ styles.short }` } placeholder="您的称呼（必填）" maxLength={ 10 } onChange={ onCheck } />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="applyNameLast" rules={[{ required: true, message: '请选择性别', }]} style={{ marginLeft: '24px', marginTop: '10px', }}>
                  <Radio.Group className={ styles.radio }>
                    <Radio value="先生">先生</Radio>
                    <Radio value="女士">女士</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item name="applyProvince" label="">
                  <Select 
                    bordered={ false }
                    placeholder="省" 
                    className={ `${ styles.select } ${ styles.short }` } 
                    size="large"
                    dropdownStyle={ selectDropdownStyle } 
                    options={ provinceList }
                    onChange={ onProvinceChange}
                    >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="applyCity" label="">
                  <Select 
                    bordered={ false }
                    placeholder="市" 
                    className={ `${ styles.select } ${ styles.short }` } 
                    size="large"
                    disabled={ isCityDisabled }
                    dropdownStyle={ selectDropdownStyle } 
                    options={ cityList }
                    >
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="applyIndustry" label="">
              <Select 
                bordered={ false }
                placeholder="请选择行业" 
                className={ `${ styles.select } `}
                dropdownStyle={ selectDropdownStyle } 
                size="large"
                options={ industryList }
                >
              </Select>
            </Form.Item>
            <Form.Item name="applyContacts" label="" rules={[{ required: true, message: '请输入手机号', }]}>
              <Input className={ styles.ipt } placeholder="手机号（必填）" maxLength={ 11 } onChange={ onCheck } />
            </Form.Item>
            <Form.Item>
              <Button 
                className={ styles['confirm-btn'] } 
                block 
                htmlType="submit"
                disabled={ !isChecked }
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  )
}