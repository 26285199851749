import React, { useState, useImperativeHandle, useRef } from 'react'
import styles from './VideoPlayModal.module.css'

import { Modal } from 'antd'
import util from '../../libs/util'

interface Props {
  childRef: any,
}

const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

export const VideoPlayModal: React.FC<Props> = ({ childRef }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoSrc, setVideoSrc] = useState()

  useImperativeHandle(childRef, () => ({
    onToggle(videoSrc) {
      onToggle(videoSrc)
    }
  }))
  
  const onToggle = (videoSrc) => {
    setVideoSrc(videoSrc)
    setIsModalVisible(!isModalVisible)
  }

  const videoRef = useRef<any>(null)
  const onModalCancel = () => {
    setIsModalVisible(false)
    videoRef?.current.pause()
  }

  return (
    <Modal 
      title="视频预览" 
      width={ 800 }
      bodyStyle={{
        padding: '20px 20px',
      }}
      visible={ isModalVisible }
      onCancel={ onModalCancel }
      centered
      footer={ false }>
        <div className={ styles['video-modal-content'] }>
          <video 
            id="video" 
            ref= { videoRef }
            className={ styles['video-modal'] } 
            controls 
            src={ baseURL + videoSrc } />
        </div>
    </Modal>
  )
}