import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Modal, Select, Table } from 'antd'
import styles from './DetailModal.module.css'

import util from '../../../../../../../libs/util'

interface Props {
  childRef: any,
}

export const DetailModal: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    showModal() {
      showModal()
    }
  }))

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    getCurrentOrgPoints()
    getCurrentUcOrgPointsFlows()
  }

  const [score, setScore] = useState(0)
  // 获取本企业的当前总积分
  const getCurrentOrgPoints = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: '/uc/org/getCurrentOrgPoints',
    })

    if (code === 1) {
      setScore(data)
    }
  }

  const [allList, setAllList] = useState<any>([])
  const [list, setList] = useState<any>([])
  const [curDate, setCurDate] = useState(util.tools.formatDateYm(new Date()))
  const [curDateTotalScore, setCurDateTotalScore] = useState(0)
  // 获取积分明细列表
  const getCurrentUcOrgPointsFlows = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: '/uc/org/getCurrentUcOrgPointsFlows',
    })

    if (code === 1) {
      setAllList(data)
      const item = data.find(i => i.period === curDate)
      setList(item.flows)
      setCurDateTotalScore(item.balance)
      const dList = data.map(i => i.period)
      let tempDateList:any = []
      dList.forEach(i => {
        tempDateList.push({
          value: i,
          text: i,
        })
      })
      setDateList(tempDateList)
    }
  }

  const [dateList, setDateList] = useState([])
  const onDateChange = (value) => {
    setCurDate(value)
    console.log('onDateChange', value, allList.map(i => i.flows))
    const item = allList.find(i => i.period === value)
    setList(value ? item.flows : allList.map(i => i.flows).flat())
    setCurDateTotalScore(value ? item.balance : score)
  }
  
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render(text, record, index) {
        return `${ index + 1 }`
      }
    },
    {
      title: '日期',
      dataIndex: 'transactionTime',
      key: 'transactionTime',
      render(text, record, index) {
        return text
      }
    },
    {
      title: '广告投放实付费用',
      dataIndex: 'appReferVolume',
      key: 'appReferVolume',
      render(text, record, index) {
        return (
          <span className={ styles['text-price'] }>
            <span>￥</span>
            <span className={ styles['text-price-number'] }>{ text }</span>
          </span>
        )
      }
    },
    {
      title: '获得积分',
      dataIndex: 'transactionVolume',
      key: 'transactionVolume',
      render(text, record, index) {
        return text
      }
    },
  ]

  return (
    <Modal 
      width={ 1104 }
      title="积分值详情"
      footer={ null }
      visible={ isModalVisible }
      onCancel={ onModalCancel }
      >
      <div className={ `${ styles['modal-tit'] }` }>我的积分值：<span className={ `${ styles['score'] }` }>{ score }</span>计算方式：统计最近12个自然月（含本月）投放广告积分的总值</div>

      <div className={ `${ styles['modal-filter'] }` }>
        日期：
        <Select 
          onChange={ onDateChange }
          placeholder="全部"
          allowClear 
          value={ curDate }
          options={ dateList }
          size="large"
          style={{ width: 200 }}>
        </Select>
      </div>
      {/*列表*/}
      <div className={ `${ styles['table-tit'] }` }>总积分：{ curDateTotalScore }</div>
      <Table 
        className={ styles['score-table'] } 
        dataSource={ list } 
        columns={ columns } 
        rowKey="transactionId" />
    </Modal>
  )
}