import { createStore, compose, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'


import user from './user/reducer'
import menu from './menu/reducer'

const rootReducer = combineReducers({
  user,
  menu,
})

// const store = createStore(rootReducer,
// 	window && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
// 	)

const store = createStore(rootReducer,
	compose(
		applyMiddleware(...[thunk]),
		(window && (window as any).__REDUX_DEVTOOLS_EXTENSION__) ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
		// window && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
	)
)

export type RootState = ReturnType<typeof rootReducer>

export default store