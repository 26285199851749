import { Redirect } from 'react-router-dom'
import util from '../libs/util'

import { MainLayout } from '../layouts'
import { NotFound } from '../pages'

import mineRoute from './mine'
import contentRoute from './content'
import baseRoute from './base'
import adRoute from './ad'

export const routes = [
  {
    path: '/',
    exact: true,
    render:() => {
      return <Redirect to={ '/home' } />
    },
  },

  // 基础
  ...baseRoute,

  // 热幕广告主
  {
    component: MainLayout,
    auth: true,
    routes: [
      ...mineRoute,
      ...contentRoute,
      ...adRoute,
      {
        path: '*',
        component: NotFound,
      },
    ]
  },

  {
    path: '*',
    component: NotFound,
  },
]