import React, { useState, useEffect, useRef } from 'react'
import { Spin, Modal, message, Checkbox, Input, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import util from '../../../libs/util'
import { GuideModal, Crumb } from '../../../components'


import styles from './Cashier.module.css'

const { confirm } = Modal
const CheckboxGroup = Checkbox.Group
interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
      isConfirmPage?: boolean,
    },
  },
}

const tempPayTypeList = [
  {
    key: 'RM_PAY',
    text: '热幕钱包支付', // '我的钱包资金',
  },
  {
    key: 'WECHAT_PAY_PC_WEB_JTT',
    text: '微信支付',
  },
]
const agentItem = {
  key: 'ZS_PAY',
  text: '申请代理商支付',
  tips: '如发生退款，退款金额将退还至代理商账户',
}

export const AdCashier: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  const { mallOrderPlanId, isConfirmPage } = props.match.params
  const [canPay, setCanPay] = useState(false)
  const [payType, setPayType] = useState('RM_PAY')

  const crumbList = [
    {
      name: '投广告',
      path: '/ad/create',
    },
    {
      name: '广告计划详情',
      path: `/ad/detail/${ mallOrderPlanId }`,
    },
    {
      name: '热幕收银台',
      path: '',
    }
  ]

  useEffect(() => {
    getAdDetail()
    getReferAccountInfo()
  }, [])

  const [payTypeList, setPayTypeList] = useState<any>([])

  // 详情
  const [ad, setAd] = useState<any>()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    setIsSubmiting(false)
    if (code === 1) {
      setAd(data)
      setCanPay(data.showPlanStatus === 'ORDER_WAIT')
      if (!!data.adAgentUcOrgId) {
        setPayTypeList([...tempPayTypeList, agentItem])
      } else {
        setPayTypeList([...tempPayTypeList])
      }
      await getOrderTotal(data)
    }
  }

  const [isAutPay, setIsAutPay] = useState(false)
  const getOrderTotal = async (ad) => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      const oTotal = data.find(i => i.mallOrderPlanId === mallOrderPlanId)
      setAd(state => {
        return {
          ...state,
          orderTotal: oTotal,
        }
      })
      const payAmount = oTotal?.planAmount || 0
      // 支付金额为0时，自动支付
      if (payAmount <= 0) {
        setPayType('RM_PAY')
        setIsAutPay(true)
        autoPay('RM_PAY', ad.cancelOrderTime)
      } else {
        getImgCode(tempPayTypeList[1].key)
        focusInput()
      }
    }
  }

  const [availableAmount, setAvailableAmount] = useState(0)
  const [creditAmount, setCreditAmount] = useState(0)
  const [hasPayPwd, setHasPayPwd] = useState(false)
  const [rechargeAmount, setRechargeAmount] = useState(0)
  const getReferAccountInfo = async() => {
    const { data, code } = await util.request({
      method: 'POST',
      url: '/finance/account/getReferAccountInfo',
      data: {
        currencyTypeId: 'RM'
      },
    })

    if (code === 1) {
      const { orgAccount } = data
      const { balance, creditAmount } = orgAccount
      const amount = ad?.orderTotal.planAmount
      const availableAmount = +((+balance + +creditAmount).toFixed(2))
      const def = +((+availableAmount - +amount).toFixed(2))
      setAvailableAmount(availableAmount)
      setCreditAmount(parseFloat(creditAmount))
      setHasPayPwd(orgAccount.hasPassword)
      setRechargeAmount(def < 0 ? Math.abs(def) : 0)
    }
  }

  const togglePayType = (key) => {
    if (key === payType) {
      return
    }
    setPayType(key)

    if (key === 'WECHAT_PAY_PC_WEB_JTT') {
      setQueryTimes(0)
      getResult()
    } else {
      setQueryTimes(0)
    }
  }

  const autoPay = async(type, endTime?)=> {
    if (isSubmiting) {
      return
    }
    setIsSubmiting(true)

    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/pay`,
      data: {
        channelId: type,
        mallOrderPlanId,
        payMethod: 'PC_WEB',
        wechatAuthCode: '',
      },
    })

    setIsSubmiting(false)
    
    if (code === 1) {
      const tradeClientData = JSON.parse(data.tradeClientData)
      // 不需要支付密码
      if (!tradeClientData.needAuth) {
        // 直接调支付结果查询
        getResult(endTime)
        return
      } else {
        // 需要支付密码，密码去支付
        forRmPay(tradeClientData.transactionId)
      }
    }
  }

  // 支付
  const onPay = async() => { 
    if (isSubmiting || !canPay) {
      return
    }
    await autoPay(payType)
  }

  const onReplacePay = async() => {
    if (isSubmiting || !canPay) {
      return
    }
    setIsSubmiting(true)

    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/updateAgentPayStatus`,
      data: {
        agentPayStatus: 'YES',
        mallOrderPlanId: mallOrderPlanId,
      },
    })

    setIsSubmiting(false)
    
    if (code === 1) {
      // 申请成功
      message.success('申请代支付成功')
      setTimeout(() => {
        // 返回订单详情页
        goToDetailPage()
      }, queryIntervalTime)
    }
  }

  const [isPayPwdError, setIsPayPwdError] = useState(false)
  const [payPwd, setPayPwd] = useState('')
  const inputRef = useRef<any>(null)
  const forRmPay = async(transactionId) => {
    setIsSubmiting(true)
    const { data, code, message: msg } = await util.request({
      method: 'POST',
      url: '/finance/account/authPwd/forOrgPay',
      data: {
        password: payPwd,
        transactionId,
      }
    })
    console.log(data, code, msg)
    if (code === 1) {
    // 支付成功
      getResult()
    } else if (code === 997) {
      setIsPayPwdError(true)
      clearInput()
      focusInput()
    } else {
      message.error(msg || '操作失败')
    }
    setIsSubmiting(false)
  }
  const onPayPwdChange = ({ target }) => {
    const value = target.value.replace(/[^\d| ]/g, '')
    setPayPwd(value)
  }
  const clearInput = () => {
    setPayPwd('')
  }
  const focusInput = () => {
    inputRef.current.focus({
      cursor: 'start',
    })
  }

  const getImgCode = async(payType) => { 
    if (isSubmiting) {
      return
    }

    // setIsSubmiting(true)

    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/pay`,
      data: {
        channelId: payType,
        mallOrderPlanId,
        payMethod: 'PC_WEB',
        wechatAuthCode: '',
      },
    })

    if (code === 1) {
      setImgSrc(data.tradeClientData)
      setCanPay(true)
      console.log('getImgCode', imgSrc)
    } else {
      setCanPay(false)
    }
  }

  const goToDetailPage = () => {
    // `/ad/-detail/${ ad.mallOrderMediaId }`
    history.push('/ad/list')
  }

  const [queryTimes, setQueryTimes] = useState(0)
  // 3秒查询一次
  const queryIntervalTime = 3000
  useEffect(() => {
    const timer = setInterval(() => {
      if (!queryTimes || queryTimes < 0) {
        clearInterval(timer)
        setIsSubmiting(false)
        setIsAutPay(false)
        return
      }
      getPayResult()
     }, queryIntervalTime)

    return () => clearInterval(timer)
  }, [queryTimes])

  const getResult = (endTime?) => {
    // 当前时间 距 截止时间 还剩下的时间
    const times = (+new Date(endTime || ad?.cancelOrderTime) - +new Date()) / queryIntervalTime
    setQueryTimes(times)
    console.log('times', times)
    const getPayResult = async () => {
      await getPayResult()
    }
  }
  // 查询支付结果
  const getPayResult = async() => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/order/pay/query/${ mallOrderPlanId }`,
    })

    if (data) {
      setIsAutPay(false)
      setIsSubmiting(false)
      setQueryTimes(0)
      // 支付成功
      message.success('支付成功')
      setTimeout(() => {
        // 返回订单详情页
        goToDetailPage()
      }, queryIntervalTime)
    } else {
      setQueryTimes(queryTimes - 1)
    }
    console.log(`getPayResult:${queryTimes}:`, code, data)
  }

  const childRef: any = useRef()
  const goToRecharge = () => {
    childRef.current.onToggle()
  }

  return (
    <Spin spinning={ isAutPay || isSubmiting } tip={ isAutPay ? '自动付款中,请稍候...' : '请稍候...' }>
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } ifont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>热幕收银台</div>
        </div>

        <div className={ `${ styles['cashier-top'] }` }>
          <div className={ `${ styles['cashier-tit'] }` }>广告投放费用</div>
          <div className={ `${ styles['text-price'] }` }>
            <span className={ `${ styles['text-price-unit'] }` }>￥</span>
            <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).int }</span>
            <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).dec }</span>
          </div>
          <div className={ `${ styles['pay-tips'] }` }>请在 <span className={ `${ styles['pay-tips-important'] }` }>{ ad?.cancelOrderTime }</span> 前支付，否则计划将自动取消</div>
        </div>

        <div className={ `${ styles['paytype-list'] }` }>
          {
            payTypeList.map((item) => {
              return (
                <div 
                  className={ `${ styles['paytype-item'] } ${ item.key === payType ? styles['is-selected'] : '' }` }
                  onClick={ () => togglePayType(item.key) }
                  key={ item.key }
                  >
                  <div className={ `${ styles['paytype-item-top'] }` }>
                    <div className={ `${ styles['paytype-item-checkbox'] }` }></div>
                    <div className={ `${ styles['paytype-name'] }` }>{ item.text }</div>
                    {
                      item.tips && (<div className={ `${ styles['paytype-tips'] }` }>{ item.tips }</div>)
                    }
                    {
                      (item.key === 'RM_PAY' && payType === 'RM_PAY') && (
                        <div className={ `${ styles['text-price'] }` }>
                          <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                          <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).int }</span>
                          <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(ad?.orderTotal?.planAmount).dec }</span>
                        </div>
                      )
                    }
                  </div>
                  
                  {
                    (item.key === 'RM_PAY' && payType === 'RM_PAY') && (
                      <>
                        <div className={ `${ styles['paytype-amount-tips'] }` }>钱包可用金额（含授信额度）：￥<span className={ `${ styles['paytype-amount-number'] }` }>{ util.tools.splitNum2String(availableAmount).int }{ util.tools.splitNum2String(availableAmount).dec }</span>
                          {
                            (rechargeAmount > 0) && (
                              <>
                                <span className={ `${ styles['paytype-amount-important'] }` }>钱包可用金额不足，还需￥{ util.tools.splitNum2String(rechargeAmount).int }{ util.tools.splitNum2String(rechargeAmount).dec }</span>
                                <span className={ `${ styles['btn-recharge'] }` } onClick={ goToRecharge }>去充值</span>
                              </>
                            )
                          }
                        </div>

                        <div className={ `${ styles['paytype-form'] }` }>
                          <div className={ `${ styles['paytype-form-tit'] }` }>请输入6位数热幕支付密码</div>
                          <div className={ `${ styles['paytype-form-item'] }` }>
                            <Input.Password 
                              className={ `${ styles['paytype-form-ipt'] }` } 
                              iconRender={visible => ('')}
                              value={ payPwd }
                              ref= { inputRef }
                              onChange={ onPayPwdChange }
                              onPressEnter={ onPay }
                              maxLength={ 6 }
                               />
                            <div className={ `${ styles['paytype-form-ipt-wrap'] }` }>
                              {
                                new Array(6).fill(0).map((item, index) => {
                                  return (
                                    <div className={ `${ styles['paytype-form-ipt-item'] }` } key={ index }></div>
                                  )
                                })
                              }
                            </div>
                            {
                              !hasPayPwd && <span className={ `${ styles['paytype-form-tips'] }` }>未设置热幕支付密码或忘记密码，请前往热幕小程序进行设置</span>
                            }
                            
                            {
                              isPayPwdError && <span className={ `${ styles['paytype-form-tips'] } ${ styles['error'] }` }>支付密码不正确</span>
                            }
                          </div>
                          <div className={ `${ styles['btn-pay'] } ${ !canPay ? styles['is-disabled'] : '' }` } onClick={ onPay }>
                            确认支付
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    (item.key === 'WECHAT_PAY_PC_WEB_JTT' && payType === 'WECHAT_PAY_PC_WEB_JTT' && imgSrc) && (
                      <div className={ `${ styles['weixin-cont'] }` }>
                        <div className={ `${ styles['weixin-tips'] }` }>请扫码完成微信支付</div>
                        <img className={ `${ styles['weixin-code-img'] }` } src={ imgSrc } />
                      </div>
                    )
                  }

                  {
                    (item.key === 'ZS_PAY' && payType === 'ZS_PAY') && (
                      <>
                        <div className={ `${ styles['paytype-cont'] }` }>
                          <div className={ `${ styles['btn-pay'] } ${ !canPay ? styles['is-disabled'] : '' }` } onClick={ onReplacePay }>确认申请代支付</div>
                        </div>
                      </>
                    )
                  }
                </div>
              )
            })
          }
          
        </div>
      </div>

      <GuideModal childRef={ childRef } />
    </div>
    </Spin>
  )
}