import React, { useState, useContext, useEffect } from 'react'
import util from '../../../../libs/util'
import styles from './Filter.module.css'

import { filterPointsContext, setFilterPointsContext } from '../SelectPoints'

import { Select, Input, message, Checkbox, Radio } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { clearScreenDown } from 'readline'
const { Option } = Select
const { Search } = Input

interface Props {
  ad: any,
}

export const FilterPointsPanel: React.FC<Props> = ({ ad }) => {
  const filter = useContext(filterPointsContext)
  const setFilter = useContext(setFilterPointsContext)

  const sourceCityList = ad?.districtList || []
  const [cityList, setCityList] = useState<any>([])
  const list:any = []
  useEffect(() => {
  	findEnumItemList()
  }, [])

  useEffect(() => {
    if (!sourceCityList || !sourceCityList.length) {
      return
    }
    // 默认全选
    setSelectedCity(sourceCityList.map(i => i.districtId))
    sourceCityList.forEach(async(i) => {
      await getDistrictList(sourceCityList, i.districtId)
    })
    // getIP()
  }, [sourceCityList])

  // 院线
  const [cinemaChainIdItems, setCinemaChainIdItems] = useState<any>([])
  const findEnumItemList = async() => {
  	const key = ['LOCATION_TYPE', 'CINEMA_CHAIN']
    const params = {
      groupKeyArray: key.join(','),
    }
    const { data, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: params,
    })
    if (code === 1) {
      setCinemaChainIdItems(data['CINEMA_CHAIN'])
      setLocationTypeItems(data['LOCATION_TYPE'])
    }
  }
  const onCinemaChainIdChange = (value) => {
  	setFilter((state) => {
      return {
        ...state,
        cinemaChainId: value,
      }
    })
  }

  // 只显示投放日期满投的点位
  const [continuousDateSearch, setContinuousDateSearch] = useState(true)
  const onContinuousDateSearchChange = ({ target }) => {
    const value = target.value
  	setFilter((state) => {
      return {
        ...state,
        pointMinDays: value ? ad.deliveryDays : 7,
      }
    })
    setContinuousDateSearch(value)
  }
  
  const host = 'https://apis.map.qq.com'
  const key = encodeURI('Q2WBZ-DDSKP-XAJDG-VD56L-FAER6-DJF4H')
  const output = 'jsonp'
  const api = {
    getIP: '/ws/location/v1/ip',
    search: '/ws/place/v1/search',
    suggestion: '/ws/place/v1/suggestion',
    getDistrict: '/ws/district/v1/list',
    // 逆地址解析
    geocoder: '/ws/geocoder/v1/'
  }
  const createScript = ({ apiUrl, callbackName, fn, params }) => {
    let url = encodeURI(`${host}${apiUrl}?key=${key}&output=${output}&callback=${callbackName}`)
    if (params) {
      url += `&${params}`
    }

    let script:any = document.getElementById(callbackName)
    if (script) {
      script.parentNode.removeChild(script)
    }

    // 创建script标签，设置其属性
    script = document.createElement('script')
    script.setAttribute('src', url)
    script.setAttribute('id', callbackName)
    document.getElementsByTagName('head')[0].appendChild(script)

    window[callbackName] = fn
  }

  const getIP = () => {
    createScript({
      apiUrl: api.getIP,
      callbackName: 'getIpCallback',
      fn: (data) => {
        const { result, status } = data
        if (status === 0) {
          const { location, ad_info: adInfo } = result
          const cityCode = `${ String(adInfo.adcode).substring(0, 4) }00`
          let { lat: latitude, lng: longitude } = location
          // 当前位置在在投放城市内
          const isInCityList = cityList.find(i => i.districtId === cityCode)
          isInCityList && setSelectedCity([cityCode])
  
          // 在投放城市内，且当前未选择城市
          if (isInCityList && !filter.administrativeAreaId.length) {
            setFilter((state) => {
              return {
                ...state,
                administrativeAreaId: [cityCode],
                entityLocation: isInCityList ? [latitude, longitude] : [],
                orderingFlag: isInCityList ? 1 : 0,
              }
            })
          }
  
          console.log('getIP', result, cityCode)
        }
      },
      params: '',
    })
  }

  // 影院、院线名条
  const onPressEnter = (e) => {
    setFilter((state) => {
      return {
        ...state,
        keyWord: e.target.value,
      }
    })
  }
  const onSearch = (value) => {
    setFilter((state) => {
      return {
        ...state,
        keyWord: value,
      }
    })
  }

  // 中心点位置
  const [centerName, setCenterName] = useState('')
  const [selectedCenterName, setSelectedCenterName] = useState('')
  const [suggestionList, setSuggestionList] = useState<any>([])
  const [suggestionLocation, setSuggestionLocation] = useState<any>([])
  const getSuggestion = (centerName) => {
    createScript({
      apiUrl: api.suggestion,
      callbackName: 'getSuggestionCallback',
      fn: ({ data }) => {
        setSuggestionList(data || [])
      },
      params: `keyword=${ centerName }`,
    })
  }
  // const [queryTimes, setQueryTimes] = useState(0)
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!queryTimes || queryTimes < 0) {
  //       clearInterval(timer)
  //       return
  //     }
  //     getSuggestion(centerName)
  //    }, 200)

  //   return () => clearTimeout(timer)
  // }, [queryTimes])
  // TODO 防抖
  const onCenterChange = ({ target }) => {
    const value = target.value
    if (value) {
      setCenterName(value)
      // setQueryTimes(queryTimes + 1)
      getSuggestion(value)
    } else {
      setCenterName('')
      setSelectedCenterName('')
      setSuggestionList([])
    }
  }
  const selecteSuggestion = (record) => {
    setCenterName('')
    setSuggestionLocation(filter.entityLocation)
    const { location } = record
    setFilter((state) => {
      return {
        ...state,
        entityLocation: [location.lat, location.lng],
        orderingFlag: 1,
      }
    })
    setSelectedCenterName(record.title)
  }
  const clearSelectedCenterName = () => {
    setSelectedCenterName('')
    setFilter((state) => {
      return {
        ...state,
        entityLocation: [...suggestionLocation],
        orderingFlag: 1,
      }
    })
  }

  const [isFold, setIsFold] = useState(false)
  const toggleFold = () => {
    setIsFold(!isFold)
  }

  const [isBrandFold, setIsBrandFold] = useState(true)
  const toggleBrand = () => {
    setIsBrandFold(!isBrandFold)
  }

  // 投放城市
  const [isCityFold, setIsCityFold] = useState(true)
  const toggleCity = () => {
    setIsCityFold(!isCityFold)
  }
  const [selectedCity, setSelectedCity] = useState<any>([])
  const onCityChange = (value) => {
    // 至少得选1个
    if (value.length < 1) {
      return
    }
    
    let tempList = JSON.parse(JSON.stringify(cityList))
    let dList:any = []
    tempList.forEach(item => {
      const districtId = item.districtId
      // 有这个城市，要让区全选
      if (value.includes(districtId)) {
        item.selectedDistrictList = item.districtList.map(i => i.districtId)
      } else {
      // 没有这个城市
        item.selectedDistrictList = []
      }
      item.indeterminate = false
      dList.push(...item.selectedDistrictList)
    })
    setCityList(tempList)
    setSelectedCity(value)
  	setFilter((state) => {
      return {
        ...state,
        administrativeAreaId: value,
        districtIds: dList,
      }
    })
  }
  const onDistrictListChange = (city, value) => {
    let list = [...cityList]
    let item = list.find(i => i.districtId === city.districtId)
    item.selectedDistrictList = value
    item.indeterminate = item.selectedDistrictList.length !== item.districtList.length && item.selectedDistrictList.length
    setCityList(list)
    let cList:any = []
    let dList:any = []
    list.forEach((item) => {
      if (item.selectedDistrictList.length) {
        cList.push(item.districtId)
        dList.push(...item.selectedDistrictList)
      }
    })
    setCityList(list)
    setFilter((state) => {
      return {
        ...state,
        administrativeAreaId: cList,
        districtIds: dList,
      }
    })
  }
  const getDistrictList = async(cList, districtId) => {
    const { data = [], code } = await util.request({
      method: 'GET',
      url: `/config/district/searchChildrenDistrict`,
      params: {
        districtType: 'CITY',
        districtId,
      },
    })

    if (code === 1) {
      list.push({
        districtId,
        list: data,
      })
      if (list.length === sourceCityList.length) {
        let tempList = JSON.parse(JSON.stringify(sourceCityList))
        tempList.forEach(item => {
          const districtList = list.find(i => i.districtId === item.districtId).list
          item.districtList = districtList
          item.selectedDistrictList = districtList.map(i => i.districtId)
          item.indeterminate = false
          item.isChecked = true
        })
        setCityList(tempList)
        console.log(list)
      }
    }
  }

  // 位置距离
  const distanceList = [
    {
      itemKey: null,
      itemValue: '不限',
    },
    {
      itemKey: 1500,
      itemValue: '1.5km',
    },
    {
      itemKey: 2000,
      itemValue: '2km',
    },
    {
      itemKey: 3000,
      itemValue: '3km',
    },
    {
      itemKey: 5000,
      itemValue: '5km',
    },
  ]
  const onDistanceChange = ({ target }) => {
  	setFilter((state) => {
      return {
        ...state,
        geoDistance: target.value,
      }
    })
  }

  // 位置类型
  const [locationTypeItems, setLocationTypeItems] = useState<any>([])
  const onLocationTypeChange = (value) => {
  	setFilter((state) => {
      return {
        ...state,
        locationType: value,
      }
    })
  }

  return(
  	<div className={ `${ styles['ad-filter'] }` }>
  		<div className={ `${ styles['ad-filter-top'] }` }>
  			<Input.Group compact style={{ width: '60%' }}>
		      <Input.Search allowClear style={{ width: 300 }} enterButton onPressEnter={ onPressEnter } onSearch={ onSearch } placeholder='影院、院线名称' />
		    </Input.Group>
  		</div>

  		<div className={ `${ styles['form'] } ${ isFold ? styles['hide'] : '' }` }>
        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>投放城市：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['city-wrap'] } ${ isCityFold ? styles['small'] : '' }` }>
	  				<Checkbox.Group 
              // style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} 
              value={ selectedCity }
              onChange={ onCityChange }>
	  					{
	  						cityList?.map((city) => {
	  							return (
                    <div className={ `${ styles['brand-item'] }` } key={ city?.districtId }>
	  								  <div className={ `${ styles['brand-item-l'] }` } key={ city?.districtId }>
                        <Checkbox 
                          value={ city.districtId } 
                          indeterminate={ city.indeterminate }
                          >{ city?.districtName }</Checkbox>
                      </div>
                      <div className={ `${ styles['district-list'] }` }>
                        <Checkbox.Group 
                          style={{ flex: 1, display: 'flex', flexWrap: 'wrap', }} 
                          value={ city.selectedDistrictList }
                          data-city={ city }
                          onChange={ (value) => { onDistrictListChange(city, value) } }>
                          {
                            city?.districtList?.map((district) => {
                              return (
                                <div className={ `${ styles['district-item'] }` } key={ district?.districtId }>
                                  <Checkbox value={ district?.districtId }>{ district?.districtName }</Checkbox>
                                </div>
                              )
                            })
                          }
                        </Checkbox.Group>
                      </div>
                    </div>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
	  				<div className={ `${ styles['btn-more'] }` } onClick={ toggleCity }>{ isCityFold ? '更多' : '收起' }<div className={ `${ styles['icon-other'] } ifont i-trangle-b ${ isCityFold ? styles['bot'] : '' }` }></div></div>
  				</div>
  			</div>

        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>位置距离：</div>
  				<div className={ `${ styles['form-value'] }` }>
            <div className={ `${ styles['form-flex'] }` }>
              <Input 
                placeholder="输入中心点位置" 
                prefix={<SearchOutlined />}
                style={{ width: '300px', marginRight: '10px' }}
                value={ centerName }
                onChange={ onCenterChange } 
                />
              { (selectedCenterName) && <span className={ `${ styles['cur-position'] }` } onClick={ clearSelectedCenterName }>选中的中心点位置：{ selectedCenterName }<span className={ `ifont i-close ${ styles['btn-clear'] }` }></span></span> }
              {
                (centerName && suggestionList.length > 0) && (
                  <div className={ `${ styles['suggestion-list'] }` }>
                    {
                      suggestionList.map(i => {
                        return (
                          <div className={ `${ styles['suggestion-item'] }` } key={ i.id } onClick={ () => { selecteSuggestion(i) } }>
                            <div className={ `${ styles['suggestion-item-tit'] }` }>{ i.title }</div>
                            <div>{ i.province }-{ i.city }-{ i.district }</div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
	  				<Radio.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} onChange={ onDistanceChange }>
	  					{
	  						distanceList?.map((item) => {
	  							return (
	  								<span className={ `${ styles['brand-item'] }` } key={ item?.itemKey }><Radio value={ item.itemKey }>{ item?.itemValue }</Radio></span>
	  							)
	  						})
	  					}
	  				</Radio.Group>
  				</div>
  			</div>

  			<div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>院线：</div>
  				<div className={ `${ styles['form-value'] } ${ styles['brand-wrap'] } ${ isBrandFold ? styles['small'] : '' }` }>
          <Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} onChange={ onCinemaChainIdChange }>
	  					{
	  						cinemaChainIdItems.map((item) => {
	  							return (
	  								<span className={ `${ styles['brand-item'] }` } key={ item.itemKey }><Checkbox value={ item.itemKey }>{ item.itemValue }</Checkbox></span>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
	  				<div className={ `${ styles['btn-more'] }` } onClick={ toggleBrand }>{ isBrandFold ? '更多' : '收起' }<div className={ `${ styles['icon-other'] } ifont i-trangle-b ${ isBrandFold ? styles['bot'] : '' }` }></div></div>
  				</div>
  			</div>

        <div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>位置类型：</div>
  				<div className={ `${ styles['form-value'] }` }>
	  				<Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }} onChange={ onLocationTypeChange }>
	  					{
	  						locationTypeItems?.map((item) => {
	  							return (
	  								<span className={ `${ styles['brand-item'] }` } key={ item?.itemKey }><Checkbox value={ item.itemKey }>{ item?.itemValue }</Checkbox></span>
	  							)
	  						})
	  					}
	  				</Checkbox.Group>
  				</div>
  			</div>

  			<div className={ `${ styles['form-item'] }` }>
  				<div className={ `${ styles['form-label'] }` }>只显示投放日期满投的点位：</div>
  				<div className={ `${ styles['form-value'] }` }>
  					<Radio.Group onChange={ onContinuousDateSearchChange } value={ continuousDateSearch }>
				      <span className={ `${ styles['radio-item'] }` }>
				      	<Radio value={ true }>是</Radio>
				      </span>
				      <span className={ `${ styles['radio-item'] }` }>
				      	<Radio value={ false }>否</Radio>
				      </span>
				    </Radio.Group>
  				</div>
  			</div>
  		</div>
  	</div>
  )
}