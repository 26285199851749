import * as actionTypes from '../actionTypes'
import { getMenuList, getMyElementList } from '../../api'

// 获取菜单
export const getMenuListAction = data => async dispatch => {
	const res = await getMenuList(data)
  dispatch({
    type: actionTypes.SET_MENU_LIST,
    payload: res,
  })
}

// 获取按钮
export const getElemsAuthAction = data => async dispatch => {
	const res = await getMyElementList(data)
  dispatch({
    type: actionTypes.SET_ELEM_LIST,
    payload: res,
  })
}