import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './Order.module.css'

import { RightOutlined } from '@ant-design/icons'

import util from '../../../../../libs/util'

import authIcon from '../../../../../assets/images/icons/icon-auth.png'
import executingIcon from '../../../../../assets/images/icons/icon-executing.png'
import doneIcon from '../../../../../assets/images/icons/icon-done.png'
import waitIcon from '../../../../../assets/images/icons/icon-wait.png'
import payIcon from '../../../../../assets/images/icons/icon-pay.png'
import arrowIcon from '../../../../../assets/images/icons/icon-arrow-r.png'

interface ListItem {
  icon: string,
  name: string,
  status: string,
  key: string,
  count: number
}

const defaultList: ListItem[] = [
  {
    icon: waitIcon,
    name: '计划中',
    status: 'SAVE',
    key: 'saveCount',
    count: 0
  },
  {
    icon: payIcon,
    name: '待付款',
    status: 'ORDER_WAIT',
    key: 'orderWaitCount',
    count: 0
  },
  {
    icon: authIcon,
    name: '待上刊',
    status: 'DELIVERY_WAIT',
    key: 'deliveryWaitCount',
    count: 0
  },
  {
    icon: executingIcon,
    name: '刊播中',
    status: 'EXECUTING',
    key: 'executingCount',
    count: 0
  },
  {
    icon: doneIcon,
    name: '已完成',
    status: 'FINISHED',
    key: 'finishedCount',
    count: 0
  },
]

export const Order: React.FC = () => {
  const history = useHistory()
  
  const [list, setList] = useState(defaultList)

  useEffect(() => {
    getOrderPlanState()
  }, [])

  const getOrderPlanState = async () => {
    const result = await util.request({
      method: 'GET',
      url: 'mall/order/getOrderPlanState'
    })

    const data = result.data || {}
    if (result.code === 1) {
      list.forEach(item => {
        const key = item.key
        const count = data[key]
        if (count) {
          item.count = count
        }
      })
      setList([...list])
    }
  }

  const goToAdPage = () => {
    history.push(`/ad/list`)
  }

  const goToPage = (status) => {
    history.push(`/ad/list?status=${ status }`)
  }

  return (
    <div className={ styles.order }>
      <h3 className={ `${ styles.title } flex justify-between` }>
        <span>我的广告计划</span>
        <p className={ styles.all } onClick={ goToAdPage }>
          <span className="mr1">查看全部</span>
          <RightOutlined />
        </p>
      </h3>

      <ul className={ `${ styles['order-status-list'] }` }>
        {
          list.map((item, index) => {
            return (
              <li className={ `${ styles['order-status-item'] }` } key={ index } onClick={ () => { goToPage(item.status) }}>
                <div className={ `${ styles['order-status-icon'] }` }>
                  <img width="33" src={ item.icon } alt="" />
                </div>
                <p>{ item.name }</p>

                {
                  item.key !== 'finishedCount' && <span className={ `${ styles.count } ${ item.count > 0 ? '' : styles.hide }` }>{ item.count }</span>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}