import React from 'react'
import styles from './NoRecord.module.css'

interface Props {
  noRecordText?: string,
}

export const NoRecord: React.FC<Props> = ({ noRecordText }) => {
  return (
  	<div className={ styles['no-record'] }>
      <div className={ styles['bg-no-record'] }></div>
      <div>{ noRecordText || '暂无记录~' }</div>
    </div>
  )
}