import React, { useState, useEffect } from 'react'
import styles from './MainLayout.module.css'

import { useHistory } from 'react-router-dom'
import { Header, Footer, Sidebar } from '../../components'

import { renderRoutes } from 'react-router-config'

import util from '../../libs/util'

export const MainLayout: React.FC = (props: any) => {
  const history = useHistory()
  const [height, setHeight] = useState<number>(600)
  
  useEffect(() => {
    setHeight(window.innerHeight - 132)
  })

  useEffect(() => {
    util.bus.on('UNAUTH', () => {
      history.push('/sign/signIn')
    })

    return function() {
      util.bus.off('UNAUTH', () => {})
    }
  }, [])

  return (
    <div className={ styles.wrap }>
      <Header />
      <div className={ `${ styles.main }` }>
        <Sidebar/>
        <div className={ `${ styles['content'] }` }>
          <div className={ `${ styles['content-inner'] }` } style={{ minHeight: `${ height }px` }}>
            { renderRoutes(props.route.routes) }
            {/*{ props.children }*/}
          </div>
          {/* <Footer/> */}
        </div>
      </div>
    </div>
  )
}