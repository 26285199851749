import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Tooltip, Calendar } from 'antd'
import type { Moment } from 'moment'
import moment from 'moment'
import styles from './PointPriceDetailModal.module.css'
import util from '../../../../libs/util'
import { PointGradeIntroModal } from '../point-grade-intro-modal'
import { PriceRulesIntroModal } from '../price-rules-intro-modal'

interface Props {
  childRef: any,
}
const dateFormat = 'YYYY-MM-DD'
type RangeValue = [Moment, Moment] | undefined

export const PointPriceDetailModal: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    onToggle(queryForm) {
      setQueryForm(queryForm)
      showEffectModal()
      getList(queryForm)
    }
  }))
  const [queryForm, setQueryForm] = useState<any>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showEffectModal = () => {
    setIsModalVisible(true)
  }

  const pointGradeIntroModalRef: any = useRef()
  const showPointGradeModal = () => {
    pointGradeIntroModalRef.current.showModal()
  }

  const priceRulesIntroModalRef: any = useRef()
  const showPriceRulesIntroModal = () => {
    priceRulesIntroModalRef.current.showModal()
  }

  // 
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [point, setPoint] = useState<any>()
  const [priceList, setPriceList] = useState<any>()
  const getList = async(queryForm) => {
    setIsSubmiting(true)

    const status = queryForm?.showPlanStatus
    const isBeforePay = status === 'DRAFT' || status === 'SAVE'
    const apiName = isBeforePay ? 'getPointSku' : 'getOrderPointDetail'
    const params = {
      resourcePointId: queryForm?.resourcePointId || null,
      mallOrderPlanId: queryForm?.mallOrderPlanId || null,
      mallOrderMediaId: queryForm?.mallOrderMediaId || null,
    }

    const url = isBeforePay ? `/mall/point/sku/getPointSku` : `/mall/delivery/getOrderPointDetail`

    const { data, code } = await util.request({
      method: 'GET',
      url,
      params,
    })
    if (code === 1) {
      const list = data.priceList
      const betweenDate = [list[0].pointDate, list[list.length - 1].pointDate]
      const today = moment().startOf('day').format(dateFormat)
      setPoint(data)
      setValidRange([moment(betweenDate[0], dateFormat), moment(betweenDate[1], dateFormat)])
      setPriceList(list)
      setCurrentDate(betweenDate[0])
      setIsSubmiting(false)
    }
  }

  // 设置可以显示的日期
  const [validRange, setValidRange] = useState<RangeValue>(undefined)
  // 展示日期
  const [currentDate, setCurrentDate] = useState('')
  const onPanelChange = (date, mode) => {
    if (mode === 'month') {
      const selectedDateStr = util.moment(date).format('YYYY-MM-DD')
      const selectedDate = new Date(selectedDateStr)
      const year = selectedDate.getFullYear()
      const month = selectedDate.getMonth()
      setCurrentDate(util.tools.formatDate(new Date(year, month, 1)))
    }
  }
  const dateFullCellRender = (value) => {
    const dateStr = moment(value).format(dateFormat)
    const item = priceList?.find(i => i.pointDate === dateStr)
    const isToday = dateStr === moment().startOf('day').format(dateFormat)
    return (
      <div className={ `${ styles['calendar-cell'] } ${ isToday ? styles['is-today'] : '' }  ${ item ? styles['calendar-delivery-bg'] : '' } ` }>
        <div className={ `${ styles['calendar-date'] }` }>{ moment(value).date() }</div>
        <>
          { item ? <span className={ `${ styles['text-price'] }` }><span className={ `${ styles['price-unit'] }` }>￥</span>{ util.tools.moneyClearZero(item.salePrice) }</span> : '' }
        </>
      </div>
    )
  }

  const convertDate = function(date) {
    return date?.split('-')[0] === '9999' ? '长期' : date?.split('-').join('.')
  }
  // YYYY-MM-DD to YYYY.MM.DD
  const formatDate = (dateStr) => {
    return dateStr ? dateStr.split('-').join('.') : ''
  }

  return (
    <Modal 
      title="点位投放费用" 
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) } }
      >
      <div className={ `${ styles['point-top'] }` }>
        <div className={ `${ styles['point-name'] }` }>
          <div className={ styles['point-name-text'] }>{ point?.pointDevice?.pointName }</div>
          <div className={ styles['rate-list'] }>
            {
              new Array(point?.pointDevice?.pointGrade).fill(1).map((item, index) => {
                return <div className={ `${ styles['rate-item'] } ifont i-fire` } key={ index }></div>
              })
            }
          </div>
          <div className={ `${ styles['icon-tips'] } ifont i-question` } onClick={ showPointGradeModal }></div>
        </div>
        <div className={ `${ styles['entity-name'] }` }>{ point?.pointDevice?.entityName }</div>

      </div>

      <div className={ `${ styles['ad-form'] }` }>
        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
            投放周期：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            { util.tools.formatDateStr(point?.deliveryStart) }-{ util.tools.formatDateStr(point?.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>(<span className={ `${ point?.usableDeliveryDays != queryForm?.deliveryDays ? styles['important'] : '' }` }>{ point?.usableDeliveryDays }</span>天)</span>
          </div>
        </div>

        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
            播放时段：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            {
              (point) && (<>{ util.tools.convertTimePeriods(point) }</>)
            }
          </div>
        </div>
      </div>

      <div className={ `${ styles['calendar-tips'] }` }>
        <div className={ `${ styles['calendar-tips-item'] }` }>
          <div className={ `${ styles['icon-delivery'] }` }></div>
          <div className={ `${ styles['durtaion-tips'] }` }>投放日期(<span className={ `${ styles['durtaion-tips-number'] } ${ queryForm?.deliveryDays != point?.usableDeliveryDays ? styles['important'] : '' }` }>{ point?.usableDeliveryDays }</span>天)</div>
        </div>

        <div className={ `${ styles['calendar-tips-item'] }` } onClick={ showPriceRulesIntroModal }>
          <div className={ `${ styles['text-important'] }` }>了解定价规则</div>
          <div className={ `${ styles['btn-more'] } ifont i-trangle-t` }></div>
        </div>
      </div>

      {/* <div className={ `${ styles['calendar-wrap'] }` }>
        <Calendar 
          onPanelChange={ onPanelChange }
          dateFullCellRender={ dateFullCellRender }
          value={ calendarValue ? moment(calendarValue) : moment() }
          validRange={ validRange }
           />
      </div> */}
      <div>
        <Calendar 
          value={ currentDate ? moment(currentDate) : moment() }
          dateFullCellRender={ dateFullCellRender } 
          onPanelChange={ onPanelChange } />
      </div>

      <div className={ styles['modal-footer'] } ><span className={ styles['modal-footer-label'] } >合计</span> ￥{ util.tools.moneyClearZero(point?.salePriceTotal || 0) }</div>

      {/*点位评级*/}
      <PointGradeIntroModal  childRef={ pointGradeIntroModalRef } />
      {/*结算规则*/}
      <PriceRulesIntroModal childRef={ priceRulesIntroModalRef } />

    </Modal>
  )
}