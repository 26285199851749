import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './libs/util'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { AppStateProvider } from './App.state'

ReactDOM.render(
  <AppStateProvider>
	  <Provider store={ store }>
	    <App />
	  </Provider>
  </AppStateProvider>,
  document.getElementById('root')
)

reportWebVitals()
