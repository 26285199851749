import React, { useState, useEffect } from 'react'
import styles from './ContentList.module.css'

import { ContentInnerList } from './index'
// FilterPanel,
interface ContextValueType {
  contentType: string,
  auditStatuses: string,
  contentNote: string,
}

const defaultFilterContextValue: ContextValueType = {
	contentType: 'VIDEO',
  auditStatuses: '',
  contentNote: '',
}

export const filterContentContext = React.createContext(defaultFilterContextValue)
export const setFilterContentContext = React.createContext<React.Dispatch<React.SetStateAction<ContextValueType>> | any>(undefined)

export const ContentList: React.FC = () => {
	const [filterData, setFilterData] = useState(defaultFilterContextValue)

  return (
    <div className={ `${ styles['content-page'] }` }>
      <div className={ `${ styles['content-page-tit'] }` }>
        <h3>素材库</h3>
      </div>
      <filterContentContext.Provider value={ filterData }>
      	<setFilterContentContext.Provider value={ setFilterData }>
		      {/*<FilterPanel />*/}
		      <ContentInnerList />
	      </setFilterContentContext.Provider>
      </filterContentContext.Provider>

    </div>
  )
}