import React from 'react'
import styles from './PersonalHome.module.css'

import { Profile } from './index'
import { Calendar } from '../companyHome/calendar'
import { Order }  from '../companyHome/order'
import { Wallet }  from '../companyHome/wallet'
interface Props {
  isManager?: boolean,
}
export const PersonalHome: React.FC<Props> = ({ isManager }) => {
  return (
    <div className={ `${ styles['personal-home'] }` }>
      <div className={　styles['personal-home-tit'] }>我的主页</div>
      <div className={ styles['personal-cont'] }>
        <div className={ styles['personal-cont-l'] }>
          <Profile />
          <Order />
        </div>
        {
          isManager && (
          <div className={ `${ styles['personal-cont-r'] }` }>
            <Wallet isPersonal />
          </div>
          )
        }
      </div>
    </div>
  )
}