import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Pagination, Spin, Modal, message, Input, Tooltip } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../../libs/util.constants'
import { AddMaterialModal } from '../add-material'
import { PreviewEffectModal } from '../preview-effect-modal'
import { CreateForm } from '../create-form'
import { ScreenEffectModal } from '../screen-effect-modal'
import { VideoPlayModal } from '../../../../components/video-play-modal'

import { Ad, defaultAd } from '../../index'
import { defaultSubmitForm } from '../../create/AdCreate'
import styles from './BaseInfo.module.css'

interface Props {
  childRef?: any,
  ad: Ad,
  onUpDateDeliveryName?: Function,
  onUpdateBaseInfo?: Function,
  isConfirmPage?: boolean,
}

export const BaseInfo: React.FC<Props> = ({ childRef, ad, onUpDateDeliveryName, onUpdateBaseInfo, isConfirmPage }) => {
  useImperativeHandle(childRef, () => ({
    showDeliveryNameModal() {
      showDeliveryNameModal()
    }
  }))

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [isEditMode, setIsEditMode] = useState((ad.showPlanStatus === 'DRAFT' || ad.showPlanStatus === 'SAVE') && !isConfirmPage)
  const [showMaterial, setShowMaterial] = useState(false)

  const [submitForm, setSubmitForm] = useState<any>(defaultSubmitForm)
  useEffect(() => {
    setShowMaterial(ad.showPlanStatus === 'DELIVERY_WAIT' || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED' || (ad.showPlanStatus === 'ORDER_TIMEOUT' && !ad.showPlanStatusMore.includes('PAY_TIMEOUT') && !ad.showPlanStatusMore.includes('CANCEL_PAY') && (ad.showPlanStatusMore.includes('APPROVE_PLATFORM_TIMEOUT') || ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('APPROVE_NO'))))
    setSubmitForm((state) => {
      return {
        ...state,
        planAim: ad.planAim,
        administrativeAreaId: ad?.administrativeAreaId,
        deliveryStart: ad.deliveryStart,
        deliveryEnd: ad.deliveryEnd,
        deliveryContentDuration: ad.deliveryContentDuration,
        deliveryIndustry: ad?.deliveryIndustry,
        timePeriods: ad?.timePeriods,
        customerId: ad?.customerId,
        operatorType: ad?.operatorType,
        playSequence: ad?.playSequence,
      }
    })
  }, [])
  useEffect(() => {
    getDayOrderState()
  }, [ad.mallOrderPlanId])
  // 获取广告计划序号
  const getDayOrderState = async() => {
    if (!ad.mallOrderPlanId) {
      return
    }
    const { data } = await util.request({
      method: 'GET',
      url: `/mall/order/getDayOrderState`,
    })
    const deliveryNameIndex = data + 1
    const districtList = ad.districtList || []
    const operatorTypeName = operatorTypeItems.find(i => i.itemKey === ad.operatorType)?.itemValue

    const deliveryName = ad.deliveryName || `${ districtList[0].districtName }${ districtList.length > 1 ? districtList.length + '城' : '' }-${ ad.deliveryStart?.split('-').join('') }至${ ad.deliveryEnd?.split('-').join('') }-${ operatorTypeName }-${ util.tools.fixZero(deliveryNameIndex, 3) }`
    setDeliveryName(deliveryName)
  }
  // 修改广告名称弹窗
  const [isDeliveryNameModalVisible, setIsDeliveryNameModalVisible] = useState(false)
  const showDeliveryNameModal = () => {
    setIsDeliveryNameModalVisible(true)
  }
  const onDeliveryNameModalCancel = () => {
    setIsDeliveryNameModalVisible(false)
  }
  // 保存
  const goToSave = async() => {
    // 更新订单(保存订单名)
    const updateResult = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName,
      },
    })

    if (updateResult.code !== 1) {
      return
    }

    // 仅将草稿状态变更为保存状态
    if (ad.showPlanStatus !== 'DRAFT') {
      return
    }

    const result = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlanStatus`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName,
      },
    })

    if (result.code === 1) {
      // history.push(`/ad/save/${ ad.mallOrderPlanId }`)
      message.success('保存成功')
      onDeliveryNameModalCancel()
      // reload
      onUpdateBaseInfo && onUpdateBaseInfo()

      // history.push(`/ad/detail/${ ad.mallOrderPlanId }`)
    } else {
      message.error(result.message || '保存失败')
    }
  }
  

  useEffect(() => {
    if (ad.deliveryDateStatus && ad.deliveryDateStatus !== 'YES') {
      Modal.confirm({
        title: '投放周期已失效，请重新选择',
        content: '',
        okText: '重新选择',
        cancelText: '返回',
        onOk: () => {
          showModal()
        },
        onCancel() {
          history.goBack()
        },
      })
    }
    
  }, [ad.deliveryDateStatus])

  const screenEffectModalRef:any = useRef()
  const onShowMaterial = () => {
    screenEffectModalRef.current.onToggle()
  }

  const videoPlayModalRef:any = useRef()
  const previewMedia = () => {
    videoPlayModalRef.current.onToggle(ad.contentUrl)
  }

  const addMaterialModalRef:any = useRef()
  const onMaterial = () => {
    hideMenu()
    addMaterialModalRef.current.onToggle({
      isFormAd: true,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad.deliveryIndustry,
      mallOrderPlanId: ad.mallOrderPlanId,
    })
  }

  const [isMenuShow, setIsMenuShow] = useState(false)
  const showMenu = () => {
    setIsMenuShow(true)
  }
  const hideMenu = () => {
    setIsMenuShow(false)
  }
  const onChangeMaterial = () => {
    history.push(`/ad/content/list/${ ad.mallOrderPlanId }`)
    hideMenu()
  }

  const previewEffectModalRef: any = useRef()
  const onPreview = (record) => {
    previewEffectModalRef.current.showModal(record)
    hideMenu()
  }

  // 广告所属行业
  const getDeliveryIndustry = (ad) => {
    return (<>{ Object.values(ad.deliveryIndustryMore?.itemMap).join(',') }</>)
  }

  const [dayOrderTotal, setDayOrderTotal] = useState(0)
  const [deliveryName, setDeliveryName] = useState('')

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
  }

  const afterUpdateBaseInfo = () => {
    onModalCancel()
    onUpdateBaseInfo && onUpdateBaseInfo()
  }

  const inputRef = useRef<any>(null)
  const onDeliveryNameChange = ({ target }) => {
    setDeliveryName(target.value)
  }
  const onDeliveryNameBlur = async() => {
    // 更新订单(保存订单名)
    const { code, message: msg } = await util.request({
      method: 'POST',
      url: `/mall/order/updateOrderPlan`,
      data: {
        mallOrderPlanId: ad.mallOrderPlanId,
        deliveryName: deliveryName,
      },
    })

    if (code !== 1) {
      message.error(msg || '操作失败')
    } else {
      message.success('广告计划名称更新成功')
      onUpDateDeliveryName && onUpDateDeliveryName(deliveryName)
    }
  }
  const clearInput = () => {
    setDeliveryName('')
  }
  const focusInput = () => {
    inputRef.current.focus({
      cursor: 'start',
    })
  }

  const getHasMenu = () => {
    return (ad.showPlanStatus === 'DELIVERY_WAIT' && ad?.showPlanStatusMore.includes('CONTENT_YES') || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED')
  }
  const getIsDefaultImg = () => {
    return ad.showPlanStatus === 'ORDER_TIMEOUT' && !ad?.showPlanStatusMore.includes('PAY_TIMEOUT') && ad?.showPlanStatusMore.includes('CONTENT_TIMEOUT') || ad?.showPlanStatusMore.includes('CONTENT_NULL') || ad?.showPlanStatusMore.includes('CONTENT_DELETE') || ad.showPlanStatus === 'DELIVERY_WAIT' && !ad?.thumbnailUrl.length
  }

  const getImgUrl = () => {
    return ad && ad?.thumbnailUrl && ad?.thumbnailUrl.length ? `${ baseURL }${ ad?.thumbnailUrl[0] }` : ''
  }

  const [isNameEditMode, setIsNameEditMode] = useState(ad.showPlanStatus === 'DRAFT' && (ad.planAim !== 'NORMAL' && ad.planAim !== 'VIP_PLAN') || ad.showPlanStatus === 'SAVE')

  return (
  	<>
			<div className={ `${ styles['ad-page-top'] }` }>
        <div className={ `${ styles['ad-labels'] }` }>
          <div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad?.operatorType) }
            {
              util.tools.convertAdChannelName(ad) && (<span className={ `${ styles['channel-label'] }` }>{ util.tools.convertAdChannelName(ad) }</span>)
            }
          </div>
          <div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad?.deliveryCinemaModel) }</div>
        </div>

        {/* 状态等 */}
        <div className={ `${ styles['ad-page-top-r'] }` }>
          {/*右边操作及提醒*/}
          <div className={ `${ styles['ad-item-opts'] }` }>
            {/*计划关闭提醒*/}
            {
              (ad.showPlanStatus === 'ORDER_TIMEOUT') && (
                <div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.orderTimeoutStatus(ad.showPlanStatusMore) }</div>
              )
            }

            {/*1分钟之内不显示提示*/}
            {
              (ad?.showPlanStatus === 'ORDER_WAIT' && util.tools.hasLeftTime(ad?.cancelOrderTime)) && (
                <div className={ `${ styles['ad-content-tips'] }` }>剩余付款时间<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad?.cancelOrderTime) }</span>  超时未付款计划将自动关闭</div>
                )
            }

            {/*素材提交提醒*/}
            {
              (ad?.showPlanStatus === 'DELIVERY_WAIT' && (ad?.showPlanStatusMore?.includes('CONTENT_NULL') || ad?.showPlanStatusMore?.includes('CONTENT_NOT_MATCH') || ad?.showPlanStatusMore?.includes('CONTENT_NO'))) && (
                <div className={ `${ styles['ad-content-tips'] }` }>素材需在<span className={ `${ styles['ad-end-date'] }` }>{ ad?.contentUploadDateEnd }</span>前提交，超时未提交计划将自动关闭</div>
                )
            }

            {/*刊播提醒 */}
            {
              (ad?.showPlanStatus === 'DELIVERY_WAIT' && (!ad?.showPlanStatusMore.includes('CONTENT_NULL') && !ad?.showPlanStatusMore.includes('CONTENT_NOT_MATCH'))) && (
                <div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad?.startBetweenDays }</span>天</div>
                )
            }

            {/*刊播数据*/}
            {
              (ad?.showPlanStatus === 'EXECUTING' && ad?.hasDeliveryDays && ad?.hasDeliveryDays > 0) && (
                <div className={ `${ styles['ad-content-tips'] }` }>已刊播<span className={ `${ styles['ad-delivery-days'] }` }>{ ad?.hasDeliveryDays }</span>天</div>
                )
            }
          </div>

          {/*订单状态*/}
          <div className={ `${ styles['ad-status'] }` }>
          {/*.text*/}
            { !isConfirmPage && (ad?.showPlanStatus && ad?.showPlanStatus !== 'DRAFT' ? util.tools.orderStatus(ad?.showPlanStatus).text : '') }
          </div>
        </div>
      </div>

      <div className={ `${ styles['ad-page-cont-inner'] }` }>
        {/*左边*/}
        {
          showMaterial && (
            <div className={ `${ styles['ad-page-cont-inner-l'] }` }>
              {/*<!-- 素材 -->*/}
              <div className={ `${ styles['order-material'] }` }>
                {/* 超时未提交符合投放要求的素材 || 未提交过素材 || 素材已删除 || 待上刊 */}
                {
                  (getIsDefaultImg()) && (
                      <div className={ `icon ${ styles['order-material-default'] }` }>默认图片，体现素材未提供</div>
                    )
                }

                {/* mask */}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH') || ad.showPlanStatusMore && ad.showPlanStatusMore.length && ad.showPlanStatusMore[0] === 'CONTENT_YES' || (ad.showPlanStatus === 'DELIVERY_WAIT' && ad.contentUrl || ad.showPlanStatus === 'EXECUTING' || ad.showPlanStatus === 'FINISHED')) && (
                      <div className={ `${ styles['order-material-mask'] }` }></div>
                    )
                }

                {/*未提交过素材 || 素材已删除*/}
                {
                  (ad.showPlanStatus === 'DELIVERY_WAIT' && (ad.showPlanStatusMore.includes('CONTENT_NULL') || ad.showPlanStatusMore.includes('CONTENT_DELETE'))) && (
                      <>
                        <div className={ `${ styles['order-material-tips'] }` }>
                          {
                            (ad.showPlanStatusMore.includes('CONTENT_DELETE')) && (
                              <div className={ `${ styles['order-material-tips-tit'] }` }>素材已删除</div>
                            )
                          }
                          <div className={ `${ styles['btn-add-material'] }` } onClick={ onMaterial }>提交素材</div>
                        </div>
                      </>
                    )
                }

                {/* 平台未及时审核已提交素材 */}
                {
                  (ad.showPlanStatus === 'ORDER_TIMEOUT' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('APPROVE_PLATFORM_TIMEOUT')) && (<div className={ `${ styles['order-material-tips'] }` } >平台超时未审核</div>)
                }

                {/*素材审核中*/}
                {
                  (ad.showPlanStatus !== 'EXECUTING' && ad.showPlanStatus !== 'FINISHED' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('CONTENT_WAIT') && !ad.showPlanStatusMore?.includes('APPROVE_PLATFORM_TIMEOUT')) && (
                    <div className={ `${ styles['order-material-tips'] }` }>审核中</div>
                  )
                }

                {/*素材不符合要求*/}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                    <div className={ `${ styles['order-material-tips'] }` }>
                      <div className={ `${ styles['order-material-tips-tit'] }` }>不符合投放要求</div>
                      {/* 素材不可投放原因 */}
                      {
                        (ad.showPlanStatusMore.includes('CONTENT_NO')) && (
                          <div className={ `${ styles['order-material-reasons'] }` }>{ ad.contentApproveOpinion }</div>
                        )
                      }
                      {
                        (ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                          <div className={ `${ styles['order-material-reasons'] }` }>素材的行业与广告计划投放的行业不符</div>
                        )
                      }
                      {
                        (ad.showPlanStatus !== 'FINISHED') && (
                          <div className={ `${ styles['btn-add-material'] }` } onClick={ onMaterial }>提交素材</div>
                        )
                      }
                    </div>
                  )
                }

                {/*上传过素材的*/}
                {
                  (ad.thumbnailUrl && ad.thumbnailUrl.length) && (
                    <div className={ `${ styles['order-material-cont'] }` }>
                      <img className={ `${ styles['order-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/448x368`} />
                    </div>
                  )
                }

                {/* 左下角的 */}
                {/* 审核中 || 审核不通过 */}
                {/*上传过素材的*/}
                {
                  (ad.showPlanStatusMore.includes('CONTENT_WAIT') || ad.showPlanStatusMore.includes('CONTENT_NO') || ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH')) && (
                    <div className={ `${ styles['order-content-duration'] } ${ styles['bottom'] }` }>
                      <div className={ `${ styles['btn-play'] }` }><div className={ `${ styles['icon-play'] } ifont i-play` }></div></div>
                      <div>{ util.tools.fixedTime((ad.contentDuration || ad.deliveryContentDuration) / 1000) }</div>
                    </div>
                  )
                }

                {/* 中间的 */}
                {
                  (ad.showPlanStatusMore?.length && ad.showPlanStatusMore.includes('CONTENT_YES')) && (
                    <div className={ `${ styles['order-content-duration'] }` } onClick={ previewMedia }>
                      <div className={ `${ styles['btn-play'] }` }><div className={ `${ styles['icon-play'] } ifont i-play` }></div></div>
                      <div>{ util.tools.fixedTime((ad.contentDuration || ad.deliveryContentDuration) / 1000) }</div>
                    </div>
                  )
                }

                {/*<!-- 菜单 -->*/}
                {
                  (getHasMenu()) && (
                    <div className={ `${ styles['btn-opts'] }` }>
                      <div className={ `${ styles['btn-more'] } ifont i-more` } onClick={ showMenu }></div>
                      {
                        (isMenuShow) && (
                          <div className={ `${ styles['btn-opts-menu'] }` }>
                            <div className={ `${ styles['btn-opts-menu-item'] }` } onClick={ onChangeMaterial }>素材使用管理</div>
                            <div className={ `${ styles['btn-opts-menu-item'] }` } onClick={ onPreview }>银幕画面效果预览</div>
                          </div>
                        )
                      }
                      
                    </div>
                  )
                }
              </div>

              {/*<!-- 只有待上刊状态 -->*/}
              {
                (ad.showPlanStatus === 'DELIVERY_WAIT' && !ad.showPlanStatusMore.includes('CONTENT_YES')) && (
                  <div className={ `${ styles['order-material-requirements'] }` } onClick={ onShowMaterial }>
                    <div>查看广告素材要求</div>
                    <div className={ `${ styles['btn-see-requirements'] } ifont i-trangle-b` }></div>
                  </div>
                )
              }
            </div>
          )
        }
        
        <div className={ `${ styles['ad-page-cont-inner-r'] }` }>
          {
            (ad?.deliveryName) && (
              <div className={ `${ styles['flex'] }` }>
                <div className={ `${ styles['ad-tit-label'] }` }>广告计划名称</div>
                <div className={ `${ styles['delivery-name'] }` }>
                  {
                    isNameEditMode && (
                      <>
                      <Input 
                        className={ `${ styles['delivery-name-ipt'] }` } 
                        value={ deliveryName }
                        size='large'
                        ref= { inputRef }
                        onChange={ onDeliveryNameChange }
                        onBlur={ onDeliveryNameBlur }
                        maxLength={ 32 }
                         />
                        {/* <div className={ `${ styles['btn-checked'] }` } onClick={ onDeliveryNameBlur }>
                          <div className={ `ifont i-checked` }></div>
                        </div> */}
                      </>
                    )
                  }

                  { !isNameEditMode && (
                    <>{ deliveryName || ad.deliveryName }</>
                  )
                  }
                </div>
              </div>
            )
          }

          <div className={ `${ styles['ad-form-wrap'] }` }>
            <div className={ `${ styles['ad-tit-label'] }` }>投放需求</div>
            {
              (isEditMode) && (
                <div className={ `${ styles['btn-edit'] }` } onClick={ showModal }><span className={ `${ styles['icon-pencile'] } ifont i-pencile-simple` }></span>编辑投放需求</div>
              )
            }

            {/*订单详细数据*/}
            {
              ad?.timePeriods && (
                <div className={ `${ styles['ad-form'] }` }>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-location` }></div>
                      投放区域：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        (ad?.districtList && ad?.districtList?.length > 2) && (
                          <Tooltip placement="top" title={ ad.districtList.map(i => i.districtName).join('、') }>
      
                            { ad.districtList[0].districtName }
                            {
                              (ad.districtList.length > 1) && (<>等{ ad.districtList.length }个</>)
                            }
                          </Tooltip>
                        )
                      }
                      {
                        (ad.districtList && ad.districtList.length && ad.districtList.length <= 2) && (<>
                          {
                            ad.districtList.map((district, index, arr) => {
                              return (
                                <span key={ index }>
                                  { district.districtName }
                                  {
                                    (index < arr.length - 1) && ('，')
                                  }
                                </span>
                              )
                            })
                          }
                        </>)
                      }
                    </div>
                  </div>
                  
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
                      投放周期：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      { util.tools.formatDateStr(ad.deliveryStart) }-{ util.tools.formatDateStr(ad.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
                      广告时长：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
                      {
                        (ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
                      播放时段：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        util.tools.convertTimePeriods(ad)
                      }
                    </div>
                  </div>
                  <div className={ `${ styles['ad-form-item'] }` }>
                    <div className={ `${ styles['ad-form-label'] }` }>
                      <div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
                      广告所属行业：
                    </div>
                    <div className={ `${ styles['ad-form-value'] }` }>
                      {
                        getDeliveryIndustry(ad)
                      }
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>

      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } callBack={ onUpdateBaseInfo } />

      {/* 视频预览 */}
      <VideoPlayModal childRef={ videoPlayModalRef } />

      {/* 编辑投放需求 弹窗*/}
      <Modal 
        width={ 1104 }
        title="编辑投放需求"
        footer={ null }
        visible={ isModalVisible }
        onCancel={ onModalCancel }
        >
          {/* 表单 */}
          <CreateForm 
            form={ submitForm } 
            ad={ ad }
            deliveryIndustryMore={ ad.deliveryIndustryMore } 
            mallOrderPlanId={ ad.mallOrderPlanId }
            reloadFn={ afterUpdateBaseInfo } />
      </Modal>

      {/*效果预览*/}
      {
        (getHasMenu()) && <PreviewEffectModal childRef={ previewEffectModalRef } imageUrl={ getImgUrl() } />
      }

      {/*广告素材制作要求*/}
      <ScreenEffectModal childRef={ screenEffectModalRef }  />

      {/* 广告计划名称弹窗 */}
      <Modal 
        width={ 480 }
        title="广告计划名称"
        visible={ isDeliveryNameModalVisible }
        onOk={ goToSave }
        onCancel={ onDeliveryNameModalCancel }
        >
        <Input 
          className={ `${ styles['delivery-name-ipt'] } ${ styles['short'] }` } 
          value={ deliveryName }
          size='large'
          ref= { inputRef }
          onChange={ onDeliveryNameChange }
          maxLength={ 32 }
            />
      </Modal>
  	</>
  )
}