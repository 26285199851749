import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { PersonalHome, CompanyHome } from './index'
import styles from './Home.module.css'

export const MineHome: React.FC = (props) => {
	const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
	const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'AD_MANAGER') : false
  })

	const [loginMode, setLoginMode] = useState<string>('')

	const [isTipsShow, setIsTipsShow] = useState(true)
	const hideTips = () => {
    setIsTipsShow(false)
  }

	useEffect(() => {
		// 'COMPANY' | 'PERSONAL'
		if (userType) {
			setLoginMode(userType)
		}
  }, [userType])

	return (
		<>
			{/* {
	      isTipsShow && <div className={ `${ styles['right-content-tips'] }` }>温馨提醒：热幕官网为您提供广告素材的上传功能，其他功能在开发中，敬请期待！如需投放、查看订单、人员授权等，您可使用热幕小程序 <span className={ `${ styles['ifont-close'] } ifont i-close-o` } onClick={ hideTips }></span></div>
	    } */} 
	    {
	    	loginMode ? (loginMode === 'COMPANY' ? <CompanyHome /> : <PersonalHome isManager={ isManager} />) : null
	    }
		</>
	)
}