import React, { useState, useEffect } from 'react'
import { getMenuListAction, getElemsAuthAction } from '../../store/menu/action'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'

import styles from './Sidebar.module.css'

import { useHistory } from 'react-router-dom'

import util from '../../libs/util'

import { Menu } from 'antd'
import topTip from '../../assets/images/sidebar/top-tip.png'

const { SubMenu } = Menu

interface ListItem {
  parentId: string,
  id: string,
  name: string,
  menuItemKey: string,
  menuPath?: string,
  menuIcon?: string,
  children?: any[]
}

export const Sidebar: React.FC = (props) => {
  const history = useHistory()
  const [list, setList] = useState<ListItem[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any>([])
  const [openKeys, setOpenKeys] = useState<any>([])
  const [sidebarHeight, setSidebarHeight] = useState<number>(600)

  const menuList = useSelector((state: RootState) => {
    return state.menu.list
  })
  const dispatch = useDispatch()

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const curPathName = history.location.pathname
    const menuSelectedData = findCurMenu(menuList, curPathName)
    setSelectedKeys(menuSelectedData.selectedKeys)
    setOpenKeys(menuSelectedData.openKeys)
  }, [history.location])

  useEffect(() => {
    initMenuList()
  }, [menuList])

  const init = () => {
    setSidebarHeight(window.innerHeight - 132)
    
    getMenuList()
  }

  const getMenuList = () => {
    dispatch(getMenuListAction({}))
  }

  const initMenuList = () => {
    const curPathName = history.location.pathname
    setList(menuList)
    const menuSelectedData = findCurMenu(menuList, curPathName)
    setSelectedKeys(menuSelectedData.selectedKeys)
    setOpenKeys(menuSelectedData.openKeys)
  }

  const findCurMenu = (list, name) => {
    if (!list) {
      return {
        selectedKeys: [],
        openKeys: [],
      }
    }
    
    let menuList = JSON.parse(JSON.stringify(list))

    let selectedKeys:any = []
    let openKeys:any = list.map(i => i.id)

    for (let i = 0, len = menuList.length; i < len; i++) {
      const menu = menuList[i]
      if (menu.menuPath === name) {
        selectedKeys = [menu]
        break;
      }

      const submenuList = menu.children
      if (submenuList && submenuList.length) {
        for (let j = 0, lenJ = submenuList.length; j < lenJ; j++) {
          const submenu = submenuList[j]
          if (submenu.menuPath === name) {
            selectedKeys = [submenu]
            // openKeys = [menu.id]
            break;
          }

          const thirdmenuList = submenu.children
          if (thirdmenuList && thirdmenuList.length) {
            for (let k = 0, lenK = thirdmenuList.length; k < lenK; k++) {
              const thirdmenu = thirdmenuList[k]
              if (thirdmenu.menuPath === name) {
                selectedKeys = [thirdmenu]
                // openKeys = [menu.id, submenu.id]
                break;
              }
            }
          }
        }
      }
    }
    return {
      selectedKeys: selectedKeys.map(i => i.id),
      openKeys,
    }
  }

  const onSelect = (event) => {
    const record = event.item.props['data-item']
    if (selectedKeys.includes(record.id)) {
      return
    }
    setSelectedKeys([record.id])
    history.push(record.menuPath)
    // 获取按钮权限
    dispatch(getElemsAuthAction({
      menuItemKey: record.menuItemKey,
    }))
  }

  return (
    <div className={ styles.sidebar }>
      <Menu 
        mode="inline" 
        selectedKeys={ selectedKeys }
        openKeys={ openKeys }
        style={{ width: '100%', height: '100%' }} 
        onClick={ onSelect }
      >
        {
          list?.map((item) => {
            if (item.children && item.children.length) {
              return (
                item.children?.map((record) => (
                  <SubMenu 
                    key={ record.parentId } 
                    icon={ item.menuIcon ? <i className={ `${ styles.iconfont } menufont ${ item.menuIcon }`}></i> : null } 
                    title={ item.name }
                  >
                    <Menu.Item 
                      data-item={ record }
                      key={ record.id }
                    >{ record.name }
                    </Menu.Item>
                  </SubMenu>
                ))
              )
            }
            else {
              return (
                <Menu.Item 
                  key={ item.id }
                  data-item={ item }
                  icon={ item.menuIcon ? <i className={ `${ styles.iconfont } menufont ${ item.menuIcon }`}></i> : null }
                >{ item.name }</Menu.Item> 
              )
            }
          })
        }
      </Menu>
    </div>
  )
}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Sidebar)