import React, { useState, useEffect, useImperativeHandle } from 'react'
import styles from './SignUpGuideModal.module.css'

import { Modal, Button } from 'antd'
import util from '../../../libs/util'

import miniCode from '../../../assets/images/common/mini-code.jpg'
import closeIcon from '../../../assets/images/icons/close-icon.png'

interface Props {
  childRef: any,
  isCinema?: Boolean,
  typeText?: String,
}

export const SignUpGuideModal: React.FC<Props> = ({ childRef, isCinema, typeText }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    util.bus.on('handleSignUp', () => {
      onToggle()
    })
  })

  useImperativeHandle(childRef, () => ({
    onToggle() {
      onToggle()
    }
  }))
  
  const onToggle = () => {
    setShow(!show)
  }

  return (
    <Modal 
      visible={ show }
      closable={ false }
      centered={ true }
      footer={ null }
      bodyStyle={{ padding: 0 }}
    >
      <div className={ styles.content }>
        <div className={ `${ styles.header } py4 tc` }>
          <span className={ `${ styles.title }` }>提醒</span>
          <img className={ styles['close-icon'] } width="16" height="16" src={ closeIcon } onClick={ onToggle } alt="" />
        </div>

        <div className={ styles.body }>
          <div className={ `${ styles.tip } mt4 tc` }>请使用微信扫码，登录热幕小程序进行{ typeText || '注册' }</div>
          <div className={ `${ styles['min-code'] } my6 tc` }>
            <img width="165" src={ miniCode } alt="" />
          </div>
        </div>

        <div className={ `${ styles.footer } py4 tc` }>
          <Button className={ `${ styles['confirm-btn'] } ${ isCinema ? styles['is-cinema'] : '' }` } onClick={ onToggle }>
            好的，我知道了
          </Button>
        </div>
      </div>
    </Modal>
  )
}