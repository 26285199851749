import React, { useState, useEffect, useRef, useContext } from 'react'
import { Spin, Modal, message, Checkbox } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../libs/util'
import { appContext } from '../../../App.state'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'
import { NoRecord, Crumb } from '../../../components'
import { Bottom } from '../select-points/bottom'
import { PointPriceDetailModal } from '../components/point-price-detail-modal'

import styles from './SelectHall.module.css'

const { confirm } = Modal
const CheckboxGroup = Checkbox.Group

interface Props {
  match: {
    params: {
      mallOrderPlanId?: String,
      filterData?: any,
      id?: String,
      deliveryContentDuration?: Number,
      deliveryDays?: Number,
      timePeriods?: any,
      playSequence?: any,
      showPlanStatus?: String,
      deliveryModel?: String,
      planAim?: String,
    },
  },
}

interface Ad {
  operatorType: string,
  deliveryCinemaModel: string,
  approveStatus: string,
  showPlanStatusMore: string,
  planAim: string,
  cancelOrderTime: string,
  contentUploadDateEnd: string,
  deliveryName: string,
  mallOrderMediaId?: string,
  mallMediaApproveId?: string,
  mallOrderPlanId?: string,
  orderTotal: {
    pointCount: number,
    entityCount: number,
    planAmount: string,
  },
  contentUrl?: string,
  showPlanStatus?: string,
  startBetweenDays?: number,
  mallOrderNumber?: string,
  payOrderTime?: string,
  payUser?: any,
  confirmUser?: any,
  hasDeliveryDays?: number,
  thumbnailUrl: string,
  contentApproveOpinion: string,
  contentDuration: number,
  deliveryContentDuration: number,
  timePeriods?: any[],
  deliveryStart?: string,
  deliveryEnd?: string,
  deliveryDays?: number,
  deliveryModel?: string,
  deliveryIndustryMore?: {
    itemMap?: string,
  },
  planAmount?: number,
  confirmOrderTime?: string,
}
const defaultAd: Ad = {
  operatorType: '',
  deliveryCinemaModel: '',
  approveStatus: '',
  showPlanStatusMore: '',
  planAim: '',
  cancelOrderTime: '',
  contentUploadDateEnd: '',
  deliveryName: '',
  mallOrderMediaId: undefined,
  mallMediaApproveId: undefined,
  mallOrderPlanId: undefined,
  orderTotal: {
    pointCount: 0,
    entityCount: 0,
    planAmount: '',
  },
  contentUrl: '',
  showPlanStatus: '',
  startBetweenDays: 0,
  mallOrderNumber: '',
  payOrderTime: '',
  payUser: {},
  confirmUser: {},
  hasDeliveryDays: 0,
  thumbnailUrl: '',
  contentApproveOpinion: '',
  contentDuration: 0,
  deliveryContentDuration: 0,
  timePeriods: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryDays: 0,
  deliveryModel: '',
  deliveryIndustryMore: {
    itemMap: '',
  },
  planAmount: 0,
  confirmOrderTime: '',
}

interface ListItem {
  isChecked: boolean,
}

export const AdSelectHall: React.FC<Props> = (props) => {
  const appValue = useContext(appContext)
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const onBack = () => {
    history.go(-1)
  }

  let { mallOrderPlanId, filterData, id, timePeriods, playSequence, deliveryContentDuration, deliveryDays } = props.match.params
  timePeriods = JSON.parse(timePeriods)
  filterData = JSON.parse(filterData)

  useEffect(() => {
    // getList(1)
    getAdDetail()
    getOrderTotal()
    getEntityDetail()
    getEnumItems()
  }, [])

  const [list, setList] = useState<any>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getList(pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)

  // 详情
  const [ad, setAd] = useState<any>(defaultAd)
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderPlanDetail`,
      data: {
        mallOrderPlanId,
      },
    })
    if (code === 1) {
      setAd(Object.assign({}, defaultAd, data))
    }
  }
  const getOrderTotal = async() => {
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
        mallOrderPlanIds: [mallOrderPlanId],
      },
    })

    if (code === 1) {
      setAd(state => {
        return {
          ...state,
          orderTotal: data.find(i => i.mallOrderPlanId === mallOrderPlanId) || null,
        }
      })
    }
  }

  const [entity, setEntity] = useState<any>()
  const getEntityDetail = async() => {
    const { data, code } = await util.request({
      method: 'POST',
      url: `/mall/point/getMallEntity`,
      data: {
        ...filterData,
        resourceEntityInfoId: id,
      },
    })

    if (code === 1) {
      setEntity(data)
    }
  }

  // 获取列表
  const getList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const pNum = num || pageNum
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/point/searchMallPointList?pageNum=${ pNum }&pageSize=${ pageSize }`,
      data: Object.assign(filterData, {
        resourceEntityInfoId: id,
      }),
    })
    if (code === 1) {
      setList(data)
      setTotal(total)
      setIsSubmiting(false)

      const cList = data.filter(i => i.hasChecked).map(i => i.resourcePointId)
      setCheckedList(cList)
      setIndeterminate(!!cList.length && cList.length < data.length)
      setCheckAll(cList.length === data.length)
    }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<any>([])
  const [cinemaSoundTrackItems, setCinemaSoundTrackItems] = useState<any>([])
  // 获取枚举值
  const getEnumItems = async () => {
    // CINEMA_SOUND_TRACK 声道
    // CINEMA_SCREEN_MATERIAL 影院银幕类型
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: ['CINEMA_SOUND_TRACK', 'CINEMA_SCREEN_MATERIAL'].join(','),
      },
    })
    if (code === 1) {
      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      setCinemaSoundTrackItems(data['CINEMA_SOUND_TRACK'] || [])
    }
  }

  const goToDetailPage = () => {
    history.push(`/ad/plan-detail/${ ad.mallOrderPlanId }`)
  }

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [checkedList, setCheckedList] = useState<any>([])

  const onCheckGroupChange = (cList) => {
    // 不可选厅，只能全选/全不选
    if (entity.fullSelect) {
      const content = cList.length < checkedList.length ? '是否确认取消投放所有影厅？' : '是否确认投放所有影厅？'
      confirm({
        title: '该影院不可选厅投放，只可投放全部影厅',
        content,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          addAllToCart(cList.length < checkedList.length ? false : true)
        },
        onCancel() {
        },
      })
      return
    }
    toggleToCart(cList)
  }

  const onCheckAllChange = ({ target }) => {
    addAllToCart(target.checked)
  }

  const toggleToCart = async(cList) => {
    const id = cList.length > checkedList.length ? cList.find(i => !checkedList.includes(i)) : checkedList.find(i => !cList.includes(i))
    const type = cList.length < checkedList.length ? 'remove' : 'add'

    const { code, message: msg } = await toggleSelectPoints(type, id)

    if (code === 1) {
      setCheckedList(cList)
      setIndeterminate(!!cList.length && cList.length < list.length)
      setCheckAll(cList.length === list.length)

      // 购物车统计
      getOrderTotal()

      getEntityDetail()
    } else {
      message.error(msg || '操作失败')
    }
  }

  const toggleSelectPoints = async(type, resourcePointId) => {
    if (isSubmiting) {
      return
    }

    setIsSubmiting(true)

    const resourceEntityInfoId = id

    const queryData = Object.assign({}, {
      ...filterData,
      entityInfoList: resourcePointId ? [{
        selectModel: 'PART',
        resourceEntityInfoId,
        selectedPointId: [resourcePointId],
      }] : [{
        selectModel: 'ALL',
        resourceEntityInfoId,
      }]
    })

    if (type === 'remove') {
      queryData.selectAll = 'NO'
    }

    const apiUrl = type === 'add' ? '/mall/order/selectMallPoint' : '/mall/order/removeOrderPoint'
    const result = await util.request({
      method: 'POST',
      url: apiUrl,
      data: queryData,
    })

    setIsSubmiting(false)

    return result
  }

  const addAllToCart = async(checked) => {
    const type = checked ? 'add' : 'remove'
    const { code, message: msg } = await toggleSelectPoints(type, '')

    if (code === 1) {
      setCheckedList(checked ? list.map(i => i.resourcePointId) : [])
      setIndeterminate(false)
      setCheckAll(checked)

      // 购物车统计
      getOrderTotal()

      getEntityDetail()
    } else {
      message.error(msg || '操作失败')
    }
  }

  const pointPriceDetailModalRef: any = useRef()
  const showPriceModal = (point) => {
    const params = {
      resourcePointId: point.resourcePointId, 
      mallOrderPlanId,
      showPlanStatus: ad.showPlanStatus,
      deliveryDays: ad.deliveryDays,
    }
    pointPriceDetailModalRef.current.onToggle(params)
  }

  const crumbList = [
    {
      name: '投广告',
      path: '/ad/create',
    },
    {
      name: '广告计划详情',
      path: `/ad/detail/${ mallOrderPlanId }`,
    },
    {
      name: '选择广告点位',
      path: '',
    }
  ]

  return (
    <>
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } ifont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>选择广告点位</div>
        </div>
        
        <div className={ `${ styles['entity-box'] }` }>
          <div className={ `${ styles['entity-item-l'] }` }>
            <img className={ `${ styles['entity-image'] }` } src={ `${ entity?.entityViewPath }?imageMogr2/thumbnail/x88` } />
            <div className={ `${ styles['chain-name'] }` }>{ entity?.cinemaChainName }</div>
          </div>
          <div className={ `${ styles['entity-item-r'] }` }>
            <div className={ `${ styles['entity-tit'] }` }>
              { entity?.entityName }
              <div className={ `${ styles['entity-other-labels'] }` }>
                {
                  (entity?.fullSelect) && (<div className={ `${ styles['entity-other-label'] }` }>该影院不支持选厅投放</div>)
                }
              </div>
            </div>
            <div className={ `${ styles['entity-tips'] }` }>
              {
                (entity?.geoDistance) && (<><span className={ `${ styles['entity-label'] }` }>{ util.tools.splitNum2String(entity?.geoDistance / 1000).int}{ util.tools.splitNum2String(entity?.geoDistance / 1000).dec }km</span> | </>)
              }<span className={ `${ styles['entity-label'] }` }>{ entity?.cityName }{ entity?.districtName }{ entity?.addressMore }</span>
            </div>
            <div className={ `${ styles['entity-tips'] }` }>营业时间：{ entity?.workStart }-{ entity?.workEnd }</div>
            {/*<div className={ `${ styles['entity-tips'] }` }>已选点位<span className={ `${ styles['count-selected'] } ${ entity.orderEntityState.checkedCount > 0 ? 'success' : '' }` }>{ entity?.orderEntityState.checkedCount || 0 }</span>个<span className={ `${ styles['btn-right'] } ifont i-trangle-b` }></span></div>*/}
          </div>
          {/* 查看场所详情 */}
          {/* <div className={ `${ styles['btn-more'] }` }>查看场所详情<div className={ `${ styles['btn-right'] } ifont i-trangle-b` }></div></div> */}
        </div>

        {
          (list.length > 0) && (
            <>
              {/* 点位列表 */}
              <div className={ `${ styles['table-head'] }` }>
                <div className={ `${ styles['table-td'] }` }>点位基本信息（共{ total }个）</div>
                <div className={ `${ styles['table-td'] }` }>广告时长</div>
                <div className={ `${ styles['table-td'] }` }>播放时段</div>
                <div className={ `${ styles['table-td'] }` }>日刊例价</div>
                <div className={ `${ styles['table-td'] }` }>可投天数</div>
                <div className={ `${ styles['table-td'] }` }>投放费用</div>
                <div className={ `${ styles['table-td'] }` }>预估触达人次</div>
                <div className={ `${ styles['table-td'] }` }>操作</div>
              </div>

              <div className={ `${ styles['point-list'] }` }>
                <Checkbox.Group style={{ width: '100%' }} value={ checkedList } onChange={ onCheckGroupChange }>
                {
                  list.map(point => {
                    return (
                      <div className={ `${ styles['point-item'] }` } key={ point.resourcePointId }>
                        <div className={ `${ styles['table-td'] } ${ styles['flex-start'] }` }>
                          <div className={ `${ styles['point-checkbox'] }` }><Checkbox value={ point.resourcePointId } checked={ point.isChecked }></Checkbox></div>
                          <div className={ `${ styles['point-img-wrap'] }` }>
                            <img className={ `${ styles['point-img'] }` } src={ `${ appValue.rHost }${ point.environmentFilePath || point.environmentFilePathOne || point.environmentFilePathTwo }?imageMogr2/thumbnail/x88` } />
                          </div>
                          <div className={ `${ styles['point-cont-r'] }` }>
                            <div className={ `${ styles['point-tit'] }` }>{ point.pointName }</div>
                            <div className={ `${ styles['point-labels'] }` }>
                              <div className={ `${ styles['point-label'] }` }>{ point.seatNumber }座</div>
                              {
                                (point.cinemaSoundTrack) && (<div className={ `${ styles['point-label'] }` }>{ cinemaSoundTrackItems?.find(i => i.itemKey === point.cinemaSoundTrack)?.itemValue }</div>)
                              }

                              {
                                (point.cinemaScreenMaterial) && (<div className={ `${ styles['point-label'] }` }>{ cinemaScreenMaterialItems?.find(i => i.itemKey === point.cinemaScreenMaterial)?.itemValue }</div>)
                              }
                            </div>
                          </div>
                        </div>

                        <div className={ `${ styles['table-td'] }` }>{ deliveryContentDuration }秒</div>

                        <div className={ `${ styles['table-td'] }` }>
                          {(point) && (<>{ util.tools.convertTimePeriods({ timePeriods, playSequence }) }</>)}
                        </div>

                        <div className={ `${ styles['table-td'] }` }>
                          <span className={ `${ styles['text-price'] }` }>
                            <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                            <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(point.minSalePrice).int }</span>
                            <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(point.minSalePrice).dec }</span>
                          </span>起/天</div>

                        <div className={ `${ styles['table-td'] }` }><span className={ `${ styles['point-total-days'] } ${ point.innerTotal && point.innerTotal != deliveryDays ? styles['special-number'] : '' }` }>{ point.innerTotal }</span>天</div>

                        <div className={ `${ styles['table-td'] }` }>
                          <span className={ `${ styles['text-price'] }` }>
                            <span className={ `${ styles['text-price-unit'] }` }>￥</span>
                            <span className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2String(point.salePrice).int }</span>
                            <span className={ `${ styles['text-price-dec'] }` }>{ util.tools.splitNum2String(point.salePrice).dec }</span>
                          </span></div>

                        <div className={ `${ styles['table-td'] }` }>{ point.reachTime }人</div>

                        <div className={ `${ styles['table-td'] } ${ styles['table-link'] }` } onClick={ () => { showPriceModal(point) } }>查看费用明细</div>
                      </div>
                    )
                  })
                }
                </Checkbox.Group>
              </div>

              <div className={ `${ styles['table-opts'] }` }>
                <Checkbox 
                  indeterminate={ indeterminate } onChange={ onCheckAllChange } checked={ checkAll } style={{ 'fontSize': '16px' }}>全选</Checkbox>
                <div className={ `${ styles['table-opts-label'] }` }>共 <span className={ `${ styles['table-opts-total'] }` }>{ total }</span> 个点位，已选点位 <span className={ `${ styles['table-opts-selected'] }` }>{ checkedList.length }</span> 个</div>
              </div>
            </>
          )
        }

        {
          list.length <= 0 && <NoRecord />
        }
      </div>
    </div>

    {/*底部操作*/}
    <Bottom ad={ ad } entity={ entity } />

    {/*查看投放日期*/}
    <PointPriceDetailModal  childRef={ pointPriceDetailModalRef } />
    </>
  )
}