import React  from 'react'

import { HashRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'

import {
  ConfigProvider,
} from 'antd'
import 'antd/dist/antd.css'
import zhCN from 'antd/lib/locale/zh_CN'

import './assets/style/reset.css'
import './assets/style/custom-antd.css'
import './assets/style/common.css'
/* 系统用到的图标 */
import './assets/style/iconfont/index.css'
/* 菜单图标 */
import './assets/style/iconfont/menufont.css'
/*热幕精灵 - 影院映前广告助手*/
import './assets/style/iconfont-assistant/index.css'

import styles from './App.module.css'

import { routes } from './router'

export const App = () => {
  return (
    <ConfigProvider locale={ zhCN }>
      <div className={ styles.App }>
        <HashRouter >
          { renderRoutes(routes) } 
        </HashRouter>
      </div>
    </ConfigProvider>
  )
}