import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Pagination, Table } from 'antd'
import styles from './ReportDayDetailModal.module.css'
import util from '../../../../../libs/util'
import { convertDeliveryStatus } from '../List'

interface Props {
  childRef: any,
}
const dateFormat = 'YYYY-MM-DD'
const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

export const ReportDayDetailModal: React.FC<Props> = ({ childRef }) => {
  useImperativeHandle(childRef, () => ({
    onToggle(queryForm, dayInfo) {
      setQueryForm(queryForm)
      setDayInfo(dayInfo)
      showEffectModal()
      getList(queryForm, 1)
    }
  }))
  const [queryForm, setQueryForm] = useState<any>()
  const [dayInfo, setDayInfo] = useState<any>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showEffectModal = () => {
    setIsModalVisible(true)
  }

  // 
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const getList = async (queryForm, num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    
    const pNum = num || pageNum
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/delivery/searchBuyerDetailPoint?pageNum=${pageNum}&pageSize=${pageSize}`,
      data: {
        mallOrderPlanId: queryForm?.mallOrderPlanId,
        pointDate: queryForm?.pointDate,
      },
    })
    if (code === 1) {
      setList(data)
      setTotal(total)
    }
  }

  useEffect(() => {
    getList(queryForm, pageNum)
  }, [pageNum])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const columns = [
    {
      title: '场所名称',
      dataIndex: 'entityName',
      key: 'entityName',
      render(text, point, index) {
        return (
          <div className={ styles['entity-name'] }>{ text }</div>
        )
      },
    },
    {
      title: '点位名称',
      dataIndex: 'pointName',
      key: 'pointName',
    },
    {
      title: '点位级别',
      dataIndex: 'pointGrade',
      key: 'pointGrade',
      render(text, record, index) {
        return text ? <div className={ styles['rate-list'] }>
          {
            new Array(text).fill(1).map((item, index) => {
              return <div className={ `${ styles['rate-item'] } ifont i-fire` } key={ index }></div>
            })
          }
        </div> : ''
      },
    },
    {
      title: '点位投放费用',
      dataIndex: 'amount',
      key: 'amount',
      render(text, point, index) {
        return (
          <div className={ styles['table-cell'] }>
            {
              <div className={ styles['text-price'] }>
                ￥
                <span className={ styles['text-price-unit'] }>
                { util.tools.splitNum2String(point.receivableAmount).int }
                { util.tools.splitNum2String(point.receivableAmount).dec }
                </span>
              </div>
            }
            {
              (point.refundAmount > 0) && (<span className={ `${ styles['report-tips-label'] } ${ styles['is-important'] }` }>已退款</span>)
            }
          </div>
        )
      },
    },
    {
      title: '回执状态',
      dataIndex: 'status',
      key: 'status',
      render(text, point, index) {
        if (!(dayInfo.settleStatus === 0 || dayInfo.settleStatus === 1 || dayInfo.settleStatus === 2)) {
          return
        }
        return (
          <span className={ `${ styles['report-status'] } ${ point.workFailedNums > 0 ? styles['error'] : styles['success'] }` }>{ point.workFailedNums > 0 ? '缺刊' : '已上刊' }</span>
        )
      },
    },
  ]

  return (
    <Modal 
      title="日刊播详情" 
      width={ 1104 }
      visible={ isModalVisible } 
      footer={ null }
      closeIcon=""
      onCancel={() => { setIsModalVisible(false) } }
      >
      <div className={ `${ styles['report-base-info'] }` }>
        <div className={ `${ styles['report-day-tit'] }` }>
          <div className={ `${ styles['report-day-tit-side'] }` }>
            <div className={ `${ styles['report-date'] }` }>
              { dayInfo?.clearDate }
              {
                (dayInfo?.status === 0) && 
                <div className={ `${ styles['date-label'] }` }>今</div>
              }
            </div>
            {/*// 无投放 -1, 刊播中 0, 已刊播 未生成报告 1, 已刊播 有报告 2, 待刊播 3*/}
            {
              (dayInfo?.settleStatus === 0 || dayInfo?.settleStatus === 1 || dayInfo?.settleStatus === 2) && <div className={ `${ styles['report-settle-status'] }` }>{ dayInfo?.settleStatus === 0 ? '未结算' : '已结算' }</div>
            }
          </div>

          <div className={ `${ styles['report-day-tit-side'] }` }>
            {
              (dayInfo?.settleStatus === 1) && <div className={ `${ styles['report-label'] }` }>未生成报告</div>
            }
            <div className={ `${ styles['report-status'] }` }>{ convertDeliveryStatus(dayInfo?.status) }</div>
          </div>
        </div>

        <div className={ `${ styles['report-data'] }` }>
          <div className={ `${ styles['report-item'] }` }>
            <div className={ `${ styles['report-item-label'] }` }>投放点位(个)</div>
            <div className={ `${ styles['report-item-value'] }` }>
              <div className={ `${ styles['report-number'] }` }>{ dayInfo?.pointNums || 0 }</div>
              {
                (dayInfo?.settleStatus === 2 && dayInfo?.workFailedNums > 0) && <div className={ `${ styles['report-tips-label'] } ${ styles['is-important'] }` }>缺刊{ parseInt(dayInfo?.workFailedNums) }个</div>
              }
            </div>
          </div>

          <div className={ `${ styles['report-item'] }` }>
            <div className={ `${ styles['report-item-label'] }` }>上刊率</div>
            <div className={ `${ styles['report-item-value'] } ${ styles['report-number'] }` }>
              { dayInfo?.settleStatus === 2 ? (dayInfo?.workSuccessRatio * 100) + '%' : '--' }
            </div>
          </div>

          <div className={ `${ styles['report-item'] }` }>
            <div className={ `${ styles['report-item-label'] }` }>实付投放费用</div>
            <div className={ `${ styles['report-item-value'] }` }>
              {
                <div className={ `${ styles['text-price'] } ${ styles['report-number'] }` }>
                  <span className={ styles['text-price-unit'] }>￥</span>
                  { util.tools.splitNum2String(dayInfo?.receivableAmount).int }
                  { util.tools.splitNum2String(dayInfo?.receivableAmount).dec }
                  
                </div>
              }
              {
                (dayInfo?.refundAmount > 0) && (<span className={ `${ styles['report-tips-label'] } ${ styles['is-important'] }` }>已退￥{ util.tools.splitNum2String(dayInfo?.refundAmount).int }{ util.tools.splitNum2String(dayInfo?.refundAmount).dec }</span>)
              }
            </div>
          </div>
        </div>
      </div>

      <div className={ `${ styles['report-list'] }` }>
        <Table
          rowKey="index"
          columns={ columns }
          dataSource={ list }
          pagination={ false }
        ></Table>
      </div>

      {/*页码*/}
      <div className={ `${ styles.paging } flex justify-end` }>
        <Pagination 
          showQuickJumper 
          showSizeChanger
          showTotal={ total => `共 ${total} 条`}
          defaultCurrent={ defaultCurrent }
          current={ pageNum }
          pageSize={ pageSize }
          pageSizeOptions={ pageSizeOptions }
          total={ total } 
          onChange={ onPageChange }
          onShowSizeChange={ onShowSizeChange }
           />
      </div>
    </Modal>
  )
}